import { ApplicationState } from '../store'
// import { useGetOrdersQuery } from '../store/PricingApi';
import { useSelector } from 'react-redux'
import { AccountState } from '../store/Account'
import { useEffect, useState } from 'react'
import { Product, useCheckoutGetAccountStatusQuery, useCheckoutGetProductsQuery } from '../store/generated/checkoutsApi'

export const useActiveProduct = () => {
  const accountStore = useSelector(
    (state: ApplicationState) => state.accountStore
  ) as AccountState
  const ordersResults = useCheckoutGetAccountStatusQuery(undefined, {
    skip: !accountStore.session_token?.access_token,
    refetchOnMountOrArgChange: true
  })
  const productsResults = useCheckoutGetProductsQuery()
  const [activeProduct, setActiveProduct] = useState<Product | null | undefined>(null)

  useEffect(() => {
    if (!accountStore.session_token && activeProduct) {
      setActiveProduct(undefined)
    }
  }, [accountStore.session_token, activeProduct])

  useEffect(() => {
    if (ordersResults.isSuccess && productsResults.isSuccess) {
      const activeOrders = ordersResults.data?.Orders?.filter((x) => !x.IsExpired && x.Active)
        .sort((a, b) => (b.Id || 0) - (a.Id || 0))
      if (activeOrders && activeOrders.length > 0) {
        const product = productsResults.data?.Products?.data?.find(
          (x) => x.id === activeOrders[0].ProductId
        )

        if (product) {
          setActiveProduct(product)
        }
      } else if (ordersResults.data.IsStudent) {
        setActiveProduct({ name: 'Student', active: true })
      } else {
        setActiveProduct(undefined)
      }
    } else if (productsResults.isSuccess && !accountStore.session_token?.access_token) {
      setActiveProduct(undefined)
    }
  }, [accountStore.session_token, ordersResults.data, ordersResults.isSuccess, productsResults.data, productsResults.isSuccess])

  return activeProduct
}
