import React, { useEffect } from 'react'
import { useTimer } from 'react-timer-hook'
import { SpecialOffer } from '../../store/generated/checkoutsApi'

interface ICountdownOffer { specialOffer: SpecialOffer }

export default function CountdownOffer({ specialOffer }: ICountdownOffer) {
  const getEndDate = () => new Date(specialOffer.DateTo ?? '2030-01-01')

  const {
    seconds,
    minutes,
    hours,
    days,
    start,
  } = useTimer({ expiryTimestamp: getEndDate(), onExpire: () => doRestart() })

  const doRestart = () => {
    window.location.reload()
  }

  useEffect(() => {
    start()
  }, [start])

  return (
    <>
      {
        days <= 7 ? (
          <div className="sale-ends-in">
            <div>Quick - Offer ending soon!</div>
            <div>
              {days} days {hours} hours {minutes} mins {seconds} secs
            </div>
          </div>
        ) : null
      }
    </>

  )
}

