import React from 'react'
import { useCheckoutGetSpecialOfferQuery } from '../store/generated/checkoutsApi'
import { Link } from 'react-router-dom'
import { NavLink } from 'reactstrap'
import { useActiveProduct } from '../hooks/useActiveProduct'
import CountdownOffer from './pricing/CountdownOffer'

interface ISale {
  isMySubscription?:boolean;
}

export default function Sale({isMySubscription}: ISale) {
  const activeProduct = useActiveProduct()
  const specialOffer = useCheckoutGetSpecialOfferQuery()

  return (
    <>
      {
        specialOffer?.data && !activeProduct && (
          <NavLink
            tag={Link}
            to={`/pricing`}
          >
            <div className={`sale ${isMySubscription ? 'sub' : ''}`}>
              <div className="sale-title">{specialOffer?.data.Name}</div>
              <div className="sale-description">{specialOffer?.data.Description}</div>
              <div className="sale-price">Extra <b>{specialOffer?.data.PercentageDiscount}%</b> off with code <b>{specialOffer.data?.Code}</b> at checkout</div>
              <CountdownOffer specialOffer={specialOffer.data} />
            </div>

          </NavLink >

        )
      }
    </>
  )
}
