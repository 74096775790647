import * as React from 'react'
import { Helmet } from 'react-helmet'
import LoadingSpinner from '../components/misc/LoadingSpinner'
import ColouredTitle from '../components/misc/ColouredTitle'
import { useEffect, useState } from 'react'
import PricingComponent from '../components/pricing/PricingComponent'
import { Portal } from 'react-portal'
import LoginOrRegister from '../components/pricing/LoginOrRegister'
import Sale from '../components/Sale'
import { ApplicationState } from '../store'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { useCheckoutGetProductsQuery, useCheckoutGetSpecialOfferQuery } from '../store/generated/checkoutsApi'
import ShareThisSticky from '../components/misc/ShareThisSticky'
import Highlight from '../components/Highlight'
import { useActiveProduct } from '../hooks/useActiveProduct'
import { useGetGeoLocationQuery } from '../store/geoApi'

interface IPricingContainer {
  home?: boolean
  hideWithSub?: boolean
}

export function PricingContainer({ home = false, hideWithSub = false }: IPricingContainer) {
  const activeProduct = useActiveProduct()
  const productsResults = useCheckoutGetProductsQuery()
  const accountStore = useSelector((state: ApplicationState) => state.accountStore)
  const [showLoginWarning, setShowLoginWarning] = useState(false)
  const specialOffer = useCheckoutGetSpecialOfferQuery()
  const { data } = useGetGeoLocationQuery(undefined)

  useEffect(() => {
    // @ts-ignore
    window.scrollTo(0, 0)
  }, [])

  if (hideWithSub && activeProduct) {
    return null
  }

  if (accountStore?.session_token?.access_token && !home) {
    return <Redirect to="/myAccount" />
  }

  if (productsResults.isLoading) {
    return <LoadingSpinner message={'Loading prices'} />
  }

  return (
    <>
      {!home ? <Helmet>
        <title>Pricing</title>
      </Helmet> : null}

      <div className='home__jumbotron theme-dark pt-30 pb-40 regPage p-0'>
        <div className='container'>
          <div className='row'>
            <div className='col col-12 regPage__form p-0'>
              <ColouredTitle title='Pricing' />
              <div className='puzzle__text-medium'>
                <p>
                  Play up to <Highlight top={2} text='3 FREE' /> puzzles daily, or check out our <Highlight top={2} text='subscriptions' />.
                </p>
                <p>
                  With our subscriptions enjoy <Highlight top={2} text='all puzzles' /> without limitations as well as lots of extra benefits, like <Highlight top={2} text='worksheets' /> and more!
                </p>
                <p>If you are a school or teacher, why not try our <Highlight top={2} text='educational subscription' /> which gives you up to <Highlight top={2} text='300 student logins' /> to use in class or at home!</p>
              </div>

              <div style={{ paddingBottom: 30, overflow: 'hidden' }}>
                <Sale />
              </div>

              <PricingComponent
                pricingData={productsResults?.data}
                specialOffer={specialOffer?.data}
                setShowLoginWarning={setShowLoginWarning}
                loggedIn={!!accountStore?.user_details}
                currency={data?.currency ?? accountStore?.user_details?.Currency}
                accountStore={accountStore}
              />

              {false && (
                <div className='login__loading'>
                  <LoadingSpinner
                    transparent={true}
                    whiteText={true}
                    message='Loading prices...'
                    marginTop={0}
                  />
                </div>
              )}

              {
                showLoginWarning && (
                  <Portal>
                    <LoginOrRegister closeModal={setShowLoginWarning} />
                  </Portal>
                )
              }

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ShareThisSticky(PricingContainer)
