export const getCurrencySymbol = (currency: string) => {
    switch (currency) {
        case 'gbp':
            return '£';
        case 'usd':
            return '$';
        case 'nzd':
            return 'N$';
        case 'cad':
            return 'C$';
        case 'aud':
            return 'A$';
        case 'eur':
            return '€';
        default:
            return '$';
    }
}

export const getCurrencySymbolVerbose = (currency: string) => {
    switch (currency) {
        case 'gbp':
            return 'GBP £';
        case 'usd':
            return 'USD $';
        case 'nzd':
            return 'NZD $';
        case 'cad':
            return 'CAD $';
        case 'aud':
            return 'AUD $';
        case 'eur':
            return 'EUR €';
        default:
            return 'USD $';
    }
}