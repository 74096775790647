import * as React from 'react'
import {
  Collapse,
  Container,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink
} from 'reactstrap'

import { Link } from 'react-router-dom'
import logo from '../../assets/solvemoji-pos-logo.svg'
// import santaHat from '../../assets/santahat.svg'
import '../../styles/header/nav.scss'
import { ISessionToken } from '../../interfaces/ISessionToken'
import IExternalLogin from '../../interfaces/IExternalLogin'
import { IUserDetails } from '../../interfaces/IUserDetails'
import history from '../../history'
import { useEffect, useRef, useState } from 'react'
import { useActiveProduct } from '../../hooks/useActiveProduct'
import Social from '../Social'
import { useCheckoutGetSpecialOfferQuery } from '../../store/generated/checkoutsApi'
import SearchPuzzleId from '../home/SearchPuzzleId'

function debounce(func: any, wait: any) {
  let timeout: any;
  return function(...args: any) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const NavMenu = (props: {
  session_token?: ISessionToken
  user_details?: IUserDetails
  doShowNickname: (show: boolean) => void
  logOut: () => void
  externalLogins: IExternalLogin[]
}) => {
  const {
    session_token,
    user_details,
    logOut,
    doShowNickname,
  } = props
  const [isOpen, setIsOpen] = useState(false)
  const activeProduct = useActiveProduct()
  const specialOffer = useCheckoutGetSpecialOfferQuery()

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const [isSticky, setIsSticky] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null); // Reference to the sticky element

  useEffect(() => {
    const debouncedHandleScroll = debounce(() => {
      if (!elementRef.current) {
        return;
      }
      const stickyTop = elementRef.current.getBoundingClientRect().top;
      const stickyValue = 0; 
      
      setIsSticky(stickyTop <= stickyValue);
    }, 50);

    window.addEventListener('scroll', debouncedHandleScroll);

    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, []);

  const doShowMyAccount = () => {
    history.push('/myAccount')
  }

  return (
    <>
      <header>
        <Container className='navbar navbar-expand-sm navbar-light solvemojiHeader'>
          <Social />
          <NavbarBrand tag={Link} to='/' aria-label="Solvemoji Logo">
            <div className='solvemojiHeader__logos'>
              <div style={{
                background: `url(${logo})`,
                paddingTop: 'calc(51/300 * 100%)'
              }}
                className='solvemojiHeader__logo'
              />
              {/* <img
                src={santaHat}
                alt='santa'
                className='solvemojiHeader__santa'
              /> */}
            </div>
          </NavbarBrand>
          <div className='authentication'>
            {!session_token && (
              <>
                <div className='authentication__buttons'>
                  <Link to='/register?redirectUrl=myAccount' onClick={() => setIsOpen(false)}>
                    <button className='btn btn-primary authentication__buttons authentication__buttons-register'>
                      <i className='icon-user-plus'></i>
                      <span>Register</span>
                    </button>
                  </Link>
                  <Link to='/login' onClick={() => setIsOpen(false)}>
                    <button className='btn btn-primary authentication__buttons authentication__buttons-login'>
                      <i className='icon-sign-in'></i>
                      <span>Login</span>
                    </button>
                  </Link>
                </div>
                {/* <SearchPuzzleId small={true} /> */}
                {/* <div className='authentication__textArea'>
                <span className='authentication__text'>Login using</span>
                <i
                  onClick={() => openExternalLogin('Google')}
                  className='icon-google-plus-square authentication__icon gp'
                />
                <i
                  onClick={() => openExternalLogin('Facebook')}
                  className='icon-facebook-square authentication__icon fb'
                />
              </div> */}
              </>
            )}

            {session_token && session_token.access_token && user_details && (
              <div className='authentication__loggedIn'>
                <div>

                  <div>
                    {session_token.userName && (
                      <>
                        <i
                          className='icon-user-circle-o icon mr-10'
                          onClick={() => { setIsOpen(false); doShowMyAccount() }}
                        ></i>
                        {session_token.userName}
                      </>
                    )}
                    {!session_token.userName && user_details.Email && (
                      <>{user_details.Email}</>
                    )}
                  </div>
                  <div>
                    {user_details.Nickname && (
                      <>
                        <i
                          className='icon-pencil icon mr-10'
                          onClick={() => { setIsOpen(false); doShowNickname(true) }}
                        ></i>
                        {user_details.Nickname}
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <button
                    className='btn btn-sm btn-primary authentication__buttons authentication__buttons-login'
                    onClick={() => { setIsOpen(false); logOut() }}
                  >
                    <i className='icon-sign-out'></i>
                  </button>
                </div>
              </div>
            )}
            <SearchPuzzleId small={true} />
          </div>

          <NavbarToggler
            onClick={toggle}
            className={`mr-2 ${session_token ? 'nav-session' : ''}`}
          />
        </Container>
      </header >
      <div ref={elementRef} className={`navbar-expand-sm navbar-toggleable-sm solvemojiNavHeader ${isSticky ? 'scrolled' : ''}`}>
        <Container>
          <Collapse
            className='d-sm-inline-flex flex-sm-row-reverse'
            isOpen={isOpen}
            navbar
          >
            <ul className='navbar-nav nav-fill w-100 solvemojiNav'>
              <NavItem>
                <NavLink tag={Link} to='/' onClick={() => setIsOpen(false)}>
                  HOME
                </NavLink>
              </NavItem>
              <NavItem>
                {!user_details && (
                  <NavLink tag={Link} to='/pricing' onClick={() => setIsOpen(false)}>
                    PRICING {!activeProduct && specialOffer.data && <span className='sale-badge navHide'>SALE!</span>}
                  </NavLink>
                )}
                {user_details && (
                  <NavLink tag={Link} to='/myAccount' onClick={() => setIsOpen(false)}>
                    MY ACCOUNT {!activeProduct && specialOffer.data && <span className='sale-badge navHide'>SALE!</span>}
                  </NavLink>
                )}
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to='/books' onClick={() => setIsOpen(false)}>
                  PUZZLE BOOKS
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to='/today' onClick={() => setIsOpen(false)}>
                  TODAYS<span className='navHide'> PUZZLES</span>
                  {!activeProduct && <span className='free-badge navHide'>FREE!</span>}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to='/new' onClick={() => setIsOpen(false)}>
                  <span className='navHide'>ALL</span> PUZZLES
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to='/leaderboard' onClick={() => setIsOpen(false)}>
                  LEADERBOARDS
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to='/howTo' onClick={() => setIsOpen(false)}>
                  HELP
                </NavLink>
              </NavItem>
              {/* <NavItem>
                  <NavLink tag={Link} to='/store'>
                    STORE
                  </NavLink>
                </NavItem> */}
            </ul>
          </Collapse>
        </Container>
      </div>
    </>

  )
}

export default NavMenu
