import SectionHeader from '../misc/SectionHeader'
import placeholderImage1 from '../../assets/blogs/worksheet-61925.jpeg';
import placeholderImage2 from '../../assets/blogs/worksheet-62675.png';
import placeholderImage3 from '../../assets/blogs/worksheet-62617.png';
import placeholderImage4 from '../../assets/blogs/worksheet-72755.png';
import React from 'react';
// @ts-ignore
import LazyLoad from 'react-lazy-load';
import { Link } from 'react-router-dom'
import { NavLink } from 'reactstrap'

const Worksheets = () => {
  return (
    <div className='home__jumbotron theme-light'>
      <div className='container'>
        <SectionHeader
          title='SOLVEMOJI WORKSHEETS'
        />
        <div className='section__subHeader'>
          <p>
            You can now download each puzzle as a pdf worksheet, these can be used in class or given as homework, click <NavLink style={{display: 'inline'}} tag={Link} to={'/worksheets'}>
                  <span className='colour__green section__highlightSmall'>
                    HERE
                  </span>
                </NavLink> for more information
          </p>
        </div>
        <div>
          <div className='worksheetExamples container'>
            <div className='row'>
            <div className='col-6 col-sm-3'>
                <LazyLoad debounce={false} offsetVertical={500}>
                  <img
                    alt='Worksheet Example 1'
                    src={placeholderImage1}
                    className="worksheet"
                  />
                </LazyLoad>
              </div>
              <div className='col-6 col-sm-3'>
                <LazyLoad debounce={false} offsetVertical={500}>
                  <img
                    alt='Worksheet Example 2'
                    src={placeholderImage2}
                    className="worksheet"
                  />
                </LazyLoad>
              </div>
              <div className='col-6 col-sm-3'>
                <LazyLoad debounce={false} offsetVertical={500}>
                  <img
                    alt='Worksheet Example 3'
                    src={placeholderImage3}
                    className="worksheet"
                  />
                </LazyLoad>
              </div>
              <div className='col-6 col-sm-3'>
                <LazyLoad debounce={false} offsetVertical={500}>
                  <img
                    alt='Worksheet Example 4'
                    src={placeholderImage4}
                    className="worksheet"
                  />
                </LazyLoad>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Worksheets
