import React from 'react';
import { iOS } from '../../helpers';

const _loaded = {};

class ImageLoader extends React.Component {
  //initial state: image loaded stage
  state = {
    loaded: _loaded[this.props.src],
    objLoaded: false,
  };

  //define our loading and loaded image classes
  static defaultProps = {
    className: '',
    loadingClassName: 'img-loading',
    loadedClassName: 'img-loaded',
  };

  //image onLoad handler to update state to loaded
  onLoad = () => {
    _loaded[this.props.src] = true;
    this.setState(() => ({ loaded: true }));
  };

  handleImageLoaded = () => {
    this.setState({ objLoaded: true });
  };

  render() {
    const { props } = this;
    const { className } = props;
    const isIos = iOS();

    if (this.state.loaded) {
      return <div>Loaded</div>;
    } else {
      return (
        <>
          {this.props.src && (
            <>
              {this.props.src.indexOf('svg') !== -1 && (
                <>
                  {isIos && (
                    <object
                      style={{
                        visibility: this.state.objLoaded ? 'visible' : 'hidden',
                      }}
                      data={this.props.src}
                      onLoad={this.handleImageLoaded}
                      className={className}
                    >
                      <img
                        alt='solvemojiFallback'
                        src={this.props.src}
                        className={className}
                      ></img>
                    </object>
                  )}
                  {!isIos && (
                    <img
                      alt='solvemojiImg'
                      src={this.props.src}
                      className={className}
                      style={{
                        position: 'absolute',
                      }}
                    />
                  )}
                </>
              )}
              {this.props.src.indexOf('svg') === -1 && (
                <>
                  <img
                    alt='prevIgm'
                    src={this.props.preview}
                    className={`${className} solvemoji-blur`}
                  />
                  <img
                    alt='oldStyleImage'
                    style={{
                      position: 'absolute',
                    }}
                    src={this.props.src}
                    className={'oldStyleImage ' + className}
                  ></img>
                </>
              )}
              <div className='imageOverlay'></div>
            </>
          )}
        </>
      );
    }
  }
}

export default ImageLoader;
