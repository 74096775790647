import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationState } from './index';
import { getUTCDate } from '../helpers';
const { utcDate } = getUTCDate();

export interface GameState {
    solution: number[]
    startIndex: number
    mindEmojiAnswers: number[][]
    mindEmojiGuesses: number[][]
    currentUTCDate: number
    leaderboard: number[]
    finished: boolean
}

const initialState: GameState = {
    solution: [],
    startIndex: 0,
    mindEmojiAnswers: [],
    mindEmojiGuesses: [],
    currentUTCDate: utcDate.getTime(),
    leaderboard: [0,0,0,0,0,0],
    finished: false
};

export const gameSlice = createSlice({
    name: 'game',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setSolution: (state, action: PayloadAction<number[]>) => {
            state.solution = action.payload;
        },
        setStartIndex: (state, action: PayloadAction<number>) => {
            state.startIndex = action.payload;
        },
        setMindEmojiAnswers: (state, action: PayloadAction<number[][]>) => {
            state.mindEmojiAnswers = action.payload;
        },
        setMindEmojiGuesses: (state, action: PayloadAction<number[][]>) => {
            state.mindEmojiGuesses = action.payload;
        },
        setNewUtcDate: (state, action: PayloadAction<number>) => {
            state.currentUTCDate = action.payload;
        },
        setGameFinished: (state, action: PayloadAction<boolean>) => {
            state.finished = action.payload;
        },
        setLeaderboard: (state, action: PayloadAction<number>) => {
            if(state.leaderboard){
                state.leaderboard[action.payload] = state.leaderboard[action.payload] + 1;
            } else {
                state.leaderboard = [0,0,0,0,0,0]
            }
        },
    },
});

export const {
    setSolution,
    setStartIndex,
    setMindEmojiAnswers,
    setMindEmojiGuesses,
    setNewUtcDate,
    setLeaderboard,
    setGameFinished
} = gameSlice.actions;

export const selectSolution = (state: ApplicationState) => state.game.solution;
export const selectStartIndex = (state: ApplicationState) => state.game.startIndex;
export const selectMindEmojiAnswers = (state: ApplicationState) => state.game.mindEmojiAnswers;
export const selectMindEmojiGuesses = (state: ApplicationState) => state.game.mindEmojiGuesses;
export const selectCurrentUTCDate = (state: ApplicationState) => state.game.currentUTCDate;
export const selectLeaderboard = (state: ApplicationState) => state.game.leaderboard;
export const selectFinish = (state: ApplicationState) => state.game.finished;

export default gameSlice.reducer;
