import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    pdfGetWorksheetUrl: build.query<
      PdfGetWorksheetUrlApiResponse,
      PdfGetWorksheetUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdf/GetWorksheetUrl`,
        params: { puzzleId: queryArg.puzzleId },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as baseApi };
export type PdfGetWorksheetUrlApiResponse = /** status 200 OK */ object;
export type PdfGetWorksheetUrlApiArg = {
  puzzleId: number;
};
export const { usePdfGetWorksheetUrlQuery } = injectedRtkApi;
