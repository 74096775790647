import React, { useRef, useState } from 'react'
import SolvemojiForm from '../misc/solvemojiForm'
import SolvemojiInput from '../misc/solvemojiInput'
import history from '../../history'

const SearchPuzzleId = (props: any) => {
  const [puzzleId, setPuzzleId] = useState('')
  const inputRef = useRef<HTMLInputElement>(null);

  const submit = () => {
    history.push(`/puzzle/${puzzleId}`)
    setPuzzleId('');
    inputRef?.current?.blur();
  }

  return (
    <div className='row'>
      <div className='pa__search m-auto'>
        <SolvemojiForm submit={submit}>
          <div>
            <SolvemojiInput
              pattern='[0-9]'
              title='please enter number only'
              name='puzzleId'
              autoComplete='off'
              maxLength={10}
              type='number'
              onChange={(e: any) => setPuzzleId(e.target.value)}
              placeholder='Search Puzzle ID...'
              value={puzzleId}
              className='pa__input pa__input-search'
              small={props.small}
              ref={inputRef}
            />
            <button type='submit' className={props.small ? '--small' : ''} aria-label="Search puzzle ID">
              <i className='icon-search'></i>
            </button>
          </div>
        </SolvemojiForm>


      </div>
    </div>
  )
}

export default SearchPuzzleId
