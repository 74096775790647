import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import NavMenu from './layout/NavMenu'
import PuzzleComplete from './puzzle/PuzzleComplete'
import ChangeNickname from './puzzle/ChangeNickname'
import SolvemojiConfirm from './puzzle/SolvemojiConfirm'
import '../styles/styles.scss'
import '../styles/layout.scss'
import { RouteComponentProps } from 'react-router'
import { ApplicationState } from '../store'
import * as AccountStore from '../store/Account'
import * as PuzzleStore from '../store/Puzzle'
import IStat from '../interfaces/IStat'
import { wwEndpoint } from '../constants'

type LayoutProps = {
  puzzleStore: PuzzleStore.PuzzleState
  accountStore: AccountStore.AccountState
  accountActions: typeof AccountStore.actionCreators
  puzzleActions: typeof PuzzleStore.actionCreators
} & RouteComponentProps<{}>

declare global {
  interface Window {
    sendGuess: any
    webSocket: WebSocket
    websocketInterval: number | undefined
    websocketAction: any
    resetWebSocket: () => void
    sendWebsocketMsg: (msg: string) => void
    connectToWebSocket: (action?: any) => void
  }
}

window.websocketInterval = undefined

window.sendGuess = () => {
  if (
    window.webSocket &&
    window.webSocket?.readyState === window.webSocket?.OPEN
  ) {
    window.webSocket?.send(`guess`)
  } else {
    window.resetWebSocket()
  }
}

window.sendWebsocketMsg = (msg: string) => {
  if (
    window.webSocket &&
    window.webSocket?.readyState === window.webSocket?.OPEN
  ) {
    window.webSocket?.send(msg)
  } else {
    window.resetWebSocket()
  }
}

window.resetWebSocket = () => {
  window.clearInterval(window.websocketInterval)
  window.websocketInterval = undefined
  window.connectToWebSocket()
}

window.connectToWebSocket = (action?: any) => {
  return new Promise((complete, failed) => {
    try {
      if (action) {
        window.websocketAction = action
      }

      var connect = () => {
        window.webSocket = new WebSocket(wwEndpoint)
        window.webSocket.onmessage = (e) => {
          const { data } = e as any
          const json = JSON.parse(data)
          const stat: IStat = {
            correct: json.c,
            total: json.g,
            online: json.u
          }

          if (window.websocketAction) {
            window.websocketAction(stat)
          } else if (action) {
            action(stat)
          }
        }

        // ping every 30 seconds to say they are online
        window.webSocket.onopen = () => {
          window.websocketInterval = window.setInterval(() => {
            window.sendWebsocketMsg('online')
          }, 30000)
        }
      }

      connect()
    } catch (err) {
      console.error(err)
    }
  })
}

class Layout extends React.PureComponent<LayoutProps> {
  async componentDidMount() {
    this.props.accountActions.refreshAuth()
    this.props.accountActions.getExternalLogins()
    window.connectToWebSocket(this.props.accountActions.setStats)

    // new TawkTo('630532e437898912e964bc0d', '1gb64tj07')
  }

  async componentDidUpdate(prevProps: any) {
    const { user_details } = this.props.accountStore

    if (user_details !== prevProps.accountStore.user_details) {
      this.props.puzzleActions.getLeaderboardRanks()
    }
  }

  public render() {
    const { session_token, user_details, showSetNickname, externalLogins } =
      this.props.accountStore
    const { showCompleted } = this.props.puzzleStore
    const { logOut, doShowNickname } = this.props.accountActions
    // const snowFlakes = new Array(30)
    // snowFlakes.fill(0)

    return (
      <>
        <NavMenu
          session_token={session_token}
          user_details={user_details}
          logOut={logOut}
          doShowNickname={doShowNickname}
          externalLogins={externalLogins}
        />
        {/* {
          snowFlakes.map(() => <div className="snowflake"></div>)
        } */}
        {this.props.children}
        {showCompleted && <PuzzleComplete />}
        <SolvemojiConfirm />
        {user_details && (showSetNickname || !user_details.Nickname) && (
          <ChangeNickname />
        )}
      </>
    )
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    puzzleActions: bindActionCreators(PuzzleStore.actionCreators, dispatch),
    accountActions: bindActionCreators(AccountStore.actionCreators, dispatch)
  }
}

export default connect(
  (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
  mapDispatchToProps // Selects which action creators are merged into the component's props
)(Layout as any)
