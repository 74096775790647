import * as React from 'react';
import { useState } from 'react';

const colours = [
  'rgb(232, 98, 164)',
  'rgb(239, 199, 64)',
  'rgb(134, 189, 54)',
  'rgb(121, 206, 244)',
  'rgb(155, 95, 163)',
];

interface ILoadingSpinner {
  transparent?: boolean
  light?: boolean
  whiteText?: boolean
  marginTop?: number
  message?: string
}

const LoadingSpinner = (props: ILoadingSpinner) => {
  const [colour] = useState(colours[Math.floor(Math.random() * 5)])

  let className = props.transparent
    ? 'theme-transparent'
    : props.light
      ? 'theme-white'
      : 'theme-dark';

  if (props.whiteText) {
    className += ' text-white';
  }

  return (
    <div className={`solvemojiLoading ${className}`}>
      <div
        className='loader'
        style={{
          borderTop: `16px solid ${colour}`,
          marginTop: props.marginTop || 40
        }}
      ></div>
      <div className='loadingMessage'>{props.message || 'Loading...'}</div>
    </div>
  );
};

export default LoadingSpinner;
