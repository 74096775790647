import * as React from 'react'
import { Helmet } from 'react-helmet'
import ColouredTitle from '../components/misc/ColouredTitle'
import { useEffect } from 'react'
import ShareThisSticky from '../components/misc/ShareThisSticky'
import Highlight from '../components/Highlight'
import solvemojiJunior from '../assets/books/solvemoji_cover_junior.jpeg';
import solvemojiHard from '../assets/books/solvemoji_cover_hard_master.jpg';
import solvemojiEasy from '../assets/books/solvemoji_cover_easy_medium.png';
import { useGetGeoLocationQuery } from '../store/geoApi'
import ReactGA from 'react-ga4'
import juniorLogo from '../assets/solvemoji-junior-logo.svg'
import nextLevelLogo from '../assets/solvemoji-next-level-logo.svg'
import { useLocation } from 'react-router'
import { getVideoId } from '../helpers'

interface IBooksContainer {
  home?: boolean
  hideWithSub?: boolean
}

// Prices
const priceGBP = 'GBP £8.99';
const priceUSD = 'USD $10.99';
const priceCAD = 'CAD $14.99';
const priceEUR = 'EUR €9.99';

// amazon
const amazonCom = 'https://www.amazon.com/stores/Laurence-Arnott/author/B0CXRC89WV';
const amazonCoUk = 'https://www.amazon.co.uk/stores/Laurence-Arnott/author/B0CXRC89WV';
const amazonCa = 'https://www.amazon.ca/stores/Laurence-Arnott/author/B0CXRC89WV'


// Junior Book URLs
const juniorBookGBPUrl = 'https://amzn.to/3UUUtxX';
const juniorBookUSDUrl = 'https://amzn.to/4aaSc5Z';
const juniorBookCADUrl = 'https://amzn.to/49zTZSa';
const juniorBookEURUrl = 'https://amzn.to/48RWrm6'

const juniorBook = {
  gbp: { url: juniorBookGBPUrl, price: priceGBP },
  usd: { url: juniorBookUSDUrl, price: priceUSD },
  nzd: { url: juniorBookUSDUrl, price: priceUSD },
  cad: { url: juniorBookCADUrl, price: priceCAD },
  aud: { url: juniorBookUSDUrl, price: priceUSD },
  eur: { url: juniorBookEURUrl, price: priceEUR }
};

// Next Level Easy Book URLs
const nextLevelEasyBookGBPUrl = 'https://amzn.to/3wNlCZE';
const nextLevelEasyBookUSDUrl = 'https://amzn.to/3PjGY7m';
const nextLevelEasyBookCADUrl = 'https://amzn.to/3PfMxU6';
const nextLevelEasyBookEURUrl = 'https://amzn.to/4ci2d3l';

const nextLevelEasyBook = {
  gbp: { url: nextLevelEasyBookGBPUrl, price: priceGBP },
  usd: { url: nextLevelEasyBookUSDUrl, price: priceUSD },
  nzd: { url: nextLevelEasyBookUSDUrl, price: priceUSD },
  cad: { url: nextLevelEasyBookCADUrl, price: priceCAD },
  aud: { url: nextLevelEasyBookUSDUrl, price: priceUSD },
  eur: { url: nextLevelEasyBookEURUrl, price: priceEUR }
};

// Next Level Hard Book URLs
const nextLevelHardBookGBPUrl = 'https://amzn.to/3SXVEd6';
const nextLevelHardBookUSDUrl = 'https://amzn.to/3Tee6yl';
const nextLevelHardBookCADUrl = 'https://amzn.to/433lKjN';
const nextLevelHardBookEURUrl = 'https://amzn.to/4cesy1V';

const nextLevelHardBook = {
  gbp: { url: nextLevelHardBookGBPUrl, price: priceGBP },
  usd: { url: nextLevelHardBookUSDUrl, price: priceUSD },
  nzd: { url: nextLevelHardBookUSDUrl, price: priceUSD },
  cad: { url: nextLevelHardBookCADUrl, price: priceCAD },
  aud: { url: nextLevelHardBookUSDUrl, price: priceUSD },
  eur: { url: nextLevelHardBookEURUrl, price: priceEUR }
};

const recordBookClick = (type: string) => {
  ReactGA.event({
    category: 'Link',
    action: 'Click',
    label: type
  });
}

export function BooksContainer({ home = false }: IBooksContainer) {
  const { data } = useGetGeoLocationQuery(undefined)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const levelId = searchParams.get('levelId');
  const videoId = getVideoId(levelId);

  useEffect(() => {
    // @ts-ignore
    window.scrollTo(0, 0)
  }, [])

  const juniorBookDetails = juniorBook[data?.currency as keyof typeof juniorBook ?? 'usd']
  const nextLevelEasyDetails = nextLevelEasyBook[data?.currency as keyof typeof nextLevelEasyBook ?? 'usd']
  const nextLevelHardDetails = nextLevelHardBook[data?.currency as keyof typeof nextLevelHardBook ?? 'usd']


  return (
    <>
      {!home ? <Helmet>
        <title>Puzzle books</title>
      </Helmet> : null}

      <div className='home__jumbotron theme-dark pt-30 pb-40 regPage p-0'>
        <div className='container'>
          <div className='row'>
            <div className='col col-12 regPage__form p-0'>
              <ColouredTitle title='Puzzle books' />
              <div className='puzzle__text-medium'>
                <p>
                  Now enjoy your favourite Solvemoji puzzles in <Highlight top={2} text='book format 📚' />
                </p>
                <p>
                  We have 3 <Highlight top={2} text='large print' /> (8.5 x 11 in) workbooks in vivid <Highlight top={2} text='colour' /> available,
                  <br /><Highlight top={2} text='Junior' />, <Highlight top={2} text='Easy-Medium' /> and <Highlight top={2} text='Hard-Expert-Master' />
                </p>
                <p>Only <Highlight top={2} text={juniorBookDetails.price} /> per book!</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className='videoWrapper books-video'>
                <iframe
                  src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&loop=1&playlist=${videoId}`}
                  frameBorder="0"
                  title='Solvemoji books'
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}>
                </iframe>
              </div>
            </div>
          </div>
          <div className="book-container">
            <div className='puzzle__text-medium'>
              <p>Click on a book below to buy</p>
              <p>Each book contains <Highlight top={2} text='150' /> Solvemoji puzzles & solutions</p>
            </div>
            <div className="row books">
              <div className="col-12 col-sm-4 book">
                <div className="bookImage-j">
                  <img
                    src={juniorLogo}
                    alt='juniorlogo'
                    className='todaysPuzzles__logo-junior'
                  />
                  <div>Levels 1-5</div>
                </div>
                <a onClick={() => recordBookClick('Junior')} href={juniorBookDetails.url} rel="noopener noreferrer" target="_blank">
                  <img src={solvemojiJunior} alt="Solvemoji Junior 150 Vol 1" />
                  <button className="btn btn-primary mt-10">Buy Now - {juniorBookDetails.price}</button>
                </a>
              </div>
              <div className="col-12 col-sm-4 book">
                <div className="bookImage-nl">
                  <img
                    src={nextLevelLogo}
                    alt='juniorlogo'
                    className='todaysPuzzles__logo-junior'
                  />
                  <div>Easy/Medium</div>
                </div>
                <a onClick={() => recordBookClick('NextLevelEasy')} rel="noopener noreferrer" href={nextLevelEasyDetails.url} target="_blank">
                  <img src={solvemojiEasy} alt="Solvemoji Junior 150 Vol 1" />
                  <button className="btn btn-primary mt-10">Buy Now - {nextLevelEasyDetails.price}</button>
                </a>
              </div>
              <div className="col-12 col-sm-4 book">
                <div className="bookImage-nl">
                  <img
                    src={nextLevelLogo}
                    alt='juniorlogo'
                    className='todaysPuzzles__logo-junior'
                  />
                  <div>Hard/Expert/Master</div>
                </div>
                <a onClick={() => recordBookClick('NextLevelHard')} rel="noopener noreferrer" href={nextLevelHardDetails.url} target="_blank">
                  <img src={solvemojiHard} alt="Solvemoji Next Level Hard/Master Vol 1" />
                  <button className="btn btn-primary mt-10">Buy Now - {nextLevelHardDetails.price}</button>
                </a>
              </div>
            </div>
            <p>Available at <a onClick={() => recordBookClick('AmazonCom')} rel="noopener noreferrer" href={amazonCom} target="_blank">
              <Highlight top={2} text='Amazon.com' />
            </a>, <a onClick={() => recordBookClick('AmazonCom')} rel="noopener noreferrer" href={amazonCoUk} target="_blank">
                <Highlight top={2} text='Amazon.co.uk' />
              </a>,<br /><a onClick={() => recordBookClick('AmazonCom')} rel="noopener noreferrer" href={amazonCa} target="_blank">
                <Highlight top={2} text='Amazon.ca' />
              </a>  and <Highlight top={2} text='many more' />!<br />
              Click on a puzzle book above, or search <Highlight top={2} text='Solvemoji' /> to view all workbooks</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default ShareThisSticky(BooksContainer)
