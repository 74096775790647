import React, { PureComponent } from 'react';
import { isMobile } from '../../helpers';

interface SolvemojiFormProps {
  submit: () => void;
  className?: string;
}

class SolvemojiForm extends PureComponent<SolvemojiFormProps> {
  formEl?: HTMLFormElement | null = null;

  state = {
    isValidated: false,
  };

  setValidation = () => {
    this.setState({ isValidated: false });
  };

  validate = () => {
    const formEl = this.formEl;
    if (formEl) {
      const formLength = formEl.length;

      if (formEl.checkValidity() === false) {
        for (let i = formLength - 1; i >= 0; i--) {
          const elem = formEl[i] as HTMLFieldSetElement;
          if (elem && elem.parentNode) {
            const errorLabel = elem.parentNode.querySelector(
              '.invalid-feedback'
            );

            if (errorLabel && elem.nodeName.toLowerCase() !== 'button') {
              if (!elem.validity.valid) {
                errorLabel.textContent = elem.validationMessage;
              } else {
                errorLabel.textContent = '';
              }
            }

            if (!isMobile() && !elem.validity.valid) {
              elem.focus();
            }
          }
        }

        return false;
      } else {
        for (let i = formLength - 1; i >= 0; i--) {
          const elem = formEl[i] as HTMLFieldSetElement;
          if (elem && elem.parentNode) {
            const errorLabel = elem.parentNode.querySelector(
              '.invalid-feedback'
            );
            if (errorLabel && elem.nodeName.toLowerCase() !== 'button') {
              errorLabel.textContent = '';
            }
          }
        }

        return true;
      }
    }
  };

  submitHandler = (event: any): void => {
    event.preventDefault();

    if (this.validate()) {
      this.props.submit();
    }

    this.setState({ isValidated: true });
  };

  render() {
    const { className, submit, ...props } = this.props;

    let classNames: string[] = [];
    if (className) {
      classNames = className.split(' ');
    }

    if (this.state.isValidated) {
      classNames.push('was-validated');
    }

    return (
      <form
        {...props}
        className={classNames.join(' ')}
        noValidate
        ref={(form) => (this.formEl = form)}
        onSubmit={this.submitHandler}
      >
        {this.props.children}
      </form>
    );
  }
}

export default SolvemojiForm;
