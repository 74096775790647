import { useEffect, useState } from 'react'
import { MapMinifiedPuzzle } from '../helpers'
import { IPuzzle } from '../interfaces/IPuzzle'
import { PuzzleMinified } from '../store/generated/puzzlesApi'

export const useUnminifyPuzzles = (data: PuzzleMinified[] | undefined, success: boolean): IPuzzle[] => {
  const [unminified, setUnminified] = useState<IPuzzle[]>([])

  useEffect(() => {
    if(success && data){
      setUnminified(MapMinifiedPuzzle(data))
    }
  }, [data, success])

  return unminified
}