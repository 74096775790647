import * as React from 'react'
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { ApplicationState } from '../../store'
import * as PuzzleStore from '../../store/Puzzle'
import ColouredTitle from '../misc/ColouredTitle'
import { shuffleArray, sortByNumberVal } from '../../helpers'
import LottieLeaderboard from '../home/LottieLeaderboard'
import LottieConfetti from '../home/LottieConfetti'
import MyRankings from './MyRankings'
import history from '../../history'
import { IPuzzle } from '../../interfaces/IPuzzle'
import { useActiveProduct } from '../../hooks/useActiveProduct'
import { useApiPuzzleGetAllTodaysLatestPuzzlesLatestQuery } from '../../store/generated/puzzlesApi'
import { useUnminifyPuzzles } from '../../hooks/useUnminifyPuzzles'

// @ts-ignore
var ReactFitText = require('react-fittext')

type PuzzleProps = PuzzleStore.PuzzleState &
  typeof PuzzleStore.actionCreators &
  RouteComponentProps<{}>

function translateFormat(input: string) {
  const [column, row] = input.split('-');
  const rowLetter = String.fromCharCode(64 + parseInt(row));
  return rowLetter + column;
}

const PuzzleComplete = (props: PuzzleProps) => {
  const activeProduct = useActiveProduct()
  const [slogan, setSlogan] = useState('')
  const getTodaysPuzzlesResults = useApiPuzzleGetAllTodaysLatestPuzzlesLatestQuery()
  const todaysPuzzlesData = useUnminifyPuzzles(getTodaysPuzzlesResults.data, getTodaysPuzzlesResults.isSuccess)

  useEffect(() => {
    props.getNewPuzzles()
    const text = [
      'Congratulations!',
      'Good Job!',
      'Super!',
      'Amazing!',
      'Well Done!',
      'Fantastic!',
      'Impressive!',
      'Incredible!',
    ]
    setSlogan(text[Math.floor(Math.random() * text.length)])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filterPuzzles = (puzzles: any) => {
    const { solvedIds, revealedIds } = props

    if (solvedIds && solvedIds.length > 0) {
      puzzles = puzzles.filter(
        (x: IPuzzle) => !solvedIds.find((y: number) => y === x.PuzzleId)
      )
    }

    if (revealedIds && revealedIds.length > 0) {
      puzzles = puzzles.filter(
        (x: IPuzzle) => !revealedIds.find((y: number) => y === x.PuzzleId)
      )
    }

    return puzzles
  }

  const handleNo = () => {
    props.doShowCompleted(false)
  }

  const handleYes = () => {
    const { puzzles: puzzlesStore, completedPuzzle: puzzle, attemptedIds, solvedIds } = props

    if (!activeProduct) {
      if (attemptedIds.length < 5) {

        if (puzzle) {
          let nextPuzzle = todaysPuzzlesData.filter(
            (x) =>
              !solvedIds.find(y => y === x.PuzzleId) &&
              x.PuzzleId !== puzzle.PuzzleId &&
              x.PuzzleTypeId === puzzle.PuzzleTypeId &&
              x.PuzzleTheme === puzzle.PuzzleTheme
          )

          if (puzzle.LevelId <= 5) {
            nextPuzzle = nextPuzzle.filter(x => x.LevelId <= 5).sort(sortByNumberVal('LevelId', true))
          } else {
            nextPuzzle = nextPuzzle.filter(x => x.LevelId >= 6).sort(sortByNumberVal('LevelId', true))
          }

          let nextPuzzle1 = todaysPuzzlesData.find(
            (x) =>
              !solvedIds.find(y => y === x.PuzzleId) &&
              x.PuzzleId !== puzzle.PuzzleId &&
              x.LevelId === puzzle.LevelId &&
              x.PuzzleTheme === puzzle.PuzzleTheme
          )

          if (nextPuzzle) {
            history.push('/puzzle/' + nextPuzzle[0].PuzzleId)
          } else if (nextPuzzle1) {
            history.push('/puzzle/' + nextPuzzle1.PuzzleId)
          } else {
            history.push('/today')
          }
        } else {
          history.push('/today')
        }
      } else {
        history.push('/today')
      }

      props.doShowCompleted(false)
      return
    }

    if (puzzle) {
      let puzzles = puzzlesStore.filter(
        (x) =>
          x.PuzzleId !== puzzle.PuzzleId &&
          x.PuzzleTypeId === puzzle.PuzzleTypeId &&
          x.LevelId === puzzle.LevelId &&
          x.PuzzleTheme === puzzle.PuzzleTheme
      )

      puzzles = filterPuzzles(puzzles)

      // if we do not have any more puzzles of this type available, send to new puzzles page
      if (puzzles.length === 0) {
        puzzles = puzzlesStore.filter(
          (x) =>
            x.PuzzleId !== puzzle.PuzzleId &&
            x.PuzzleTypeId === puzzle.PuzzleTypeId &&
            x.LevelId === puzzle.LevelId
        )

        puzzles = filterPuzzles(puzzles)

        // if we do not have any more puzzles of this type available, send to new puzzles page
        if (puzzles.length === 0) {
          history.push('/today')
          props.doShowCompleted(false)
        }
        // otherwise sent to direct puzzle page
        else {
          showNewPuz(puzzles)
        }
      }
      // otherwise sent to direct puzzle page
      else {
        showNewPuz(puzzles)
      }
    }
  }

  const showNewPuz = (puzzles: any) => {
    shuffleArray(puzzles)
    props.doShowCompleted(false)

    history.push(`/puzzle/${puzzles[0].PuzzleId}`)
  }

  // const redirect = (route: string) => {
  //   history.push(route)
  //   props.doShowCompleted(false)
  // }

  const handleLogin = () => {
    history.push(`/login`)
    props.doShowCompleted(false)
  }

  const handleRegister = () => {
    history.push(`/register`)
    props.doShowCompleted(false)
  }

  const { showCompleted, completedPuzzle, userRanks } = props

  if (!showCompleted || !completedPuzzle) {
    return null
  }

  const getPuzzleAnswer = () => {
    if (completedPuzzle.PuzzleTypeId === 5) {
      const answer = completedPuzzle.AnswerWord ?? '1-1';
      return translateFormat(answer);
    }

    return completedPuzzle.Answer || completedPuzzle.AnswerWord;
  }

  return (
    <div className='puzzleComplete'>
      <LottieConfetti />
      <div className='row'>
        <div className='col-12 col-sm-1 col-md-2 col-lg-3'></div>
        <div className='col-12 col-sm-10 col-md-8 col-lg-6'>
          <div className='message correctGuess-11'>
            <div className='modal__close' onClick={handleNo}>
              <i className='icon-times'></i>
            </div>
            <div className='row'>
              <div className='col col-12 text-center'>
                <ReactFitText compressor={1.1}>
                  <ColouredTitle title={slogan} />
                </ReactFitText>
                <div className='puzzleComplete__text'>
                  <span className='colour__green section__highlight'>
                    {getPuzzleAnswer()}
                  </span>{' '}
                  {completedPuzzle.PuzzleTypeId === 4 ? 'This ' : ' '}is the correct answer
                </div>

                <div className='row'>
                  <div className='col-12 col-sm-3 mobileHide flippedX'>
                    <LottieLeaderboard />
                  </div>
                  <div className='col-12 col-sm-6 md-12 text-center m-auto'>
                    <div className='imageBackground'>
                      <img
                        src={
                          completedPuzzle.AnswerImageSvg ||
                          completedPuzzle.AnswerImageUrl
                        }
                        alt={`puzzleId_${completedPuzzle.PuzzleId}`}
                        className='puzzleComplete__image'
                      />
                    </div>
                  </div>
                  <div className='col-12 col-sm-3 mobileHide'>
                    <LottieLeaderboard />
                  </div>
                </div>

                {userRanks && userRanks.length === 3 && <MyRankings />}
                <div className='puzzleComplete__text'>
                  Would you like to play another puzzle?
                </div>
                <div className='row mt-20'>
                  <div className='col text-right'>
                    <button
                      className='btn btn-lg puzzleComplete__button-yes'
                      onClick={handleYes}
                    >
                      <i className='icon-thumbs-o-up mr-10'></i>
                      <span>
                        Yes<span className='tinyHide'> Please</span>
                      </span>
                    </button>
                  </div>
                  <div className='col text-left'>
                    <button
                      className='btn btn-lg puzzleComplete__button-no'
                      onClick={handleNo}
                    >
                      <i className='icon-thumbs-o-down mr-10'></i>
                      <span>
                        No<span className='tinyHide'> Thanks</span>
                      </span>
                    </button>
                  </div>
                </div>

                {!userRanks ||
                  (userRanks.length !== 3 && (
                    <>
                      <div className='puzzleComplete__text-small mt-30'>
                        <span className='colour__green section__highlight'>
                          Uh-oh!
                        </span>{' '}
                        You are missing out on the leaderboard why not login
                        or join below...
                      </div>
                      <div className='row mt-20'>
                        <div className='col col-6 text-right'>
                          <button
                            className='btn btn-lg puzzleComplete__button-login'
                            onClick={handleLogin}
                          >
                            <i className='icon-sign-in mr-10'></i>
                            <span>Login</span>
                          </button>
                        </div>
                        <div className='col col-6 text-left'>
                          <button
                            className='btn btn-lg puzzleComplete__button-register'
                            onClick={handleRegister}
                          >
                            <i className='icon-user-plus mr-10'></i>
                            <span>Register</span>
                          </button>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-1 col-md-2 col-lg-3'></div>
      </div>
    </div>
  )
}

export default connect(
  (state: ApplicationState) => state.puzzleStore, // Selects which state properties are merged into the component's props
  PuzzleStore.actionCreators // Selects which action creators are merged into the component's props
)(PuzzleComplete as any)
