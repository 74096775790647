import React from "react"
import { useDowloadWorksheet } from "./puzzle/useDownloadWorksheet"

interface IOpenWorksheet {
    puzzleId: number
    message?:string
}

export const OpenWorksheet = ({ puzzleId, message = 'click here to download this sample worksheet' }: IOpenWorksheet) => {
    const savePuzzleWorksheet = useDowloadWorksheet()
    return <div className="text-center sample" onClick={() => savePuzzleWorksheet(puzzleId)}>{message}</div>
}
