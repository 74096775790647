export const SecretKey =
  'Tj#BbLUnZxa+|6xo64mYDfmJX3K9oha&|#iULyE$WG&@2uC5H&+DeMDIO?$sFjEyPB?h?iY_!V8ZL125-HeZxNzS@a?=PJ*tTQ2Vn??Ml47D|3b7o76-+H?u5q%^zuO2';
const EndPointStr = process.env.REACT_APP_API_URL;//'api.solvemoji.com'; //'localhost:44363'; // 'https://solvemojitest.azurewebsites.net/'; //'https://localhost:44363'; //'https://solvemojitest.azurewebsites.net';
export const EndPoint: string = `https://${EndPointStr}`;
export const wwEndpoint: string = `wss://${EndPointStr}/api/websocket`;

export const Level = {
  Unset: undefined,
  Junior1: 6,
  Junior2: 7,
  Junior3: 8,
  Junior4: 9,
  Junior5: 10,
  Easy: 1,
  Medium: 2,
  Hard: 3,
  Expert: 4,
  Master: 5
};

export const PuzzleType = {
  Mixed: undefined,
  Classic: 1,
  Grid: 2,
  Word: 3,
  Sudoku: 4,
  OddEmoji: 5
};

export const LevelColours = {
  [Level.Easy]: 'rgb(124, 176, 66)',
  [Level.Medium]: 'rgb(253, 203, 46)',
  [Level.Hard]: 'rgb(187, 41, 20)',
  [Level.Expert]: 'rgb(0,0,0)',
  [Level.Master]: 'rgb(0,49,177)',
  [Level.Junior1]: 'rgb(133,224,102)',
  [Level.Junior2]: 'rgb(255,224,102)',
  [Level.Junior3]: 'rgb(255,102,133)',
  [Level.Junior4]: 'rgb(126,126,126)',
  [Level.Junior5]: 'rgb(102,102,255)'
};

export const LevelColoursGrid = {
  [Level.Easy]: 'rgb(189,219,157)',
  [Level.Medium]: 'rgb(254, 227, 148)',
  [Level.Hard]: 'rgb(245, 176, 167)',
  [Level.Expert]: 'rgb(155, 155, 155)',
  [Level.Master]: 'rgb(130, 164, 255)',
  [Level.Junior1]: 'rgb(186, 233, 143)',
  [Level.Junior2]: 'rgb(250, 233, 142)',
  [Level.Junior3]: 'rgb(243, 146, 166)',
  [Level.Junior4]: 'rgb(164, 164, 164)',
  [Level.Junior5]: 'rgb(160, 154, 255)'
};

export const titleColours = [
  'rgb(121, 206, 244)', // light blue
  'rgb(134, 189, 54)', // green
  'rgb(246, 102, 132)', // pink
  'rgb(155, 95, 163)', // purplie
  'rgb(239, 199, 64)', // yellow
  'rgb(0, 160, 219)' // darkblue
];

export const validationPattern =
  '^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&*^/=?_.,:;\\-])|(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*^/=?_.,:;\\-])|(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%&*^/=?_.,:;\\-])|(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&*^/=?_.,:;\\-])).{8,}$';
export const validationMessage =
  'At least 8 characters long, include a special character !@#$%^&* and a minimum 2 of the following - Uppercase, Lowercase, Number';

export const LeaderboardType = {
  AllTime: 0,
  Weekly: 1,
  Daily: 2
};

export enum videos {
  classrooms = 'Classrooms.mp4',
  classroomLeaderboards = 'ClassroomLeaderboards.mp4',
  qrCode = 'QRCode.mp4',
  studentLeaderboard = 'StudentLeadeboard.mp4',
  students = 'Students.mp4',
  teacherPortal = 'TeacherPortal.mp4'
}
