import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { getCurrencySymbol, getCurrencySymbolVerbose } from './stripeHelpers'
import { StripeProducts, SpecialOffer, Price, PriceCurrencyOptions, OrdersRead, Product } from '../../store/generated/checkoutsApi'
import uk from '../../assets/flags/flag-united-kingdom.svg'
import us from '../../assets/flags/flag-united-states.svg'
import nz from '../../assets/flags/flag-new-zealand.svg'
import au from '../../assets/flags/flag-australia.svg'
import ca from '../../assets/flags/flag-canada.svg'
import eu from '../../assets/flags/flag-european-union.svg'
import { scrollTo } from '../../helpers'
import { useWindowSize } from '../../hooks/useWindowResizeObserver'
import VideoPreview from './VideoPreview'
import { FaLock, FaRegPlayCircle } from 'react-icons/fa'
import { videos } from '../../constants'
import { Period } from '../../enums/Period'
import { FaEye } from 'react-icons/fa'
import { OpenWorksheet } from '../OpenWorksheet'
import { AccountState } from '../../store/Account'
import Highlight from '../Highlight'

interface IPricingComponent {
  pricingData?: StripeProducts
  loggedIn: boolean
  currency?: string
  orders?: OrdersRead[]
  specialOffer?: SpecialOffer
  setShowLoginWarning?: (show: boolean) => void
  accountStore?: AccountState
  canChangeCurrency?: boolean
}

interface IPricingProps {
  symbol: string
  amount?: number
  amountMax?: number
  onClick?: () => void
  specialOffer?: SpecialOffer
  period: Period
  setPeriod: (period: Period) => void
}

// const studentNumbers = [30, 60, 90, 300]

const Pricing = (props: IPricingProps) => {
  const { amount, amountMax, symbol, period } = props;
  const perMonth = period === Period.YEAR ? ((amount || 0) / 100 / 12) : (amount || 0) / 100;
  const perYear = (amount || 0) / 100;

  const perMonthMax = amountMax ? (period === Period.YEAR ? ((amountMax || 0) / 100 / 12) : (amountMax || 0) / 100) : 0;
  const perYearMax = amountMax ? (amountMax || 0) / 100 : 0;

  const format = (price: number, round: boolean = false) => (round ? Math.round(price * 100) : Math.floor(price * 100)) / 100;
  const formatVal = (val: number, round: boolean = false) => `${symbol}${format(val, round)}`;

  // let perMonthDiscount = 0;
  let perYearDiscount = 0;

  // let perMonthMaxDiscount = 0;
  let perYearMaxDiscount = 0;

  // Artificially inflate the yearly price and apply a 50% discount
  let inflatedPerYear = perYear * 2 + 0.01;
  let inflatedPerYearMax = perYearMax * 2 + 0.01;

  let discountedPerYear = inflatedPerYear * 0.5;
  let discountedPerYearMax = inflatedPerYearMax * 0.5;

  // if (specialOffer) {
  //   const percentage = (100 - (specialOffer?.PercentageDiscount || 0)) / 100;

  //   perMonthDiscount = ((amount || 0) * percentage / 100 / 12);
  //   perYearDiscount = discountedPerYear * percentage;

  //   perMonthMaxDiscount = amountMax ? ((amountMax * percentage || 0) / 100 / 12) : 0;
  //   perYearMaxDiscount = amountMax ? (discountedPerYearMax * percentage || 0) / 100 : 0;
  // } else {
  perYearDiscount = discountedPerYear;
  perYearMaxDiscount = discountedPerYearMax;
  // }

  return (
    <>
      <>
        <span>
          {
            period === Period.YEAR ? (
              <>
                {/* <s>{formatVal(inflatedPerYear)}{amountMax ? ` - ${formatVal(inflatedPerYearMax)}` : ''}</s>&nbsp; */}
                {formatVal(perYearDiscount)}{amountMax ? ` - ${formatVal(perYearMaxDiscount)}` : ''} Per Year
              </>
            ) : null
          }
        </span>
        {
          period === Period.MONTH ? (
            <span>
              {formatVal(perMonth)}{amountMax ? ` - ${formatVal(perMonthMax)}` : ''} Per Month
            </span>
          ) : null
        }
      </>
    </>
  );
};

const Subscribe = (props: IPricingProps) => {
  const { amount, symbol, onClick, period } = props
  const perYear = (amount || 0) / 100
  const percentage = 1
  const format = (price: number) => Math.floor(price * 100 * percentage) / 100

  return (
    <button className='btn btn-primary' onClick={onClick}>Subscribe - {symbol}{format(perYear)}<br />{period === Period.YEAR ? 'per year' : 'per month'} *</button>
  )
}

// const PurchaseOrder = (props: IPurchaseOrder) => {
//   const { onClick } = props

//   return (
//     <button className='mt-10 btn btn-success' onClick={onClick}>Purchase Order Enquiry</button>
//   )
// }

const Tick = () => <i className="icon-check icon colour__tickGreen"></i>
const Cross = () => <i className="icon-times icon colour__crossGrey"></i>


interface IPricingTable {
  freeTier: boolean
  individualTier: boolean
  teacherTier: boolean
  setPeriod: (period: Period) => void
}

export default function PricingComponent(props: IPricingComponent) {
  const { pricingData, loggedIn, orders, setShowLoginWarning, specialOffer, currency, canChangeCurrency } = props

  const [showVideo, setShowVideo] = useState(false)
  const [showCurrecies, setShowCurrencies] = useState(false)
  const [video, setVideo] = useState('')
  const [videoTitle, setVideoTitle] = useState('')
  const [videoMessage, setVideoMessage] = useState('')

  const [symbol, setSymbol] = useState('$')
  const [symbolVerbose, setSymbolVerbose] = useState('USD $')
  const [teacherStudents, setTeacherStudents] = useState(30)
  const history = useHistory()
  const size = useWindowSize()

  const [period, setPeriod] = useState<Period>(Period.YEAR)
  const [country, setCountry] = useState(currency || 'usd')

  useEffect(() => {
    if (currency) {
      setCountry(currency)
    }
  }, [currency])

  useEffect(() => {
    setSymbol(getCurrencySymbol(country))
    setSymbolVerbose(getCurrencySymbolVerbose(country))
  }, [country])

  const individualProduct = pricingData?.Products?.data?.find((x: any) => x.name === 'Individual Subscription')
  const teacherProduct = pricingData?.Products?.data?.find((x: any) => x.name === 'Educational / Teacher Subscription')
  const teacherProducts = pricingData?.Products?.data?.filter((x) => {
    const teacherSub = x?.name?.indexOf('Educational / Teacher Subscription')
    return teacherSub === undefined ? false : teacherSub > -1
  })

  const teacherSelected = teacherProducts?.find(x => x.name?.toString().endsWith(` ${teacherStudents.toString()}`))

  const teacher30 = teacherProducts?.find(x => x.name?.toString().endsWith(' 30'))
  const teacher60 = teacherProducts?.find(x => x.name?.toString().endsWith(' 60'))
  const teacher90 = teacherProducts?.find(x => x.name?.toString().endsWith(' 90'))
  const teacher300 = teacherProducts?.find(x => x.name?.toString().endsWith(' 300'))

  const teacherSelectedPrice = pricingData?.Prices?.data?.find((x: Price) => x.product === teacherSelected?.id && x.recurring?.interval === period)
  const teacherSelectedPrice_currency: PriceCurrencyOptions | undefined = teacherSelectedPrice?.currency_options && teacherSelectedPrice?.currency_options[country]

  const getTeacherPrice = useCallback((selected?: Product): PriceCurrencyOptions | undefined => {
    const teacherPrice = pricingData?.Prices?.data?.find((x: Price) => x.product === selected?.id && x.recurring?.interval === period)
    return teacherPrice?.currency_options && teacherPrice?.currency_options[country]
  }, [country, period, pricingData])

  const individual: Price | undefined = pricingData?.Prices?.data?.find((x: any) => x.product === individualProduct?.id && x.recurring?.interval === period)
  const individual_currency: PriceCurrencyOptions | undefined = individual?.currency_options && individual?.currency_options[country]

  const hasIndividual = orders?.find(x => !x.IsExpired && x.ProductId === individualProduct?.id)
  const hasTeacher = orders?.find(x => !x.IsExpired && x.ProductId === teacherProduct?.id)

  const activeOrders = orders?.find(x => !x.IsExpired)

  const doIndividual = useCallback(() => {
    if (loggedIn) {
      history.push(`/checkout/${individual?.id}?currency=${country}`)
    } else if (setShowLoginWarning) {
      setShowLoginWarning(true)
    }
  }, [country, history, individual, loggedIn, setShowLoginWarning])

  const doTeacher = useCallback((teacherPrice: any) => {
    if (loggedIn) {
      history.push(`/checkout/${teacherPrice?.id}?currency=${country}`)
    } else if (setShowLoginWarning) {
      setShowLoginWarning(true)
    }
  }, [country, history, loggedIn, setShowLoginWarning])

  const setVideoPreview = (title: string, message: string, vid: string) => {
    setVideo(vid)
    setVideoTitle(title)
    setVideoMessage(message)
    setShowVideo(true)
  }

  const ShowVideo = (props: { title: string, message: string, vid: string }) => (
    <FaRegPlayCircle
      onClick={() => setVideoPreview(props.title, props.message, props.vid)}
      className='showMe'
    />
  )

  const doSetPeriod = (period: Period) => {
    scrollTo('#currencyButtons')
    setPeriod(period)
  }

  const goToWorksheets = () => {
    window.location.href = '/worksheets'
  }

  const onChangeValue = (event: any) => {
    setTeacherStudents(Number(event?.target.value))
  }


  const PricingTable = useCallback((props: IPricingTable) => {
    const { freeTier, individualTier, teacherTier, setPeriod } = props

    return (<table id="prices" className="theme-white container table table-sm table-striped solvemojiTable pricing-table">
      <thead>
        <tr>
          <th>Feature</th>
          {freeTier && <th>Free Play</th>}
          {individualTier && <th>Individual</th>}
          {teacherTier && <th>Teacher</th>}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Leaderboards</td>
          {freeTier && <td><Tick /></td>}
          {individualTier && <td><Tick /></td>}
          {teacherTier && <td><Tick /></td>}
        </tr>
        {
          freeTier && <tr>
            <td>3 Free Daily Puzzles</td>
            {freeTier && <td><Tick /></td>}
            {individualTier && <td><Tick /></td>}
            {teacherTier && <td><Tick /></td>}
          </tr>
        }
        {(individualTier || teacherTier) && (<tr>
          <td>Play All Puzzles</td>
          {freeTier && <td><Cross /></td>}
          {individualTier && <td><Tick /></td>}
          {teacherTier && <td><Tick /></td>}
        </tr>)}
        {(individualTier || teacherTier) && (
          <tr>
            <td>Remove Ads</td>
            {freeTier && <td><Cross /></td>}
            {individualTier && <td><Tick /></td>}
            {teacherTier && <td><Tick /></td>}
          </tr>
        )}
        {(individualTier || teacherTier) && (<tr>
          <td>Price locked <FaLock title="Subscription price is set for life" className="showMe" /><br />
            <span className="sampleText">Subscription price is set for life</span></td>
          {freeTier && <td><Cross /></td>}
          {individualTier && <td><Tick /></td>}
          {teacherTier && <td><Tick /></td>}
        </tr>)}
        {(individualTier || teacherTier) && (<tr>
          <td>
            Download Worksheets <FaEye className="showMe --big" onClick={goToWorksheets} />
            <OpenWorksheet puzzleId={62617} message={'Download example - click here'} />
          </td>
          {freeTier && <td><Cross /></td>}
          {individualTier && <td><Tick /></td>}
          {teacherTier && <td><Tick /></td>}
        </tr>)}
        {(individualTier || teacherTier) && (<tr>
          <td>Download Puzzles</td>
          {freeTier && <td><Cross /></td>}
          {individualTier && <td><Tick /></td>}
          {teacherTier && <td><Tick /></td>}
        </tr>)}
        {(individualTier || teacherTier) && (<tr>
          <td>Reveal Solutions</td>
          {freeTier && <td><Cross /></td>}
          {individualTier && <td><Tick /></td>}
          {teacherTier && <td><Tick /></td>}
        </tr>)}
        {teacherTier && (<tr>
          <td>Teacher Portal <ShowVideo title="Teacher Portal" message="Manage your classrooms and students" vid={videos.teacherPortal} /></td>
          {freeTier && <td><Cross /></td>}
          {individualTier && <td><Cross /></td>}
          {teacherTier && <td><Tick /></td>}
        </tr>)}
        {teacherTier && (<tr>
          <td>Classrooms <ShowVideo title="Classrooms" message="Create different classrooms to group your students" vid={videos.classrooms} /></td>
          {freeTier && <td><Cross /></td>}
          {individualTier && <td><Cross /></td>}
          {teacherTier && <td><Tick /></td>}
        </tr>)}
        {teacherTier && (<tr>
          <td>Classroom Leaderboards <ShowVideo title="Classroom Leaderboards" message="View your classroom leaderboards" vid={videos.classroomLeaderboards} /></td>
          {freeTier && <td><Cross /></td>}
          {individualTier && <td><Cross /></td>}
          {teacherTier && <td><Tick /></td>}
        </tr>)}
        {teacherTier && (<tr>
          <td>Student Leaderboard <ShowVideo title="Student Leaderboard" message="Students can see their own class stats" vid={videos.studentLeaderboard} /></td>
          {freeTier && <td><Cross /></td>}
          {individualTier && <td><Cross /></td>}
          {teacherTier && <td><Tick /></td>}
        </tr>)}
        {teacherTier && (<tr>
          <td>QR Code Login <ShowVideo title="QR Code Login" message="Simplify student login with a QR code or magic link" vid={videos.qrCode} /></td>
          {freeTier && <td><Cross /></td>}
          {individualTier && <td><Cross /></td>}
          {teacherTier && <td><Tick /></td>}
        </tr>)}
        {teacherTier && (<tr>
          <td>Student Logins <ShowVideo title="Student Logins" message="Add up to 300 students" vid={videos.students} /></td>
          {freeTier && <td><Cross /></td>}
          {individualTier && <td><Cross /></td>}
          {teacherTier && <td>30 - 300</td>}
        </tr>)}
        <tr className="pricing-table">
          <td>Price{period === Period.YEAR ? <div className="pricing__perMonth">(Paid Annually)</div> : null}</td>
          {freeTier && <td>{symbol}0.00<br /><div className="pricing__perMonth">FREE!</div></td>}
          {individualTier && (<td>
            {/* {period === Period.YEAR ? <div className="fiftyPercentOff">Save 50% with yearly</div> : null} */}
            <Pricing specialOffer={specialOffer} amount={individual_currency?.unit_amount} symbol={symbol} period={period} setPeriod={setPeriod} />
          </td>)}
          {teacherTier && (<td className="teacherPricing">
            <>
              {/* {period === Period.YEAR ? <div className="fiftyPercentOff">Save 50% with yearly</div> : null} */}
              <span>Student Logins</span>
              {/* <SolvemojiSelect
                title='Teacher'
                name='teacherPricing'
                autoComplete='off'
                onChange={(e: any) => setTeacherStudents(e.target.value)}
                value={teacherStudents}
              >
                {
                  studentNumbers?.map(x => (
                    <option value={x} selected={x === teacherStudents}>{x}</option>
                  ))
                }
              </SolvemojiSelect> */}
              <div className="radio-group btn-group btn-group-toggle" data-toggle="buttons" >
                <label htmlFor='30Students' >
                  <input type="radio" name="options" id="30Students" checked={teacherStudents === 30} value={30} onChange={onChangeValue} /> 30 logins (<Pricing specialOffer={specialOffer} amount={getTeacherPrice(teacher30)?.unit_amount} symbol={symbol} period={period} setPeriod={setPeriod} />)
                </label>
                <label htmlFor='60Students'>
                  <input type="radio" name="options" id="60Students" checked={teacherStudents === 60} value={60} onChange={onChangeValue} /> 60 logins (<Pricing specialOffer={specialOffer} amount={getTeacherPrice(teacher60)?.unit_amount} symbol={symbol} period={period} setPeriod={setPeriod} />)
                </label>
                <label htmlFor='90Students'>
                  <input type="radio" name="options" id="90Students" checked={teacherStudents === 90} value={90} onChange={onChangeValue} /> 90 logins (<Pricing specialOffer={specialOffer} amount={getTeacherPrice(teacher90)?.unit_amount} symbol={symbol} period={period} setPeriod={setPeriod} />)
                </label>
                <label htmlFor='300Students'>
                  <input type="radio" name="options" id="300Students" checked={teacherStudents === 300} value={300} onChange={onChangeValue} /> 300 logins (<Pricing specialOffer={specialOffer} amount={getTeacherPrice(teacher300)?.unit_amount} symbol={symbol} period={period} setPeriod={setPeriod} />)
                </label>
              </div>
            </>
          </td>)}
        </tr>
        <tr>
          <td></td>
          {freeTier && (<td>
            {(!orders || orders.length === 0) && loggedIn && (
              <span className='order-active'>Active - Free Play</span>
            )}
            {
              (!orders || orders.length === 0) && !activeOrders && setShowLoginWarning && <button className='btn btn-primary' onClick={() => setShowLoginWarning(true)}>Login/Register Here</button>
            }
          </td>)}
          {individualTier && (<td>
            {hasIndividual && (
              <>
                {hasIndividual.Active && !hasIndividual.CancelAtPeriodEnd && <span className='order-active'>Active - Individual</span>}
                {hasIndividual.Active && hasIndividual.CancelAtPeriodEnd && <span className='order-cancelled'>Cancelled - Individual</span>}
                {!hasIndividual.Active && <span className='order-inactive'>Inactive - Individual</span>}
              </>
            )}
            {!hasIndividual && !hasTeacher && !activeOrders && (
              <Subscribe specialOffer={specialOffer} amount={individual_currency?.unit_amount} symbol={symbolVerbose} onClick={doIndividual} setPeriod={setPeriod} period={period} />
            )}
          </td>)}
          {teacherTier && (<td>
            {hasTeacher && (
              <>
                {hasTeacher.Active && !hasTeacher.CancelAtPeriodEnd && <span className='order-active'>Active - Teacher</span>}
                {hasTeacher.Active && hasTeacher.CancelAtPeriodEnd && <span className='order-cancelled'>Cancelled - Teacher</span>}
                {!hasTeacher.Active && <span className='order-inactive'>Inactive - Teachers</span>}
              </>
            )}
            {!hasTeacher && !activeOrders && (
              <>
                <Subscribe specialOffer={specialOffer} amount={teacherSelectedPrice_currency?.unit_amount} symbol={symbolVerbose} setPeriod={setPeriod} onClick={() => doTeacher(teacherSelectedPrice)} period={period} />
                <br />
                <div className="purchaseOrder">(Purchase orders also available upon request)</div>
              </>
            )}
          </td>)}
        </tr>
      </tbody>
    </table>
    )
  }, [activeOrders, doIndividual, doTeacher, getTeacherPrice, hasIndividual, hasTeacher, individual_currency, loggedIn, orders, period, setShowLoginWarning, specialOffer, symbol, symbolVerbose, teacher30, teacher300, teacher60, teacher90, teacherSelectedPrice, teacherSelectedPrice_currency, teacherStudents])

  return (
    <div>
      {/* <div id="currencyButtons" className="btn-group__pricing" role="group">
        <button type="button" onClick={() => doSetPeriod(Period.YEAR)} className={`btn btn-secondary ${period === 'year' ? '--active' : ''}`}>Pay Yearly</button>
        <button type="button" onClick={() => doSetPeriod(Period.MONTH)} className={`btn btn-secondary ${period === 'month' ? '--active' : ''}`}>Pay Monthly</button>
      </div> */}
      {
        showCurrecies ? <div id="currencyButtons" className="btn-group__pricing" role="group">
          <button type="button" onClick={() => setCountry('usd')} className={`btn spaced btn-secondary ${country === 'usd' ? '--active' : ''}`}><img src={us} alt='usd' /> USD $</button>
          <button type="button" onClick={() => setCountry('gbp')} className={`btn spaced btn-secondary ${country === 'gbp' ? '--active' : ''}`}><img src={uk} alt='gbp' /> GBP £</button>
          <button type="button" onClick={() => setCountry('nzd')} className={`btn spaced btn-secondary ${country === 'nzd' ? '--active' : ''}`}><img src={nz} alt='nzd' /> NZD $</button>
          <button type="button" onClick={() => setCountry('cad')} className={`btn spaced btn-secondary ${country === 'cad' ? '--active' : ''}`}><img src={ca} alt='cad' /> CAD $</button>
          <button type="button" onClick={() => setCountry('aud')} className={`btn spaced btn-secondary ${country === 'aud' ? '--active' : ''}`}><img src={au} alt='aud' /> AUD $</button>
          <button type="button" onClick={() => setCountry('eur')} className={`btn spaced btn-secondary ${country === 'eur' ? '--active' : ''}`}><img src={eu} alt='eur' /> EUR €</button>
        </div> : canChangeCurrency !== false ? <div className='mb-20 white'>Prices shown in {<Highlight top={1} text={symbolVerbose} />} to change <span onClick={() => setShowCurrencies(true)}><Highlight top={1} text='click here' /></span></div> : null
      }

      {
        size.width > 900 && (
          <PricingTable freeTier={true} individualTier={true} teacherTier={true} setPeriod={doSetPeriod} />
        )
      }
      {
        size.width <= 900 && (
          <>
            <PricingTable freeTier={true} individualTier={false} teacherTier={false} setPeriod={doSetPeriod} />
            <br />
            <PricingTable freeTier={false} individualTier={true} teacherTier={false} setPeriod={doSetPeriod} />
            <br />
            <PricingTable freeTier={false} individualTier={false} teacherTier={true} setPeriod={doSetPeriod} />
          </>
        )
      }

      <p className="mt-10 white">* subscriptions will auto renew after the subscription period unless cancelled</p>

      <VideoPreview
        message={videoMessage}
        setShow={() => setShowVideo(false)}
        show={showVideo}
        title={videoTitle}
        video={video}
      />
    </div >
  )
}