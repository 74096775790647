import * as React from 'react';
import { StickyShareButtons } from 'sharethis-reactjs';

export default function ShareThisSticky(WrappedComponent: any) {
  return class extends React.Component {
    state = {
      href: window.location.href,
      loading: true,
    };

    componentDidMount() {
      // @ts-ignore
      if (window.__sharethis__) {
        // @ts-ignore
        window.__sharethis__.href = window.location.href;
        this.setState({ loading: false, href: window.location.href });
      } else {
        this.setState({ loading: false });
      }
    }

    componentDidUpdate() {
      if (this.state.href !== window.location.href) {
        this.setState({ loading: true }, () => {
          // @ts-ignore
          if (window.__sharethis__) {
            // @ts-ignore
            window.__sharethis__.href = window.location.href;
            this.setState({ loading: false, href: window.location.href });
          } else {
            this.setState({ loading: false });
          }
        });
      }
    }

    public render() {
      return (
        <>
          <WrappedComponent {...this.props} />
          {!this.state.loading && (
            <StickyShareButtons
              config={{
                alignment: 'left', // alignment of buttons (left, right)
                color: 'social', // set the color of buttons (social, white)
                enabled: true, // show/hide buttons (true, false)
                font_size: 16, // font size for the buttons
                // @ts-ignore
                hide_desktop: false, // hide buttons on desktop (true, false)
                labels: 'counts', // button labels (cta, counts, null)
                language: 'en', // which language to use (see LANGUAGES)
                min_count: 0, // hide react counts less than min_count (INTEGER)
                networks: [
                  // which networks to include (see SHARING NETWORKS)
                  'facebook',
                  'twitter',
                  'pinterest',
                  'messenger',
                  'email',
                  'sharethis',
                ],
                padding: 12, // padding within buttons (INTEGER)
                radius: 4, // the corner radius on each button (INTEGER)
                show_total: true, // show/hide the total share count (true, false)
                show_mobile: true, // show/hide the buttons on mobile (true, false)
                show_toggle: true, // show/hide the toggle buttons (true, false)
                size: 48, // the size of each button (INTEGER)
                top: 160, // offset in pixels from the top of the page
              }}
            />
          )}
        </>
      );
    }
  };
}
