import * as React from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import ColouredTitle from '../misc/ColouredTitle'
// @ts-ignore
var ReactFitText = require('react-fittext')

interface FilterProps {
  logo: string
  size: number
  margin: string
  labels: string[]
  colours: string[]
  selected?: number
  disabled?: boolean
  easy?: boolean
  light?: boolean
  noPadding?: boolean
  onClick: (position: number) => void
}

const Filter = (props: FilterProps) => {
  const {
    logo,
    onClick,
    size,
    labels,
    margin,
    colours,
    selected,
    disabled,
    easy,
    light,
    noPadding,
  } = props

  useEffect(() => {
    window.setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 500)
  }, [])

  return (
    <>
      <ReactFitText compressor={4.8}>
        <div className={`mt-20 filter__text${light ? '-light' : ''}`}>
          <div className='icon-angle-double-down filter__arrow'></div>
          <ColouredTitle title={easy ? 'Easier - Age 4+' : 'Harder - Age 7+'} />
          <div className='icon-angle-double-down filter__arrow'></div>
        </div>
      </ReactFitText>
      <div className={`filter__containter${noPadding ? '-nopadding' : ''}`}>
        <div className={disabled ? 'filter-disabled' : ''}>
          <div style={{
            background: `url(${logo})`,
            paddingTop: 'calc(365/548 * 100%)'
          }} className='filter__icon' />
          <div className='filter__buttons'>
            <div
              onClick={() => onClick(1)}
              className={selected === 0 ? 'selected' : ''}
              style={{ background: colours[0] }}
            >
              <ReactFitText compressor={size}>
                <div style={{ marginTop: margin }}>{labels[0]}</div>
              </ReactFitText>
            </div>
            <div
              onClick={() => onClick(2)}
              className={selected === 1 ? 'selected' : ''}
              style={{ background: colours[1] }}
            >
              <ReactFitText compressor={size}>
                <div style={{ marginTop: margin }}>{labels[1]}</div>
              </ReactFitText>
            </div>
            <div
              onClick={() => onClick(3)}
              className={selected === 2 ? 'selected' : ''}
              style={{ background: colours[2] }}
            >
              <ReactFitText compressor={size}>
                <div style={{ marginTop: margin }}>{labels[2]}</div>
              </ReactFitText>
            </div>
            <div
              onClick={() => onClick(4)}
              className={selected === 3 ? 'selected' : ''}
              style={{ background: colours[3] }}
            >
              <ReactFitText compressor={size}>
                <div style={{ marginTop: margin }}>{labels[3]}</div>
              </ReactFitText>
            </div>
            <div
              onClick={() => onClick(5)}
              className={selected === 4 ? 'selected' : ''}
              style={{ background: colours[4] }}
            >
              <ReactFitText compressor={size}>
                <div style={{ marginTop: margin }}>{labels[4]}</div>
              </ReactFitText>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default connect()(Filter)
