import classroom, { ClassroomState } from './classroomSlice'
import * as Puzzle from './Puzzle'
import * as Account from './Account'
import game, { GameState } from './gameSlice'
import puzzle, { PuzzleState } from './puzzleSlice'
import { combineReducers } from 'redux'
import { enhancedSolvemojiApi } from './enhancedSolvemojiApi'

export interface ApplicationState {
  puzzleStore: Puzzle.PuzzleState | undefined
  accountStore: Account.AccountState | undefined
  game: GameState,
  puzzle: PuzzleState
  classroom: ClassroomState
}

export const reducers = {
  puzzleStore: Puzzle.reducer,
  accountStore: Account.reducer,
  game,
  puzzle,
  classroom,
  [enhancedSolvemojiApi.reducerPath]: enhancedSolvemojiApi.reducer
}

export const rootReducer = combineReducers({
  puzzleStore: Puzzle.reducer,
  accountStore: Account.reducer,
  game,
  puzzle,
  classroom,
  [enhancedSolvemojiApi.reducerPath]: enhancedSolvemojiApi.reducer
})

export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void
}
