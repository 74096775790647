import * as React from 'react'
import { FaSyncAlt } from 'react-icons/fa'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { generateRandomName } from '../../nameGenerator'
import { ApplicationState } from '../../store'
import * as AccountStore from '../../store/Account'
import ColouredTitle from '../misc/ColouredTitle'
import SolvemojiForm from '../misc/solvemojiForm'
import SolvemojiInput from '../misc/solvemojiInput'
// @ts-ignore
var ReactFitText = require('react-fittext')

type PuzzleProps = AccountStore.AccountState &
  typeof AccountStore.actionCreators &
  RouteComponentProps<{}>

class ChangeNickname extends React.PureComponent<PuzzleProps> {
  state = {
    nickname: '',
    firstTime: false,
  };

  componentDidMount() {
    const { user_details } = this.props

    if (user_details) {
      if (user_details.Nickname) {
        this.setState({ nickname: user_details.Nickname })
      } else {
        this.setState({ firstTime: true })
      }
    }
  }

  handleYes = async () => {
    const { setNickname, doShowNickname } = this.props

    await setNickname(this.state.nickname)
    doShowNickname(false)
  };

  handleOnChange = (e: any) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value })
  };


  refreshNickname = () => {
    this.setState({ nickname: generateRandomName() })
  }


  render() {
    const { handleYes, handleOnChange, state } = this
    const { nickname, firstTime } = state
    const { user_details } = this.props

    if (!user_details) {
      return null
    }

    return (
      <div className='puzzleComplete'>
        <div className='row'>
          <div className='col-12 col-sm-1 col-md-2 col-lg-3'></div>
          <div className='col-12 col-sm-10 col-md-8 col-lg-6'>
            <div className='message mw-310'>
              <div className='row'>
                <div className='col col-12 text-center'>
                  <ReactFitText compressor={0.7}>
                    <ColouredTitle title='NICKNAME' />
                  </ReactFitText>

                  <SolvemojiForm submit={handleYes}>
                    <div className='puzzleComplete__text mt-10 mb-10'>
                      {!firstTime && 'Change your nickname'}
                      {firstTime && 'Choose a nickname'}
                    </div>
                    {firstTime && (
                      <div className='puzzleComplete__text-smaller mt-5 mb-10'>
                        (This will be shown on the
                        <br />
                        Solvemoji leaderboards)
                      </div>
                    )}

                    <div className='row'>
                      <div className='col-12'>
                        <SolvemojiInput
                          title='Nickname'
                          name='nickname'
                          autoComplete='off'
                          maxLength={20}
                          minLength={5}
                          type='text'
                          placeholder='Nickname...'
                          onChange={handleOnChange}
                          required={true}
                          autoFocus={true}
                          value={nickname}
                        />
                      </div>
                    </div>
                    <div className="generate-new-nickname">Generate random nickname <FaSyncAlt onClick={this.refreshNickname} /></div>
                    <div className='row mt-20'>
                      <div className='col'>
                        <button
                          className='btn btn-lg puzzleComplete__button-yes'
                          type='submit'
                        >
                          <i className='icon-check mr-10'></i>
                          <span>Update Nickname</span>
                        </button>
                      </div>
                    </div>
                  </SolvemojiForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state: ApplicationState) => state.accountStore, // Selects which state properties are merged into the component's props
  AccountStore.actionCreators // Selects which action creators are merged into the component's props
)(ChangeNickname as any)
