import { useEffect, useState } from 'react'
import { useActiveProduct } from '../../hooks/useActiveProduct'

declare global {
  interface Window {
    adsbygoogle: any
  }
}

export const useGoogleAdsWatcher = () => {
  const activeProduct = useActiveProduct()
  const [scriptLoaded, setScriptLoaded] = useState(false)

  useEffect(() => {
    const styleId = 'adsbygoogle-style'
    const activePro = localStorage.getItem('activeProduct')

    if (activeProduct) {
      if (!activePro) {
        // Add the style tag
        const style = document.createElement('style')
        style.id = styleId
        style.innerHTML = `
            .adsbygoogle {
              display: none !important;
            }
          `
        document.head.appendChild(style)
        window.adsbygoogle = []
        localStorage.setItem('activeProduct', activeProduct.name?.toString() || "active")
        window.setTimeout(() => {
          if (window.location.href.indexOf('magic') > -1) {
            window.location.href = "/";
          } else {
            window.location.reload() // refresh the browser
          }
        }, 1000)
      }
    } else {
      // Remove the style tag if it exists
      const existingStyle = document.getElementById(styleId)
      if (existingStyle) {
        existingStyle.remove()
      }
    }
  }, [activeProduct])

  useEffect(() => {
    if (activeProduct === undefined && !scriptLoaded) {
      // Load the ads script
      const script = document.createElement('script')
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
      script.async = true
      script.dataset.adClient = 'ca-pub-1381385725306576'
      script.onload = () => {
        setScriptLoaded(true)
      }
      document.body.appendChild(script)
    }
  }, [activeProduct, scriptLoaded])
}