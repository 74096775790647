import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationState } from './index';

export interface ClassroomState {
  classroomId: number;
}

const initialState: ClassroomState = {
  classroomId: 0
};

export const classroomSlice = createSlice({
  name: 'classroom',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setClassroomId: (state, action: PayloadAction<number>) => {
      state.classroomId = action.payload;
    }
  }
});

export const {
  setClassroomId
} = classroomSlice.actions;

export const selectorClassroomId = (state: ApplicationState) =>
  state.classroom.classroomId;

export default classroomSlice.reducer;
