import * as React from 'react';
import logo from '../../assets/solvemoji-pos-logo.svg';
import browserSupported from '../../assets/browserSupport.png';
import '../../styles/styles.scss';
import '../../styles/layout.scss';

const UnsupportedBrowser = (props: any) => {
  return (
    <div className='row unsupportedBrowser'>
      <div className='col'>
        <div className='card'>
          <div className='card-body text-center'>
            <div className='gdsLogo'>
              <img width='300' alt='Solvemoji' src={logo} />
              <h2 className='mt-10 gdsLogo__title'>Unsupported Browser</h2>
              <p className='text-muted gdsLogo__subtitle'>
                Oops, it looks like you are using an old browser.
                <br />
                Solvemoji requires a modern browser.
              </p>
            </div>
            <div className='text-center'>
              <div className='mb-10'>
                Please use the latest version
                <br />
                of one of the following browsers
              </div>
              <img
                width='320'
                alt='Supported Browsers'
                src={browserSupported}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnsupportedBrowser;
