import React, { Suspense, lazy, useEffect } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import './styles/misc/solvemojiLoading.scss'
import Layout from './components/Layout'
import LoadingSpinner from './components/misc/LoadingSpinner'
import history from './history'
import GoogleAnalytics from './GoogleAnalytics'
import { useUnhandledExceptions } from './helpers'
import Home from './components/Home'
import { useGoogleAdsWatcher } from './components/ads/useGoogleAdsWatcher'
import ReactGA from "react-ga4";

const Contact = lazy(() => import('./components/Contact'))
const PuzzlesContainer = lazy(() => import('./containers/PuzzlesContainer'))
const MagicContainer = lazy(() => import('./containers/MagicContainer'))
const DailyPuzzles = lazy(() => import('./components/DailyPuzzles'))

const HelpContainer = lazy(() => import('./containers/HelpContainer'))
const LeaderboardsContainer = lazy(() => import('./containers/LeaderboardsContainer'))
const PuzzleQuestion = lazy(() => import('./components/PuzzleQuestion'))
const PuzzleSolution = lazy(() => import('./components/PuzzleSolution'))
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'))
const ChangePassword = lazy(() => import('./containers/ChangePasswordContainer'))
const BooksContainer = lazy(() => import('./containers/BooksContainer'))

// const GamePage  = lazy(() => import('./components/GamePage'));
const Shop = lazy(() => import('./components/Shop'))
const TermsAndConditions = lazy(
  () => import('./components/TermsAndConditions')
)
const ForgottenPassword = lazy(() => import('./components/ForgottenPassword'))
const ForgottenPasswordConfirmation = lazy(
  () => import('./components/ForgottenPasswordConfirmation')
)
const ForgottenPasswordCompleted = lazy(
  () => import('./components/ForgottenPasswordCompleted')
)
const ResetPassword = lazy(() => import('./components/ResetPassword'))
const Register = lazy(() => import('./components/Register'))
const Login = lazy(() => import('./components/Login'))
const ExternalLogin = lazy(() => import('./components/ExternalLogin'))
const Footer = lazy(() => import('./components/layout/Footer'))
const PricingContainer = lazy(() => import('./containers/PricingContainer'))
const CheckoutContainer = lazy(() => import('./containers/CheckoutContainer'))
const MyAccountContainer = lazy(() => import('./containers/MyAccountContainer'))
const ManageUsersContainer = lazy(() => import('./containers/ManageUsersContainer'))
const PageNotFoundContainer = lazy(() => import('./containers/PageNotFoundContainer'))
const BlogsContainer = lazy(() => import('./containers/BlogsContainer'))
const BlogContainer = lazy(() => import('./containers/BlogContainer'))
const BlogContainer2 = lazy(() => import('./containers/BlogContainer2'))
const BlogContainer3 = lazy(() => import('./containers/BlogContainer3'))
const Xmas = lazy(() => import('./containers/Xmas'))
// const ColourBlocksContainer = lazy(() => import('./containers/WordBlocks'))

export default () => {
  useUnhandledExceptions()
  useGoogleAdsWatcher()

  useEffect(() => {
    window.setTimeout(() => {
      ReactGA.initialize('G-WSNFC6H0ZB');
    }, 1000)
  }, [])

  return (
    <Router history={history}>
      <Layout>
        <Suspense fallback={<LoadingSpinner />}>
          <Switch>
            {/* <Route exact path="/sudoku" component={Sudoku} /> */}
            <Route exact path='/' component={Home} />
            <Route exact path='/blogs' component={BlogsContainer} />
            <Route exact path='/blog/1' component={BlogContainer} />
            <Route exact path='/blog/2' component={BlogContainer2} />
            <Route exact path='/worksheets' component={BlogContainer3} />
            <Route path='/contact' component={Contact} />
            <Route path='/books' component={BooksContainer} />
            <Route path='/new' component={PuzzlesContainer} />
            <Route path='/today' component={DailyPuzzles} />
            <Route path='/popular' component={PuzzlesContainer} />
            <Route path='/magic' component={MagicContainer} />
            <Route exact path='/puzzle/new' component={PuzzlesContainer} />
            <Route exact path='/puzzle/mostpopular' component={PuzzlesContainer} />
            <Route exact path='/puzzle/today' component={DailyPuzzles} />
            <Route exact path='/puzzle' component={PuzzlesContainer} />
            <Route exact path='/puzzle/index' component={PuzzlesContainer} />
            <Route path='/howTo' component={HelpContainer} />
            <Route path='/howToEmojidoku' component={HelpContainer} />
            <Route path='/howToSpotTheOddEmoji' component={HelpContainer} />
            <Route path='/howToWord' component={HelpContainer} />
            <Route path='/howToVideo' component={HelpContainer} />

            <Route path='/help/teacherPortal' component={HelpContainer} />
            <Route path='/help/classrooms' component={HelpContainer} />
            <Route path='/help/classroomLeaderboards' component={HelpContainer} />
            <Route path='/help/studentLeaderboard' component={HelpContainer} />
            <Route path='/help/qrCodeLogin' component={HelpContainer} />
            <Route path='/help/studentLogins' component={HelpContainer} />

            {/* <Route path='/colour' component={ColourBlocksContainer} />
             */}

            <Route exact path='/home/howToPlay' component={HelpContainer} />
            <Route exact path='/home/leaderboard/1' component={LeaderboardsContainer} />
            <Route path='/leaderboard' component={LeaderboardsContainer} />
            <Route path='/leaderboardWeekly' component={LeaderboardsContainer} />
            <Route path='/leaderboardDaily' component={LeaderboardsContainer} />
            <Route path='/leaderboardRanks' component={LeaderboardsContainer} />
            <Route path='/leaderboardClassroom' component={LeaderboardsContainer} />
            <Route path='/leaderboardWeeklyClassroom' component={LeaderboardsContainer} />
            <Route path='/leaderboardDailyClassroom' component={LeaderboardsContainer} />
            <Route path='/register' component={Register} />
            <Route exact path='/account/register' component={Register} />
            <Route path='/login' component={Login} />
            <Route exact path='/account/login' component={Login} />
            <Route path='/swag' component={Shop} />
            <Route path='/shop' component={Shop} />
            <Route path='/store' component={Shop} />
            <Route path='/christmas-worksheets' component={Xmas} />
            <Route path='/pricing' component={PricingContainer} />
            <Route path='/myAccount' component={MyAccountContainer} />
            <Route path='/manageStudents' component={ManageUsersContainer} />
            <Route path='/settings' component={LeaderboardsContainer} />
            <Route path='/checkout/:id' component={CheckoutContainer} />
            <Route path='/externalLogin' component={ExternalLogin} />
            <Route exact path='/home/privacypolicy' component={PrivacyPolicy} />
            <Route exact path='/privacypolicy' component={PrivacyPolicy} />
            <Route
              exact
              path='/home/termsandconditions'
              component={TermsAndConditions}
            />
            <Route
              exact
              path='/termsandconditions'
              component={TermsAndConditions}
            />
            <Route exact path='/changepassword' component={ChangePassword} />
            <Route
              exact
              path='/forgottenpassword'
              component={ForgottenPassword}
            />
            <Route
              exact
              path='/forgottenpasswordconfirmation'
              component={ForgottenPasswordConfirmation}
            />
            <Route
              exact
              path='/forgottenpasswordcomplete'
              component={ForgottenPasswordCompleted}
            />

            <Route exact path='/resetpassword' component={ResetPassword} />
            <Route
              exact
              path='/Account/ResetPassword'
              component={ResetPassword}
            />
            <Route path='/puzzle/puzzle/:id' component={PuzzleQuestion} />
            <Route path='/puzzle/:id' component={PuzzleQuestion} />

            <Route path='/puzzleSolution/:id' component={PuzzleSolution} />
            {/* <Route path='/daily' component={GamePage} /> */}
            <Route path="*" component={PageNotFoundContainer} />
          </Switch>
          <Footer />
        </Suspense>
      </Layout>
      <GoogleAnalytics />
    </Router>
  )
}

