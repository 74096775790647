import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApplicationState } from './index'
import { PuzzleOptions } from '../interfaces/IPuzzleOptions'
import { Level, PuzzleType } from '../constants'

export interface PuzzleState {
  newPuzzleOptions: PuzzleOptions
  popularPuzzleOptions: PuzzleOptions
  guessedPuzzles: number
}

const initialState: PuzzleState = {
  newPuzzleOptions: {
    puzzleTypeId: PuzzleType.Mixed,
    levelId: Level.Unset
  },
  popularPuzzleOptions: {
    puzzleTypeId: PuzzleType.Mixed,
    levelId: Level.Unset
  },
  guessedPuzzles: 0
}

export const puzzleSlice = createSlice({
  name: 'puzzle',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setNewOptions: (state, action: PayloadAction<PuzzleOptions>) => {
      if (
        action.payload !== undefined &&
        action.payload.levelId !== undefined &&
        action.payload.puzzleTypeId !== undefined &&
        action.payload.levelId > 5 &&
        (action.payload.puzzleTypeId === PuzzleType.Word ||
          action.payload.puzzleTypeId === PuzzleType.Grid)
      ) {
        action.payload.levelId = Level.Unset
      }

      state.newPuzzleOptions = action.payload
    },
    setPopularOptions: (state, action: PayloadAction<PuzzleOptions>) => {
      if (
        action.payload !== undefined &&
        action.payload.levelId !== undefined &&
        action.payload.puzzleTypeId !== undefined &&
        action.payload.levelId > 5 &&
        (action.payload.puzzleTypeId === PuzzleType.Word ||
          action.payload.puzzleTypeId === PuzzleType.Grid)
      ) {
        action.payload.levelId = Level.Unset
      }

      state.popularPuzzleOptions = action.payload
    },
    addGuess: (state) => {
      state.guessedPuzzles += 1
    }
  }
})

export const {
  setNewOptions,
  setPopularOptions,
  addGuess
} = puzzleSlice.actions

export const selectorNewPuzzleOptions = (state: ApplicationState) =>
  state.puzzle.newPuzzleOptions
export const selectorPopularPuzzleOptions = (state: ApplicationState) =>
  state.puzzle.popularPuzzleOptions
export const selectorGuessedPuzzles = (state: ApplicationState) =>
  state.puzzle.guessedPuzzles

export default puzzleSlice.reducer
