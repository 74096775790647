import * as React from 'react'
import ColouredTitle from '../misc/ColouredTitle'
import { Portal } from 'react-portal'

// @ts-ignore
var ReactFitText = require('react-fittext')

interface IVideoPreview {
  message: string
  title: string
  show: boolean
  setShow: () => void
  video: string
}

export default function VideoPreview(props: IVideoPreview) {
  const { show, setShow, title, message, video } = props
  return (
    <>
      {
        show && (
          <Portal>
            <div className='puzzleComplete' >
              <div className='row'>
                <div className='col-12 col-sm-1 col-md-2 col-lg-3'></div>
                <div className='col-12 col-sm-10 col-md-8 col-lg-6'>
                  <div className='message'>
                    <div className='modal__close'>
                      <i className='icon-times' onClick={setShow}></i>
                    </div>
                    <div className='row'>
                      <div className='col col-12 text-center'>
                        <ReactFitText compressor={1.1}>
                          <ColouredTitle title={title} />
                        </ReactFitText>

                        <>
                          <div className='puzzleComplete__text'>
                            {
                              message
                            }
                            <div className='videoWrapper'>
                              <video autoPlay={true} loop={false} muted={false} controls={true}> 
                                <source type="video/mp4" src={`https://solvemoji.s3.eu-west-2.amazonaws.com/videos/${video}`} />
                              </video>
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-sm-1 col-md-2 col-lg-3'></div>
              </div>
            </div>
          </Portal>
        )
      }
    </>

  )
}