import * as React from 'react';

const SolvemojiInput = React.memo((props: any) => (
  <div>
    <input {...props}  className={`pa__input form-control ${props.small ? '--small' : ''}`} autoComplete='off' />
    <div className='invalid-feedback' />
  </div>
));

export default SolvemojiInput;
