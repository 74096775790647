import React, { lazy, Suspense } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'

import { shuffle, scrollTo } from '../helpers'
import { IPuzzle } from '../interfaces/IPuzzle'
import { ApplicationState } from '../store'
import * as PuzzleStore from '../store/Puzzle'
import * as AccountStore from '../store/Account'
import ShareThisSticky from './misc/ShareThisSticky'
import LoadingSpinner from './misc/LoadingSpinner'
import Intro from './home/Intro'
import Worksheets from './home/Worksheets'
import { PricingContainer } from '../containers/PricingContainer'
import { BooksContainer } from '../containers/BooksContainer'

const PageStats = lazy(() => import('./home/PageStats'))
const TryOne = lazy(() => import('./home/TryOne'))

const Top10s = lazy(() => import('./home/Top10s'))
const TodaysPuzzles = lazy(() => import('./home/TodaysPuzzles'))

type PuzzleProps = { puzzleStore: PuzzleStore.PuzzleState } & {
  accountStore: AccountStore.AccountState
} & typeof PuzzleStore.actionCreators &
  RouteComponentProps<{}>

class Home extends React.PureComponent<PuzzleProps> {
  constructor(props: any) {
    super(props)
    // @ts-ignore
    window.scrollTo(0, 0)

  }

  state = {
    juniorPuzzle: undefined,
    nextLevelPuzzle: undefined
  };

  public async componentDidMount() {
    if (this.props.puzzleStore.todaysPuzzles.length === 0) {
      this.props.getTodaysPuzzles()
      this.props.getTop10Leaderboards()
    } else if (
      !this.state.juniorPuzzle &&
      this.props.puzzleStore.todaysPuzzles.length > 0
    ) {
      this.setPuzzlesState()
    }
  }

  public componentDidUpdate(props: any) {
    if (!this.state.juniorPuzzle && this.props.puzzleStore.todaysPuzzles.length > 0) {
      this.setPuzzlesState()
    }
  }

  setPuzzlesState = () => {
    const juniorPuzzles = this.props.puzzleStore.todaysPuzzles.filter(
      (x: IPuzzle) => x.LevelId > 5
    )
    const nextLevelPuzzles = this.props.puzzleStore.todaysPuzzles.filter(
      (x: IPuzzle) => x.LevelId <= 2
    )
    shuffle(juniorPuzzles)
    shuffle(nextLevelPuzzles)
    this.setState({
      juniorPuzzle: juniorPuzzles[0],
      nextLevelPuzzle: nextLevelPuzzles[0]
    })
  };

  goToLevel = (position: number, isJunior: boolean) => {
    if (!isJunior) {
      const nextLevelPuzzles = this.props.puzzleStore.todaysPuzzles.filter(
        (x: IPuzzle) => x.LevelId === position
      )
      shuffle(nextLevelPuzzles)
      this.setState({
        nextLevelPuzzle: nextLevelPuzzles[0]
      })

      scrollTo('#nextLevelPuzzle', 600, 30)
    } else {
      const juniorPuzzle = this.props.puzzleStore.todaysPuzzles.find(
        (x: IPuzzle) => x.LevelId === position + 5
      )

      this.setState({
        juniorPuzzle
      })

      scrollTo('#juniorPuzzle', 600, 30)
    }
  };

  public render() {
    const { juniorPuzzle, nextLevelPuzzle } = this.state

    const junior = this.props.puzzleStore.puzzles.find(
      // @ts-ignore
      (x: any) => x.PuzzleId === juniorPuzzle?.PuzzleId
    )
    const nextLevel = this.props.puzzleStore.puzzles.find(
      // @ts-ignore
      (x: any) => x.PuzzleId === nextLevelPuzzle?.PuzzleId
    )

    return (
      <>
        <Helmet>
          <title>Solvemoji</title>
        </Helmet>
        <Intro
          juniorPuzzle={juniorPuzzle}
          nextLevelPuzzle={nextLevelPuzzle}
          goToLevel={this.goToLevel}
        />
        <Suspense fallback={<LoadingSpinner />}>
          <TryOne
            juniorPuzzle={junior || juniorPuzzle}
            nextLevelPuzzle={nextLevel || nextLevelPuzzle}
            uploadPuzzleGuess={this.props.uploadPuzzleGuess}
            removePuzzle={this.props.removePuzzle}
          />
        </Suspense>
        <Suspense fallback={<LoadingSpinner />}>
          <PricingContainer home={true} hideWithSub={true} />
        </Suspense>
        <Worksheets />
        <BooksContainer home={true} />
        <Suspense fallback={<LoadingSpinner />}>
          <PageStats stats={this.props.accountStore.stats} />
        </Suspense>

        <Suspense fallback={<LoadingSpinner />}>
          <Top10s />
        </Suspense>
        <Suspense fallback={<LoadingSpinner />}>
          {
            this.props.puzzleStore.todaysPuzzles.length ? (
              <TodaysPuzzles
                puzzles={this.props.puzzleStore.todaysPuzzles}
              />
            ) : null
          }
        </Suspense>
      </>
    )
  }
}

export default connect(
  (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
  PuzzleStore.actionCreators // Selects which action creators are merged into the component's props
)(ShareThisSticky(Home) as any)
