const first = ['Rock', 'Wizard', 'Pirate', 'Captain', 'Doctor', 'Sheep', 'Super', 'Dog', 'Kitten', 'Cat', 'Froggy', 'Piggy', 'Rabbit', 'Wolfy', 'Fox', 'Red', 'Genie', 'Lovely', 'Yellow', 'Green', 'Blue', 'Purple', 'Pink', 'Sparkle', 'Sporty', 'Bitty', 'Bore', 'Bug', 'Bum', 'Ding', 'Fuzz', 'Goo', 'Gum', 'Hick', 'Pea', 'Shoo', 'Sniff', 'Stink', 'Worm', 'Zoo', 'Fig', 'Chew', 'Beanie', 'Mad', 'Egg', 'Wee', 'Lick', 'Buzz', 'Fluff', 'Flap']
const second = ['star', 'bop', 'cop', 'pop', 'buns', 'wizz', '-a-boo', 'bob', 'berry', 'eenie', 'spitz', 'wee', 'bag', 'ster', 'moo', 'aloo', 'pants', 'noo', 'bee', 'spin', 'ington', 'blip']
const third = ['Dog', 'Kitten', 'Cat', 'Footy', 'Froggy', 'Piggy', 'Rabbit', 'Super', 'Wolfy', 'Fox', 'Red', 'Yellow', 'Green', 'Blue', 'Purple', 'Pink', 'Sparkle', 'Beanie', 'Bobo', 'Booger', 'Doozy', 'Fudge', 'Hippy', 'Oink', 'Pimple', 'Sock', 'Smudge', 'Toot', 'Pie', 'Wiggle', 'Giggle', 'Spotty', 'Chew', 'Woofy', 'Noodle', 'Doodoo', 'Droopy', 'Sweetie', 'Groovy', 'Happy', 'Cheeky', 'Silly', 'Chuckle', 'Smiley']
const forth = ['ball', 'bean', 'bag', 'born', 'bottom', 'face', 'fish', 'gold', 'silver', 'pom', 'wizz', 'hair', 'hall', 'ham', 'hill', 'man', 'shine', 'son', 'whistle', 'worthy', 'rider', 'seed', 'worth', 'ton', 'brain', 'eye', 'feet']

const random = () => 0.5 - Math.random()

export const generateRandomName = (): string => {
  let newNickname = doGenerateNewNickname()

  while (newNickname.length > 20) {
    newNickname = doGenerateNewNickname()
  }

  return newNickname
}

export const doGenerateNewNickname = () => {
  const firstRandom = first.sort(random).sort(random)[Math.floor(Math.random() * first.length)]
  const secondRandom = second.sort(random).sort(random)[Math.floor(Math.random() * second.length)]
  const thirdRandom = third.sort(random).sort(random)[Math.floor(Math.random() * third.length)]
  const forthRandom = forth.sort(random).sort(random)[Math.floor(Math.random() * forth.length)]

  return `${firstRandom}${secondRandom} ${thirdRandom}${forthRandom}`
}