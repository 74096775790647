import { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { enhancedSolvemojiApi as api } from '../../store/enhancedSolvemojiApi';
import { usePdfGetWorksheetUrlQuery } from '../../store/generated/pdfApi'

export const useDowloadWorksheet = () => {
  const [puzzleId, setPuzzleId] = useState<number>();
  const [getPdf, setGetPdf] = useState(false)
  const [hasRefetched, setHasRefetched] = useState(false);
  const { data, refetch, isSuccess } = usePdfGetWorksheetUrlQuery({ puzzleId: puzzleId! }, { skip: !getPdf ||  !puzzleId });
  const dispatch = useDispatch()

  const downloadURL = useCallback((url: string): void => {
    const a = document.createElement('a');
    a.href = url;
    a.download = `Solvemoji - Puzzle ${puzzleId} - Worksheet`;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, [puzzleId])

  useEffect(() => {
    const pdf = data as any;
    if (pdf?.Url && hasRefetched && puzzleId) {
      downloadURL(pdf.Url);
      dispatch(api.util.invalidateTags([{ type: 'pdfWorksheetUrl' }]));
      setHasRefetched(false);
      setPuzzleId(undefined);
    }
  }, [isSuccess, data, hasRefetched, puzzleId, dispatch, downloadURL]);

  const doRefetch = () => {
    if (!getPdf) {
      setGetPdf(true);
    } else {
      refetch()
    }
  }

  const savePuzzleWorksheet = async (id: number) => {
    setPuzzleId(id);
    doRefetch()
    setHasRefetched(true);
  }

  return savePuzzleWorksheet;
}