import * as React from 'react';
import { connect } from 'react-redux';
import { titleColours } from '../../constants';

interface ColouredTitleProps {
  title: string;
  small?: boolean;
}

const ColouredTitle = (props: ColouredTitleProps) => {
  const { title, small } = props;

  const titleSplit = title.split('');

  return (
    <div
      className='colouredTitle__title'
      style={{ fontSize: small ? '2em' : '3em' }}
    >
      {titleSplit.map((x, i) => (
        <span key={`ct_${i}`} style={{ color: titleColours[i % 6] }}>
          {x}
        </span>
      ))}
    </div>
  );
};

export default connect()(ColouredTitle);
