import { store } from './../../index';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const baseApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: `https://${process.env.REACT_APP_API_URL}`,
        prepareHeaders: (headers, { endpoint, getState }) => {
            if (endpoint !== 'getGeoLocation') {
              let token = store.getState().accountStore?.session_token?.access_token;

              if (token) {
                  headers.set('authorization', `Bearer ${token}`);
              }
            }

            return headers;
        }
    }),
    reducerPath: "generatedApi",
    tagTypes: [],
    endpoints: () => ({})
})
