import { Action, Reducer } from 'redux'
import { AppThunkAction } from './'
import { IPuzzle } from '../interfaces/IPuzzle'
import { ISolvedIds } from '../interfaces/ISolvedIds'
import {
  UserLeaderboardRank,
  LeaderboardTop10,
  LeadboardboardReturnModel,
  LeaderboardModel,
} from '../interfaces/Top10LeaderboardInterfaces'
import { MapMinifiedPuzzle, MapMinifiedPuz, GetNewPuzzles, generateId } from '../helpers'
import { PuzzleType, Level, SecretKey, EndPoint } from '../constants'
import { actionCreators as accountActionCreators } from './Account'
import { PuzzleOptions } from '../interfaces/IPuzzleOptions'

interface PuzzleSolution {
  show: boolean
  puzzle?: IPuzzle
}

export interface PuzzleState {
  todaysPuzzles: IPuzzle[]
  puzzle?: IPuzzle
  completedPuzzle?: IPuzzle
  selectedPuzzle?: IPuzzle
  showCompleted: boolean
  showSolution: boolean
  puzzles: IPuzzle[]
  newPuzzleOptions: PuzzleOptions
  popularPuzzleOptions: PuzzleOptions
  suggestedPuzzles: IPuzzle[]
  completedPuzzleIds: number[]
  attemptedIds: number[]
  solvedIds: number[]
  revealedIds: number[]
  leaderboard: LeaderboardModel[]
  leaderboardWeekly: LeaderboardModel[]
  leaderboardDaily: LeaderboardModel[]
  top10s: LeaderboardTop10[]
  userRanks: UserLeaderboardRank[]
  hideSolved: boolean
}

export interface GetTodaysPuzzlesAction {
  type: 'GET_TODAYS_PUZZLES'
  data: IPuzzle[]
}
export interface SetPuzzleAction {
  type: 'GET_PUZZLE'
  data?: IPuzzle
}

export interface GetPuzzles {
  type: 'GET_PUZZLES'
  data: IPuzzle[]
}

export interface InvalidateTags {
  type: 'generatedApi/invalidateTags'
  payload: string[]
}

export interface GetPopularPuzzles {
  type: 'GET_POPULAR_PUZZLES'
  data: IPuzzle[]
}

export interface GetNewPuzzles {
  type: 'GET_NEW_PUZZLES'
  data: IPuzzle[]
}

export interface UpdateNewPuzzleOptions {
  type: 'UPDATE_NEW_PUZZLE_OPTIONS'
  newPuzzleOptions: PuzzleOptions
}

export interface UpdatePopularPuzzleOptions {
  type: 'UPDATE_POPULAR_PUZZLE_OPTIONS'
  popularPuzzleOptions: PuzzleOptions
}

export interface SetSuggestedPuzzles {
  type: 'SET_SUGGESTED_PUZZLES'
  data: IPuzzle[]
}

export interface SetCompletedPuzzle {
  type: 'SET_COMPLETED_PUZZLE'
  data: IPuzzle
}

export interface RemovePuzzle {
  type: 'REMOVE_PUZZLE'
  data: number
}

export interface ShowCompleted {
  type: 'SHOW_COMPLETED'
  data: boolean
}

export interface ShowSolution {
  type: 'SHOW_SOLUTION'
  data: PuzzleSolution
}

export interface AddCorrectPuzzleId {
  type: 'ADD_CORRECT_PUZZLE_ID'
  data: number
}

export interface GetTop10Leaderboards {
  type: 'GET_TOP_10_LEADERBOARDS'
  data: LeadboardboardReturnModel
}

export interface GetLeaderboardRanks {
  type: 'GET_LEADERBOARD_RANKS'
  data: LeadboardboardReturnModel
}

export interface GetLeaderboards {
  type: 'GET_LEADERBOARDS'
  data: LeadboardboardReturnModel
}

export interface GetLeaderboardsWeekly {
  type: 'GET_LEADERBOARDS_WEEKLY'
  data: LeadboardboardReturnModel
}

export interface GetLeaderboardsDaily {
  type: 'GET_LEADERBOARDS_DAILY'
  data: LeadboardboardReturnModel
}

export interface ClearPuzzles {
  type: 'CLEAR_PUZZLES'
}

export interface ToggleSolved {
  type: 'TOGGLE_HIDE_SOLVED'
  data: boolean
}

export interface GetSolvedIds {
  type: 'GET_SOLVED_IDS'
  data: ISolvedIds
}

export interface GetRevealedIds {
  type: 'GET_REVEALED_IDS'
  data: number[]
}

export type KnownAction =
  | GetTodaysPuzzlesAction
  | SetPuzzleAction
  | GetPuzzles
  | GetPopularPuzzles
  | GetNewPuzzles
  | UpdateNewPuzzleOptions
  | UpdatePopularPuzzleOptions
  | SetSuggestedPuzzles
  | RemovePuzzle
  | SetCompletedPuzzle
  | ShowCompleted
  | ShowSolution
  | AddCorrectPuzzleId
  | GetTop10Leaderboards
  | GetLeaderboardRanks
  | GetLeaderboards
  | GetLeaderboardsWeekly
  | GetLeaderboardsDaily
  | ClearPuzzles
  | ToggleSolved
  | GetSolvedIds
  | GetRevealedIds
  | InvalidateTags

export const actionCreators = {
  updateNewPuzzleOptions: (
    newPuzzleOptions: PuzzleOptions
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    if (
      newPuzzleOptions !== undefined &&
      newPuzzleOptions.levelId !== undefined &&
      newPuzzleOptions.puzzleTypeId !== undefined &&
      newPuzzleOptions.levelId > 5 &&
      (newPuzzleOptions.puzzleTypeId === PuzzleType.Word ||
        newPuzzleOptions.puzzleTypeId === PuzzleType.Grid)
    ) {
      newPuzzleOptions.levelId = Level.Unset
    }

    dispatch({
      type: 'UPDATE_NEW_PUZZLE_OPTIONS',
      newPuzzleOptions,
    })

    // @ts-ignore
    dispatch(actionCreators.getNewPuzzles())
  },

  updatePopularPuzzleOptions: (
    popularPuzzleOptions: PuzzleOptions
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    if (
      popularPuzzleOptions !== undefined &&
      popularPuzzleOptions.levelId !== undefined &&
      popularPuzzleOptions.puzzleTypeId !== undefined &&
      popularPuzzleOptions.levelId > 5 &&
      (popularPuzzleOptions.puzzleTypeId === PuzzleType.Word ||
        popularPuzzleOptions.puzzleTypeId === PuzzleType.Grid)
    ) {
      popularPuzzleOptions.levelId = Level.Unset
    }

    dispatch({
      type: 'UPDATE_POPULAR_PUZZLE_OPTIONS',
      popularPuzzleOptions,
    })

    // @ts-ignore
    dispatch(actionCreators.getPopularPuzzles())
  },

  uploadPuzzleGuess: (
    puzzleId: number,
    guess: string,
    correct: boolean
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const { accountStore } = getState()

    const userId =
      accountStore && accountStore.user_details
        ? accountStore.user_details.Id
        : undefined

    if (correct) {
      dispatch({ type: 'ADD_CORRECT_PUZZLE_ID', data: puzzleId })
      window.setTimeout(
        () => dispatch({ type: 'SHOW_COMPLETED', data: true }),
        2000
      )
    }

    let deviceID = localStorage.getItem('XID')

    if (!deviceID) {
      deviceID = generateId()

      localStorage.setItem('XID', deviceID)
    }

    const responsePuz = await fetch(
      `${EndPoint}/api/Puzzle/UploadPuzzleGuess`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          PuzzleId: puzzleId,
          PuzzleGuess: guess,
          AccountUserId: userId,
          DeviceId: !userId ? deviceID : null,
        }),
      }
    )

    const json: any = await responsePuz.text()
    const puzzle = MapMinifiedPuz(JSON.parse(json))
    dispatch({ type: 'GET_PUZZLES', data: [puzzle] })
    dispatch({ type: 'SET_COMPLETED_PUZZLE', data: puzzle })
    // @ts-ignore
    dispatch(actionCreators.getSolvedPuzzleIds())

    dispatch({
      type: 'generatedApi/invalidateTags',
      payload: ['puzzleLists'],
    })
  },

  removePuzzle: (puzzleId: number): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    await fetch(`${EndPoint}/api/Puzzle/rp`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        PuzzleId: puzzleId,
      }),
    })

    dispatch({ type: 'REMOVE_PUZZLE', data: puzzleId })
  },

  getLeaderboardRanks: (): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const { accountStore } = getState()

    if (accountStore && accountStore.user_details) {
      var response = await fetch(
        `${EndPoint}/api/Puzzle/GetLeaderboardsRanks`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            secret: SecretKey,
            userId: accountStore.user_details.Id,
          }),
        }
      )

      const json: LeadboardboardReturnModel = await response.json()
      dispatch({ type: 'GET_LEADERBOARD_RANKS', data: json })
    }
  },

  toggleHideSolved: (
    hideSolved: boolean
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    dispatch({
      type: 'TOGGLE_HIDE_SOLVED',
      data: hideSolved,
    })
  },

  getTop10Leaderboards: (): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const { accountStore } = getState()

    const userId =
      accountStore && accountStore.user_details
        ? accountStore.user_details.Id
        : undefined

    const response = await fetch(
      `${EndPoint}/api/Puzzle/GetTop10Leaderboards`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          secret: SecretKey,
          userId,
        }),
      }
    )

    const json: LeadboardboardReturnModel = await response.json()
    dispatch({ type: 'GET_TOP_10_LEADERBOARDS', data: json })
  },

  GetAllTimeLeaderboard: (): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const { accountStore } = getState()

    const userId =
      accountStore && accountStore.user_details
        ? accountStore.user_details.Id
        : undefined

    const response = await fetch(
      `${EndPoint}/api/Puzzle/GetAllTimeLeaderboard`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          userId,
        }),
      }
    )

    const json: LeadboardboardReturnModel = await response.json()
    dispatch({ type: 'GET_LEADERBOARDS', data: json })
  },

  GetWeeklyLeaderboard: (): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const { accountStore } = getState()

    const userId =
      accountStore && accountStore.user_details
        ? accountStore.user_details.Id
        : undefined

    const response = await fetch(
      `${EndPoint}/api/Puzzle/GetWeeklyLeaderboard`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          userId,
        }),
      }
    )

    const json: LeadboardboardReturnModel = await response.json()
    dispatch({ type: 'GET_LEADERBOARDS_WEEKLY', data: json })
  },

  GetDailyLeaderboard: (): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const { accountStore } = getState()

    const userId =
      accountStore && accountStore.user_details
        ? accountStore.user_details.Id
        : undefined

    const response = await fetch(`${EndPoint}/api/Puzzle/GetDailyLeaderboard`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        userId,
      }),
    })

    const json: LeadboardboardReturnModel = await response.json()
    dispatch({ type: 'GET_LEADERBOARDS_DAILY', data: json })
  },

  getTodaysPuzzles: (): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const response = await fetch(
      `${EndPoint}/api/Puzzle/GetAllTodaysLatestPuzzlesLatest`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        method: 'GET',
      }
    )
    const json: any = await response.text()
    const puzzles = MapMinifiedPuzzle(json)
    dispatch({ type: 'GET_PUZZLES', data: puzzles })
    dispatch({ type: 'GET_TODAYS_PUZZLES', data: puzzles })
  },
  getPopularPuzzles: (): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const { puzzleStore, accountStore } = getState()

    if (puzzleStore && accountStore) {
      const { hideSolved } = puzzleStore

      const levelId = puzzleStore.popularPuzzleOptions
        ? puzzleStore.popularPuzzleOptions.levelId
        : undefined
      const puzzleTypeId = puzzleStore.popularPuzzleOptions
        ? puzzleStore.popularPuzzleOptions.puzzleTypeId
        : undefined
      const userId = accountStore.user_details
        ? accountStore.user_details.Id
        : undefined

      const response = await fetch(`${EndPoint}/api/Puzzle/getPopularPuzzles`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          userId,
          levelId,
          puzzleTypeId,
          showSolved: !hideSolved,
          sudoku: true
        }),
      })
      const json: any = await response.json()
      const puzzles = MapMinifiedPuzzle(json)

      dispatch({ type: 'GET_POPULAR_PUZZLES', data: puzzles })
      // @ts-ignore
      dispatch(actionCreators.getSolvedPuzzleIds())
    }
  },

  getNewSuggestedPuzzles: (
    levelId: number,
    puzzleTypeId: number,
    puzzleTheme: string,
    userId?: string
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const puzzles: any = await GetNewPuzzles(
      userId,
      levelId,
      puzzleTypeId,
      puzzleTheme,
      true // only show not complete puzzles
    )
    dispatch({ type: 'GET_NEW_PUZZLES', data: puzzles })
  },

  getNewPuzzles: (): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const { puzzleStore, accountStore } = getState()

    if (puzzleStore && accountStore) {
      const { hideSolved } = puzzleStore

      const levelId = puzzleStore.newPuzzleOptions
        ? puzzleStore.newPuzzleOptions.levelId
        : undefined
      const puzzleTypeId = puzzleStore.newPuzzleOptions
        ? puzzleStore.newPuzzleOptions.puzzleTypeId
        : undefined
      const userId = accountStore.user_details
        ? accountStore.user_details.Id
        : undefined

      const puzzles: any = await GetNewPuzzles(
        userId,
        levelId,
        puzzleTypeId,
        undefined,
        !hideSolved
      )
      dispatch({ type: 'GET_NEW_PUZZLES', data: puzzles })
      // @ts-ignore
      dispatch(actionCreators.getSolvedPuzzleIds())
    }
  },

  doShowCompleted: (show: boolean): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    dispatch({ type: 'SHOW_COMPLETED', data: show })
  },

  doShowSolution: (
    show: boolean,
    puzzle?: IPuzzle
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    dispatch({ type: 'SHOW_SOLUTION', data: { show, puzzle } })
  },

  updateRevealedPuzzle: (
    puzzleId: number
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const { puzzleStore, accountStore } = getState()
    if (puzzleStore && accountStore && puzzleId) {
      const { revealedIds } = puzzleStore
      const { session_token, user_details } = accountStore
      if (session_token && user_details) {
        if (!revealedIds || !revealedIds.find((x) => x === puzzleId)) {
          const result1 = await fetch(
            `${EndPoint}/api/Puzzle/setPuzzleRevealed`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${session_token.access_token}`,
              },
              body: JSON.stringify({
                PuzzleId: puzzleId,
              }),
            }
          )

          const json1 = await result1.json()

          dispatch({ type: 'GET_REVEALED_IDS', data: json1 })
        }
      }
    }
  },

  getSolvedPuzzleIds: (): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    try {
      // alert('here')
      const { accountStore } = getState()
      if (accountStore) {
        const { session_token } = accountStore
        if (session_token) {
          const url1 = `${EndPoint}/api/Puzzle/GetSolvedPuzzleIds`
          const objectP1 = {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${session_token.access_token}`,
            },
          }

          const result1 = await fetch(url1, objectP1)

          if (result1.status !== 200) {
            throw new Error('Failed Response')
          }

          const json1 = await result1.json()

          dispatch({ type: 'GET_SOLVED_IDS', data: json1 })
        } else {
          let deviceID = localStorage.getItem('XID')

          if (deviceID) {
            const url1 = `${EndPoint}/api/Puzzle/GetSolvedDevicePuzzleIds`
            const objectP1 = {
              method: 'POST',
              body: JSON.stringify({ deviceID }),
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
            }

            const result1 = await fetch(url1, objectP1)

            if (result1.status !== 200) {
              throw new Error('Failed Response')
            }

            const json1 = await result1.json()

            dispatch({ type: 'GET_SOLVED_IDS', data: json1 })
          }
        }
      }
    } catch (err) {
      console.error(err)
      // @ts-ignore
      dispatch(accountActionCreators.logOut())
    }
  },

  getPuzzle: (puzzleId: string): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    dispatch({ type: 'GET_PUZZLE', data: undefined })
    dispatch({ type: 'SET_SUGGESTED_PUZZLES', data: [] })
    let error = false;

    const { puzzleStore } = getState()

    // const hideSolved = puzzleStore ? puzzleStore.hideSolved : true;

    let puz = puzzleStore
      ? puzzleStore.puzzles.find((x) => x.PuzzleId === parseInt(puzzleId, 10))
      : undefined

    const func = async () => {
      try {
        // do real query to server to get data
        const response = await fetch(`${EndPoint}/api/Puzzle/GetPuzzle`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify({
            PuzzleId: puzzleId,
          }),
        })

        const json: any = await response.json()

        if (!json) {
          window.location.href = '/404'
          return;
        }

        puz = MapMinifiedPuz(json)

        if (puz) {
          dispatch({ type: 'GET_PUZZLE', data: puz })

          // check other puzzles for suggested
          // @ts-ignore
          const puzzles =
            puzzleStore && puz !== undefined
              ? puzzleStore.puzzles.filter(
                (x) =>
                  x.PuzzleId !== parseInt(puzzleId) &&
                  x.PuzzleTypeId === (puz ? puz.PuzzleTypeId : -1) &&
                  x.LevelId === (puz ? puz.LevelId : -1) &&
                  x.PuzzleTheme === (puz ? puz.PuzzleTheme : '')
              )
              : []

          // if we are running low then get more puzzles
          if (puzzles.length < 10) {
            const puzzleAll = (await GetNewPuzzles(
              undefined,
              puz.LevelId,
              puz.PuzzleTypeId,
              undefined,
              true // only not completed puzzles
            )) as IPuzzle[]
            dispatch({ type: 'GET_PUZZLES', data: puzzleAll })

            const puzzles = (await GetNewPuzzles(
              undefined,
              puz.LevelId,
              puz.PuzzleTypeId,
              puz.PuzzleTheme,
              true // only not completed puzzles
            )) as IPuzzle[]
            dispatch({ type: 'GET_PUZZLES', data: puzzles })
          }
        } else {
          alert('here')
        }
      } catch (err) {
        error = true;
      }
    }

    if (puz) {
      dispatch({ type: 'GET_PUZZLE', data: puz })
      func()
    } else {
      if (!error) {
        await func()
      } else {
        window.location.href = '/404'
        return;
      }
    }
  },
}

export const reducer: Reducer<PuzzleState> = (
  state: PuzzleState | undefined,
  incomingAction: Action
): PuzzleState => {
  if (state === undefined) {
    return {
      todaysPuzzles: [],
      puzzles: [],
      newPuzzleOptions: {
        puzzleTypeId: PuzzleType.Mixed,
        levelId: Level.Unset,
      },
      popularPuzzleOptions: {
        puzzleTypeId: PuzzleType.Mixed,
        levelId: Level.Unset,
      },
      suggestedPuzzles: [],
      completedPuzzleIds: [],
      attemptedIds: [],
      solvedIds: [],
      revealedIds: [],
      showCompleted: false,
      showSolution: false,
      top10s: [],
      leaderboard: [],
      leaderboardWeekly: [],
      leaderboardDaily: [],
      userRanks: [],
      hideSolved: true,
    }
  }

  const action = incomingAction as KnownAction
  switch (action.type) {
    case 'GET_PUZZLE': {
      if (action.data !== undefined) {
        const oldPuzzles = state.puzzles.filter(
          (x) => action.data && action.data.PuzzleId !== x.PuzzleId
        )

        return {
          ...state,
          puzzle: action.data,
          puzzles: [action.data, ...oldPuzzles],
        }
      } else {
        return {
          ...state,
        }
      }
    }
    case 'GET_SOLVED_IDS': {
      return {
        ...state,
        solvedIds: action.data.SolvedPuzzleIds,
        revealedIds: action.data.RevealedPuzzleIds,
        attemptedIds: action.data.AttemptedPuzzleIds
      }
    }
    case 'GET_REVEALED_IDS': {
      return {
        ...state,
        revealedIds: action.data,
      }
    }
    case 'TOGGLE_HIDE_SOLVED': {
      return {
        ...state,
        hideSolved: action.data,
      }
    }
    case 'CLEAR_PUZZLES': {
      return {
        ...state,
        puzzles: [],
        puzzle: undefined,
        suggestedPuzzles: [],
        completedPuzzleIds: [],
        solvedIds: [],
        revealedIds: [],
        showCompleted: false,
        showSolution: false,
        leaderboard: [],
        leaderboardWeekly: [],
        leaderboardDaily: [],
        userRanks: [],
        hideSolved: true,
      }
    }
    case 'SHOW_COMPLETED': {
      return {
        ...state,
        showCompleted: action.data,
      }
    }

    case 'SHOW_SOLUTION': {
      const { show, puzzle } = action.data

      return {
        ...state,
        showSolution: show,
        selectedPuzzle: puzzle,
      }
    }
    case 'GET_TOP_10_LEADERBOARDS': {
      return {
        ...state,
        top10s: action.data.top10s,
        userRanks: action.data.userRanks,
      }
    }

    case 'GET_LEADERBOARD_RANKS': {
      return {
        ...state,
        userRanks: action.data.userRanks,
      }
    }

    case 'GET_LEADERBOARDS': {
      return {
        ...state,
        leaderboard: action.data.leaderboard,
        userRanks: action.data.userRanks,
      }
    }

    case 'GET_LEADERBOARDS_WEEKLY': {
      return {
        ...state,
        leaderboardWeekly: action.data.leaderboard,
        userRanks: action.data.userRanks,
      }
    }

    case 'GET_LEADERBOARDS_DAILY': {
      return {
        ...state,
        leaderboardDaily: action.data.leaderboard,
        userRanks: action.data.userRanks,
      }
    }

    case 'ADD_CORRECT_PUZZLE_ID': {
      const completedPuzzleIds = state.completedPuzzleIds
      completedPuzzleIds.push(action.data)
      let unique = [...new Set(completedPuzzleIds)]

      return {
        ...state,
        completedPuzzleIds: unique,
      }
    }
    case 'SET_COMPLETED_PUZZLE': {
      if (action.data !== undefined) {
        return {
          ...state,
          completedPuzzle: action.data,
        }
      } else {
        return {
          ...state,
        }
      }
    }
    case 'GET_NEW_PUZZLES':
    case 'GET_POPULAR_PUZZLES': {
      const oldPuzzles = state.puzzles.filter(
        (x) => !action.data.find((y) => y.PuzzleId === x.PuzzleId)
      )

      return {
        ...state,
        puzzles: [...action.data, ...oldPuzzles],
      }
    }
    case 'GET_PUZZLES':
      const oldPuzzles = state.puzzles.filter(
        (x) => !action.data.find((y) => y.PuzzleId === x.PuzzleId)
      )

      let oldTodaysPuzzles = state.todaysPuzzles

      if (
        oldTodaysPuzzles.find((x) =>
          action.data.find((y) => y.PuzzleId === x.PuzzleId)
        )
      ) {
        oldTodaysPuzzles = [
          ...oldTodaysPuzzles.filter(
            (x) => !action.data.find((y) => y.PuzzleId === x.PuzzleId)
          ),
          ...action.data.filter((x) =>
            oldTodaysPuzzles.find((y) => y.PuzzleId === x.PuzzleId)
          ),
        ]
      }

      return {
        ...state,
        puzzles: [...action.data, ...oldPuzzles],
        todaysPuzzles: [...oldTodaysPuzzles],
      }

    case 'REMOVE_PUZZLE': {
      const oldPuzzles = state.puzzles.filter(
        (x) => x.PuzzleId !== action.data
      )

      return {
        ...state,
        puzzles: [...oldPuzzles],
      }
    }
    case 'GET_TODAYS_PUZZLES':
      return {
        ...state,
        todaysPuzzles: action.data,
      }
    case 'UPDATE_NEW_PUZZLE_OPTIONS':
      return {
        ...state,
        newPuzzleOptions: action.newPuzzleOptions,
      }

    case 'UPDATE_POPULAR_PUZZLE_OPTIONS':
      return {
        ...state,
        popularPuzzleOptions: action.popularPuzzleOptions,
      }
    default:
      return state
  }
}
