import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    checkoutCreateCheckout: build.mutation<
      CheckoutCreateCheckoutApiResponse,
      CheckoutCreateCheckoutApiArg
    >({
      query: (queryArg) => ({
        url: `/api/checkout/create-checkout`,
        method: "POST",
        body: queryArg.priceIdRequest,
      }),
    }),
    checkoutGetSubscriptions: build.mutation<
      CheckoutGetSubscriptionsApiResponse,
      CheckoutGetSubscriptionsApiArg
    >({
      query: () => ({ url: `/api/checkout/get-subscriptions`, method: "POST" }),
    }),
    checkoutCreateCustomerPortal: build.mutation<
      CheckoutCreateCustomerPortalApiResponse,
      CheckoutCreateCustomerPortalApiArg
    >({
      query: () => ({ url: `/api/checkout/customer-portal`, method: "POST" }),
    }),
    checkoutGetOrders: build.query<
      CheckoutGetOrdersApiResponse,
      CheckoutGetOrdersApiArg
    >({
      query: () => ({ url: `/api/checkout/get-orders` }),
    }),
    checkoutGetAccountStatus: build.query<
      CheckoutGetAccountStatusApiResponse,
      CheckoutGetAccountStatusApiArg
    >({
      query: () => ({ url: `/api/checkout/get-account-status` }),
    }),
    checkoutGetIsCustomer: build.query<
      CheckoutGetIsCustomerApiResponse,
      CheckoutGetIsCustomerApiArg
    >({
      query: () => ({ url: `/api/checkout/get-is-customer` }),
    }),
    checkoutGetSpecialOffer: build.query<
      CheckoutGetSpecialOfferApiResponse,
      CheckoutGetSpecialOfferApiArg
    >({
      query: () => ({ url: `/api/checkout/get-special-offers` }),
    }),
    checkoutGetProducts: build.query<
      CheckoutGetProductsApiResponse,
      CheckoutGetProductsApiArg
    >({
      query: () => ({ url: `/api/checkout/get-products` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as baseApi };
export type CheckoutCreateCheckoutApiResponse = /** status 200 OK */ ReturnUrl;
export type CheckoutCreateCheckoutApiArg = {
  priceIdRequest: PriceIdRequest;
};
export type CheckoutGetSubscriptionsApiResponse = unknown;
export type CheckoutGetSubscriptionsApiArg = void;
export type CheckoutCreateCustomerPortalApiResponse =
  /** status 200 OK */ ReturnUrl;
export type CheckoutCreateCustomerPortalApiArg = void;
export type CheckoutGetOrdersApiResponse = /** status 200 OK */ OrdersRead[];
export type CheckoutGetOrdersApiArg = void;
export type CheckoutGetAccountStatusApiResponse =
  /** status 200 OK */ AccountUserStatusRead;
export type CheckoutGetAccountStatusApiArg = void;
export type CheckoutGetIsCustomerApiResponse = /** status 200 OK */ boolean;
export type CheckoutGetIsCustomerApiArg = void;
export type CheckoutGetSpecialOfferApiResponse =
  /** status 200 OK */ SpecialOffer;
export type CheckoutGetSpecialOfferApiArg = void;
export type CheckoutGetProductsApiResponse =
  /** status 200 OK */ StripeProducts;
export type CheckoutGetProductsApiArg = void;
export type ReturnUrl = {
  Url?: string;
};
export type PriceIdRequest = {
  id?: string;
  currency?: string;
};
export type Orders = {
  Id?: number;
  LineItemId?: string;
  InvoiceId?: string;
  StripeCustomerId?: string;
  InvoiceNumber?: string;
  InvoicePdf?: string;
  SubscriptionId?: string;
  ProductId?: string;
  OrderDate?: string;
  Currency?: string;
  Amount?: number;
  AmountPaid?: number;
  StartDate?: string;
  EndDate?: string;
  OrderDescription?: string;
  CancelAtPeriodEnd?: boolean;
  SubscriptionItemId?: string;
  Active?: boolean;
};
export type OrdersRead = {
  Id?: number;
  LineItemId?: string;
  InvoiceId?: string;
  StripeCustomerId?: string;
  InvoiceNumber?: string;
  InvoicePdf?: string;
  SubscriptionId?: string;
  ProductId?: string;
  OrderDate?: string;
  RenewalDate?: string;
  IsExpired?: boolean;
  Currency?: string;
  Amount?: number;
  AmountPaid?: number;
  StartDate?: string;
  EndDate?: string;
  OrderDescription?: string;
  CancelAtPeriodEnd?: boolean;
  SubscriptionItemId?: string;
  Active?: boolean;
};
export type AccountUserStatus = {
  Orders?: Orders[];
  IsStudent?: boolean;
};
export type AccountUserStatusRead = {
  Orders?: OrdersRead[];
  IsStudent?: boolean;
};
export type SpecialOffer = {
  SpecialOfferId?: number;
  Name?: string;
  Description?: string;
  Code?: string;
  DateFrom?: string;
  DateTo?: string;
  PercentageDiscount?: number;
};
export type PriceCurrencyOptionsCustomUnitAmount = {
  maximum?: number;
  minimum?: number;
  preset?: number;
};
export type PriceCurrencyOptionsTier = {
  flat_amount?: number;
  flat_amount_decimal?: number;
  unit_amount?: number;
  unit_amount_decimal?: number;
  up_to?: number;
};
export type PriceCurrencyOptions = {
  custom_unit_amount?: PriceCurrencyOptionsCustomUnitAmount;
  tax_behavior?: string;
  tiers?: PriceCurrencyOptionsTier[];
  unit_amount?: number;
  unit_amount_decimal?: number;
};
export type PriceCustomUnitAmount = {
  maximum?: number;
  minimum?: number;
  preset?: number;
};
export type StripeExpandableField1StripeProductStripeNetVersion43400CultureNeutralPublicKeyTokenNull =
  {
    Id?: string;
    ExpandedObject?: Product;
  };
export type PriceRecurring = {
  aggregate_usage?: string;
  interval?: string;
  interval_count?: number;
  trial_period_days?: number;
  usage_type?: string;
};
export type PriceTier = {
  flat_amount?: number;
  flat_amount_decimal?: number;
  unit_amount?: number;
  unit_amount_decimal?: number;
  up_to?: number;
};
export type PriceTransformQuantity = {
  divide_by?: number;
  round?: string;
};
export type Price = {
  id?: string;
  object?: string;
  active?: boolean;
  billing_scheme?: string;
  created?: string;
  currency?: string;
  currency_options?: {
    [key: string]: PriceCurrencyOptions;
  };
  custom_unit_amount?: PriceCustomUnitAmount;
  deleted?: boolean;
  livemode?: boolean;
  lookup_key?: string;
  metadata?: {
    [key: string]: string;
  };
  nickname?: string;
  product?: StripeExpandableField1StripeProductStripeNetVersion43400CultureNeutralPublicKeyTokenNull;
  recurring?: PriceRecurring;
  tax_behavior?: string;
  tiers?: PriceTier[];
  tiers_mode?: string;
  transform_quantity?: PriceTransformQuantity;
  type?: string;
  unit_amount?: number;
  unit_amount_decimal?: number;
};
export type StripeExpandableField1StripePriceStripeNetVersion43400CultureNeutralPublicKeyTokenNull =
  {
    Id?: string;
    ExpandedObject?: Price;
  };
export type ProductFeature = {
  name?: string;
};
export type ProductPackageDimensions = {
  height?: number;
  length?: number;
  weight?: number;
  width?: number;
};
export type TaxCode = {
  id?: string;
  object?: string;
  description?: string;
  name?: string;
};
export type StripeExpandableField1StripeTaxCodeStripeNetVersion43400CultureNeutralPublicKeyTokenNull =
  {
    Id?: string;
    ExpandedObject?: TaxCode;
  };
export type Product = {
  id?: string;
  object?: string;
  active?: boolean;
  created?: string;
  default_price?: StripeExpandableField1StripePriceStripeNetVersion43400CultureNeutralPublicKeyTokenNull;
  deleted?: boolean;
  description?: string;
  features?: ProductFeature[];
  images?: string[];
  livemode?: boolean;
  metadata?: {
    [key: string]: string;
  };
  name?: string;
  package_dimensions?: ProductPackageDimensions;
  shippable?: boolean;
  statement_descriptor?: string;
  tax_code?: StripeExpandableField1StripeTaxCodeStripeNetVersion43400CultureNeutralPublicKeyTokenNull;
  type?: string;
  unit_label?: string;
  updated?: string;
  url?: string;
};
export type StripeStripeList1StripeProductStripeNetVersion43400CultureNeutralPublicKeyTokenNull =
  {
    object?: string;
    data?: Product[];
    has_more?: boolean;
    url?: string;
  };
export type StripeStripeList1StripePriceStripeNetVersion43400CultureNeutralPublicKeyTokenNull =
  {
    object?: string;
    data?: Price[];
    has_more?: boolean;
    url?: string;
  };
export type StripeProducts = {
  Products?: StripeStripeList1StripeProductStripeNetVersion43400CultureNeutralPublicKeyTokenNull;
  Prices?: StripeStripeList1StripePriceStripeNetVersion43400CultureNeutralPublicKeyTokenNull;
};
export const {
  useCheckoutCreateCheckoutMutation,
  useCheckoutGetSubscriptionsMutation,
  useCheckoutCreateCustomerPortalMutation,
  useCheckoutGetOrdersQuery,
  useCheckoutGetAccountStatusQuery,
  useCheckoutGetIsCustomerQuery,
  useCheckoutGetSpecialOfferQuery,
  useCheckoutGetProductsQuery,
} = injectedRtkApi;
