import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import * as PuzzleStore from '../../store/Puzzle';
import * as AccountStore from '../../store/Account';
import ColouredTitle from '../misc/ColouredTitle';
import history from '../../history';

// @ts-ignore
var ReactFitText = require('react-fittext');

type PuzzleProps = {
  puzzleStore: PuzzleStore.PuzzleState;
  accountStore: AccountStore.AccountState;
} & typeof PuzzleStore.actionCreators &
  RouteComponentProps<{}>;

class SolvemojiConfirm extends React.PureComponent<PuzzleProps> {
  handleNo = () => {
    this.props.doShowSolution(false);
  };

  handleLogin = () => {
    history.push(`/login`);
    this.props.doShowSolution(false);
  };

  handleRegister = () => {
    history.push(`/register`);
    this.props.doShowSolution(false);
  };

  handleYes = () => {
    const { selectedPuzzle: puzzle } = this.props.puzzleStore;
    if (puzzle) {
      history.push(`/puzzleSolution/${puzzle.PuzzleId}`);
      this.props.doShowSolution(false);
    }
  };

  render() {
    const { handleNo, handleYes, handleLogin, handleRegister } = this;
    const { selectedPuzzle, showSolution } = this.props.puzzleStore;
    const { user_details } = this.props.accountStore;

    if (!selectedPuzzle || !showSolution) {
      return null;
    }

    return (
      <div className='puzzleComplete'>
        <div className='row'>
          <div className='col-12 col-sm-1 col-md-2 col-lg-3'></div>
          <div className='col-12 col-sm-10 col-md-8 col-lg-6'>
            <div className='message'>
              <div className='modal__close' onClick={handleNo}>
                <i className='icon-times'></i>
              </div>
              <div className='row'>
                <div className='col col-12 text-center'>
                  <ReactFitText compressor={1.1}>
                    <ColouredTitle title='SOLUTION' />
                  </ReactFitText>
                  <div className='puzzleComplete__text'>
                    Do you want to see the solution to this puzzle?
                  </div>

                  <div className='row'>
                    <div className='col-12 col-sm-3'></div>
                    <div className='col-12 col-sm-6 text-center'>
                      <div className='imageBackground'>
                        <img
                          src={
                            selectedPuzzle.QuestionImageSvg ||
                            selectedPuzzle.QuestionImageUrl
                          }
                          alt={`puzzleId_${selectedPuzzle.PuzzleId}`}
                          className='puzzleComplete__image'
                        />
                      </div>
                    </div>
                    <div className='col-12 col-sm-3'></div>
                  </div>
                  {user_details && (
                    <>
                      <div className='puzzleComplete__text'>
                        You will not be able to guess this puzzle once the
                        solution is shown.
                      </div>
                      <div className='row mt-20'>
                        <div className='col text-right'>
                          <button
                            className='btn btn-lg puzzleComplete__button-yes'
                            onClick={handleYes}
                          >
                            <i className='icon-thumbs-o-up mr-10'></i>
                            <span>Yes Please</span>
                          </button>
                        </div>
                        <div className='col text-left'>
                          <button
                            className='btn btn-lg puzzleComplete__button-no'
                            onClick={handleNo}
                          >
                            <i className='icon-thumbs-o-down mr-10'></i>
                            <span>No Thanks</span>
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {!user_details && (
                    <>
                      <div className='puzzleComplete__text'>
                        You must have a Solemoji subscription to view solutions
                      </div>
                      <div className='row mt-20'>
                        <div className='col'>
                          <button
                            className='btn btn-lg puzzleComplete__button-login mb-10'
                            onClick={handleLogin}
                          >
                            <i className='icon-sign-in mr-10'></i>
                            <span>Login</span>
                          </button>
                        </div>
                        <div className='col'>
                          <button
                            className='btn btn-lg puzzleComplete__button-register  mb-10'
                            onClick={handleRegister}
                          >
                            <i className='icon-user-plus mr-10'></i>
                            <span>Register</span>
                          </button>
                        </div>
                        <div className='col'>
                          <button
                            className='btn btn-lg puzzleComplete__button-no  mb-10'
                            onClick={handleNo}
                          >
                            <i className='icon-thumbs-o-down mr-10'></i>
                            <span>Cancel</span>
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-sm-1 col-md-2 col-lg-3'></div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
  PuzzleStore.actionCreators // Selects which action creators are merged into the component's props
)(SolvemojiConfirm as any);
