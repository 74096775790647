import React from 'react'

interface IHighlight {
  text: string | JSX.Element,
  top?: number
}

export default function Highlight({ text, top }: IHighlight) {
  return (
    <span style={{top: top}} className='colour__green section__highlightSmall'>
      {text}
    </span>
  )
}
