import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    apiPuzzleGetPotentialScoreUpload: build.mutation<
      ApiPuzzleGetPotentialScoreUploadApiResponse,
      ApiPuzzleGetPotentialScoreUploadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Puzzle/GetPotentialScoreUpload`,
        method: "POST",
        body: queryArg.deviceModel,
      }),
    }),
    apiPuzzleUpdateUserDevicePuzzleGuessesLeaderboards: build.mutation<
      ApiPuzzleUpdateUserDevicePuzzleGuessesLeaderboardsApiResponse,
      ApiPuzzleUpdateUserDevicePuzzleGuessesLeaderboardsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Puzzle/UpdateUserDevicePuzzleGuessesLeaderboards`,
        method: "POST",
        body: queryArg.deviceModel,
      }),
    }),
    apiPuzzleGetTodaysLatestPuzzlesOld: build.mutation<
      ApiPuzzleGetTodaysLatestPuzzlesOldApiResponse,
      ApiPuzzleGetTodaysLatestPuzzlesOldApiArg
    >({
      query: () => ({
        url: `/api/Puzzle/GetTodaysLatestPuzzles`,
        method: "POST",
      }),
    }),
    apiPuzzleGetTodaysLatestPuzzles: build.query<
      ApiPuzzleGetTodaysLatestPuzzlesApiResponse,
      ApiPuzzleGetTodaysLatestPuzzlesApiArg
    >({
      query: () => ({ url: `/api/Puzzle/GetTodaysLatestPuzzlesNew` }),
    }),
    apiPuzzleGetAllTodaysLatestPuzzles: build.query<
      ApiPuzzleGetAllTodaysLatestPuzzlesApiResponse,
      ApiPuzzleGetAllTodaysLatestPuzzlesApiArg
    >({
      query: () => ({ url: `/api/Puzzle/GetAllTodaysLatestPuzzles` }),
    }),
    apiPuzzleGetAllTodaysLatestPuzzlesLatest: build.query<
      ApiPuzzleGetAllTodaysLatestPuzzlesLatestApiResponse,
      ApiPuzzleGetAllTodaysLatestPuzzlesLatestApiArg
    >({
      query: () => ({ url: `/api/Puzzle/GetAllTodaysLatestPuzzlesLatest` }),
    }),
    apiPuzzleGetSeasonalPuzzles: build.query<
      ApiPuzzleGetSeasonalPuzzlesApiResponse,
      ApiPuzzleGetSeasonalPuzzlesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Puzzle/GetSeasonalPuzzles`,
        params: { min: queryArg.min, max: queryArg.max },
      }),
    }),
    apiPuzzleSetPuzzleRevealed: build.mutation<
      ApiPuzzleSetPuzzleRevealedApiResponse,
      ApiPuzzleSetPuzzleRevealedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Puzzle/SetPuzzleRevealed`,
        method: "POST",
        body: queryArg.puzzlesRequestModel,
      }),
    }),
    apiPuzzleGetSolvedPuzzleIds: build.mutation<
      ApiPuzzleGetSolvedPuzzleIdsApiResponse,
      ApiPuzzleGetSolvedPuzzleIdsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Puzzle/GetSolvedPuzzleIds`,
        method: "POST",
        body: queryArg.puzzlesRequestModel,
      }),
    }),
    apiPuzzleGetSolvedDevicePuzzleIds: build.mutation<
      ApiPuzzleGetSolvedDevicePuzzleIdsApiResponse,
      ApiPuzzleGetSolvedDevicePuzzleIdsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Puzzle/GetSolvedDevicePuzzleIds`,
        method: "POST",
        body: queryArg.deviceNewModel,
      }),
    }),
    apiPuzzleGetPopularPuzzles: build.mutation<
      ApiPuzzleGetPopularPuzzlesApiResponse,
      ApiPuzzleGetPopularPuzzlesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Puzzle/GetPopularPuzzles`,
        method: "POST",
        body: queryArg.puzzlesRequestModel,
      }),
    }),
    apiPuzzleRefreshCache: build.query<
      ApiPuzzleRefreshCacheApiResponse,
      ApiPuzzleRefreshCacheApiArg
    >({
      query: () => ({ url: `/api/Puzzle/RefreshCache` }),
    }),
    apiPuzzleGetNewPuzzles: build.mutation<
      ApiPuzzleGetNewPuzzlesApiResponse,
      ApiPuzzleGetNewPuzzlesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Puzzle/GetNewPuzzles`,
        method: "POST",
        body: queryArg.puzzlesRequestModel,
      }),
    }),
    apiPuzzleRp: build.mutation<ApiPuzzleRpApiResponse, ApiPuzzleRpApiArg>({
      query: (queryArg) => ({
        url: `/api/Puzzle/RP`,
        method: "POST",
        body: queryArg.puzzlesRequestModel,
      }),
    }),
    apiPuzzleGetPuzzle: build.mutation<
      ApiPuzzleGetPuzzleApiResponse,
      ApiPuzzleGetPuzzleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Puzzle/GetPuzzle`,
        method: "POST",
        body: queryArg.puzzlesRequestModel,
      }),
    }),
    apiPuzzleUploadPuzzleGuess: build.mutation<
      ApiPuzzleUploadPuzzleGuessApiResponse,
      ApiPuzzleUploadPuzzleGuessApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Puzzle/UploadPuzzleGuess`,
        method: "POST",
        body: queryArg.guess,
      }),
    }),
    apiPuzzleGetLatestPuzzlesUserDevice: build.mutation<
      ApiPuzzleGetLatestPuzzlesUserDeviceApiResponse,
      ApiPuzzleGetLatestPuzzlesUserDeviceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Puzzle/GetLatestPuzzlesUserDevice`,
        method: "POST",
        body: queryArg.puzzleRequestModel,
      }),
    }),
    apiPuzzleGetTop10Leaderboards: build.mutation<
      ApiPuzzleGetTop10LeaderboardsApiResponse,
      ApiPuzzleGetTop10LeaderboardsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Puzzle/GetTop10Leaderboards`,
        method: "POST",
        body: queryArg.leaderboardsModel,
      }),
    }),
    apiPuzzleGetLeaderboardsRanks: build.mutation<
      ApiPuzzleGetLeaderboardsRanksApiResponse,
      ApiPuzzleGetLeaderboardsRanksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Puzzle/GetLeaderboardsRanks`,
        method: "POST",
        body: queryArg.leaderboardsModel,
      }),
    }),
    apiPuzzleGetAllTimeLeaderboard: build.mutation<
      ApiPuzzleGetAllTimeLeaderboardApiResponse,
      ApiPuzzleGetAllTimeLeaderboardApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Puzzle/GetAllTimeLeaderboard`,
        method: "POST",
        body: queryArg.leaderboardsModel,
      }),
    }),
    apiPuzzleGetClassroomLeaderboard: build.mutation<
      ApiPuzzleGetClassroomLeaderboardApiResponse,
      ApiPuzzleGetClassroomLeaderboardApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Puzzle/GetClassroomLeaderboard`,
        method: "POST",
        body: queryArg.leaderboardsModel,
      }),
    }),
    apiPuzzleGetWeeklyLeaderboard: build.mutation<
      ApiPuzzleGetWeeklyLeaderboardApiResponse,
      ApiPuzzleGetWeeklyLeaderboardApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Puzzle/GetWeeklyLeaderboard`,
        method: "POST",
        body: queryArg.leaderboardsModel,
      }),
    }),
    apiPuzzleGetDailyLeaderboard: build.mutation<
      ApiPuzzleGetDailyLeaderboardApiResponse,
      ApiPuzzleGetDailyLeaderboardApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Puzzle/GetDailyLeaderboard`,
        method: "POST",
        body: queryArg.leaderboardsModel,
      }),
    }),
    apiPuzzleUploadPuzzleGuesses: build.mutation<
      ApiPuzzleUploadPuzzleGuessesApiResponse,
      ApiPuzzleUploadPuzzleGuessesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Puzzle/UploadPuzzleGuesses`,
        method: "POST",
        body: queryArg.puzzleGuessModel,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as baseApi };
export type ApiPuzzleGetPotentialScoreUploadApiResponse =
  /** status 200 OK */ object;
export type ApiPuzzleGetPotentialScoreUploadApiArg = {
  deviceModel: DeviceModel;
};
export type ApiPuzzleUpdateUserDevicePuzzleGuessesLeaderboardsApiResponse =
  /** status 200 OK */ object;
export type ApiPuzzleUpdateUserDevicePuzzleGuessesLeaderboardsApiArg = {
  deviceModel: DeviceModel;
};
export type ApiPuzzleGetTodaysLatestPuzzlesOldApiResponse =
  /** status 200 OK */ object;
export type ApiPuzzleGetTodaysLatestPuzzlesOldApiArg = void;
export type ApiPuzzleGetTodaysLatestPuzzlesApiResponse =
  /** status 200 OK */ PuzzleMinified[];
export type ApiPuzzleGetTodaysLatestPuzzlesApiArg = void;
export type ApiPuzzleGetAllTodaysLatestPuzzlesApiResponse =
  /** status 200 OK */ PuzzleMinified[];
export type ApiPuzzleGetAllTodaysLatestPuzzlesApiArg = void;
export type ApiPuzzleGetAllTodaysLatestPuzzlesLatestApiResponse =
  /** status 200 OK */ PuzzleMinified[];
export type ApiPuzzleGetAllTodaysLatestPuzzlesLatestApiArg = void;
export type ApiPuzzleGetSeasonalPuzzlesApiResponse =
  /** status 200 OK */ PuzzleMinified[];
export type ApiPuzzleGetSeasonalPuzzlesApiArg = {
  min: number;
  max: number;
};
export type ApiPuzzleSetPuzzleRevealedApiResponse =
  /** status 200 OK */ number[];
export type ApiPuzzleSetPuzzleRevealedApiArg = {
  puzzlesRequestModel: PuzzlesRequestModel;
};
export type ApiPuzzleGetSolvedPuzzleIdsApiResponse =
  /** status 200 OK */ SolvedRevealedIds;
export type ApiPuzzleGetSolvedPuzzleIdsApiArg = {
  puzzlesRequestModel: PuzzlesRequestModel;
};
export type ApiPuzzleGetSolvedDevicePuzzleIdsApiResponse =
  /** status 200 OK */ SolvedRevealedIds;
export type ApiPuzzleGetSolvedDevicePuzzleIdsApiArg = {
  deviceNewModel: DeviceNewModel;
};
export type ApiPuzzleGetPopularPuzzlesApiResponse =
  /** status 200 OK */ PuzzleMinified[];
export type ApiPuzzleGetPopularPuzzlesApiArg = {
  puzzlesRequestModel: PuzzlesRequestModel;
};
export type ApiPuzzleRefreshCacheApiResponse = /** status 200 OK */ object;
export type ApiPuzzleRefreshCacheApiArg = void;
export type ApiPuzzleGetNewPuzzlesApiResponse =
  /** status 200 OK */ PuzzleMinified[];
export type ApiPuzzleGetNewPuzzlesApiArg = {
  puzzlesRequestModel: PuzzlesRequestModel;
};
export type ApiPuzzleRpApiResponse = /** status 200 OK */ object;
export type ApiPuzzleRpApiArg = {
  puzzlesRequestModel: PuzzlesRequestModel;
};
export type ApiPuzzleGetPuzzleApiResponse = /** status 200 OK */ PuzzleMinified;
export type ApiPuzzleGetPuzzleApiArg = {
  puzzlesRequestModel: PuzzlesRequestModel;
};
export type ApiPuzzleUploadPuzzleGuessApiResponse =
  /** status 200 OK */ PuzzleMinified;
export type ApiPuzzleUploadPuzzleGuessApiArg = {
  guess: Guess;
};
export type ApiPuzzleGetLatestPuzzlesUserDeviceApiResponse =
  /** status 200 OK */ object;
export type ApiPuzzleGetLatestPuzzlesUserDeviceApiArg = {
  puzzleRequestModel: PuzzleRequestModel;
};
export type ApiPuzzleGetTop10LeaderboardsApiResponse =
  /** status 200 OK */ LeaderboardsReturnModel;
export type ApiPuzzleGetTop10LeaderboardsApiArg = {
  leaderboardsModel: LeaderboardsModel;
};
export type ApiPuzzleGetLeaderboardsRanksApiResponse =
  /** status 200 OK */ LeaderboardsReturnModel;
export type ApiPuzzleGetLeaderboardsRanksApiArg = {
  leaderboardsModel: LeaderboardsModel;
};
export type ApiPuzzleGetAllTimeLeaderboardApiResponse =
  /** status 200 OK */ LeaderboardsReturnModel;
export type ApiPuzzleGetAllTimeLeaderboardApiArg = {
  leaderboardsModel: LeaderboardsModel;
};
export type ApiPuzzleGetClassroomLeaderboardApiResponse =
  /** status 200 OK */ LeaderboardsReturnModel;
export type ApiPuzzleGetClassroomLeaderboardApiArg = {
  leaderboardsModel: LeaderboardsModel;
};
export type ApiPuzzleGetWeeklyLeaderboardApiResponse =
  /** status 200 OK */ LeaderboardsReturnModel;
export type ApiPuzzleGetWeeklyLeaderboardApiArg = {
  leaderboardsModel: LeaderboardsModel;
};
export type ApiPuzzleGetDailyLeaderboardApiResponse =
  /** status 200 OK */ LeaderboardsReturnModel;
export type ApiPuzzleGetDailyLeaderboardApiArg = {
  leaderboardsModel: LeaderboardsModel;
};
export type ApiPuzzleUploadPuzzleGuessesApiResponse =
  /** status 200 OK */ object;
export type ApiPuzzleUploadPuzzleGuessesApiArg = {
  puzzleGuessModel: PuzzleGuessModel;
};
export type DeviceModel = {
  secret: string;
  DeviceId: string;
};
export type PuzzleMinified = {
  Id?: number;
  Lv?: number;
  Qu?: string;
  Qs?: string;
  Qp?: string;
  Au?: string;
  As?: string;
  A?: number;
  Aw?: string;
  Pty?: number;
  Pth?: string;
  At?: number;
  Cr?: number;
  Rv?: number;
  Sq?: string;
  Sa?: string;
  Et?: string;
  Ei?: string;
  Pdf?: string;
  Pdfc?: number;
  Pdft?: string;
};
export type PuzzlesRequestModel = {
  PuzzleId?: number;
  UserId?: string;
  LevelId?: number;
  PuzzleTypeId?: number;
  PuzzleTheme?: string;
  ShowSolved?: boolean;
  Sudoku?: boolean;
  OddEmoji?: boolean;
};
export type SolvedRevealedIds = {
  SolvedPuzzleIds?: number[];
  RevealedPuzzleIds?: number[];
  AttemptedPuzzleIds?: number[];
};
export type DeviceNewModel = {
  DeviceId?: string;
};
export type Guess = {
  PuzzleId?: number;
  AccountUserId?: string;
  PuzzleGuess?: string;
  DeviceId?: string;
};
export type PuzzleRequestModel = {
  secret: string;
  Count?: number;
  AccountUserId?: string;
  DeviceId?: string;
  Level?: number;
  Min?: number;
  ShowSolved?: boolean;
};
export type LeaderboardTop10 = {
  LeaderboardType?: number;
  UserId?: string;
  Nickname?: string;
  TotalAttempted?: number;
  TotalCorrect?: number;
  TotalScore?: number;
  RowNumber?: number;
};
export type UserLeaderboardRank = {
  Type?: number;
  TotalAttempted?: string;
  TotalCorrect?: string;
  TotalScore?: number;
  Rank?: number;
};
export type LeaderboardModel = {
  Index?: number;
  NickName?: string;
  LeaderboardId?: number;
  UserId?: string;
  EasyCorrect?: number;
  EasyAttempted?: number;
  MediumCorrect?: number;
  MediumAttempted?: number;
  HardCorrect?: number;
  HardAttempted?: number;
  ExpertCorrect?: number;
  ExpertAttempted?: number;
  MasterCorrect?: number;
  MasterAttempted?: number;
  SolutionsRevealed?: number;
  TotalAttempted?: number;
  TotalCorrect?: number;
  TotalScore?: number;
  OneCorrect?: number;
  OneAttempted?: number;
  TwoCorrect?: number;
  TwoAttempted?: number;
  ThreeCorrect?: number;
  ThreeAttempted?: number;
  FourCorrect?: number;
  FourAttempted?: number;
  FiveCorrect?: number;
  FiveAttempted?: number;
};
export type LeaderboardsReturnModel = {
  top10s?: LeaderboardTop10[];
  userRanks?: UserLeaderboardRank[];
  leaderboard?: LeaderboardModel[];
};
export type LeaderboardsModel = {
  secret: string;
  userId?: string;
  classroomId?: number;
  type?: 0 | 1 | 2;
  student?: boolean;
};
export type PuzzleGuessModel = {
  secret: string;
  guesses?: Guess[];
};
export const {
  useApiPuzzleGetPotentialScoreUploadMutation,
  useApiPuzzleUpdateUserDevicePuzzleGuessesLeaderboardsMutation,
  useApiPuzzleGetTodaysLatestPuzzlesOldMutation,
  useApiPuzzleGetTodaysLatestPuzzlesQuery,
  useApiPuzzleGetAllTodaysLatestPuzzlesQuery,
  useApiPuzzleGetAllTodaysLatestPuzzlesLatestQuery,
  useApiPuzzleGetSeasonalPuzzlesQuery,
  useApiPuzzleSetPuzzleRevealedMutation,
  useApiPuzzleGetSolvedPuzzleIdsMutation,
  useApiPuzzleGetSolvedDevicePuzzleIdsMutation,
  useApiPuzzleGetPopularPuzzlesMutation,
  useApiPuzzleRefreshCacheQuery,
  useApiPuzzleGetNewPuzzlesMutation,
  useApiPuzzleRpMutation,
  useApiPuzzleGetPuzzleMutation,
  useApiPuzzleUploadPuzzleGuessMutation,
  useApiPuzzleGetLatestPuzzlesUserDeviceMutation,
  useApiPuzzleGetTop10LeaderboardsMutation,
  useApiPuzzleGetLeaderboardsRanksMutation,
  useApiPuzzleGetAllTimeLeaderboardMutation,
  useApiPuzzleGetClassroomLeaderboardMutation,
  useApiPuzzleGetWeeklyLeaderboardMutation,
  useApiPuzzleGetDailyLeaderboardMutation,
  useApiPuzzleUploadPuzzleGuessesMutation,
} = injectedRtkApi;
