import React, { useEffect, useRef, useState } from 'react'
import { Container, NavLink } from 'reactstrap'
import { Link } from 'react-router-dom'
// import { iOS } from '../../helpers'
import juniorLogo from '../../assets/junior-stream.svg'
import nextLevelLogo from '../../assets/next-level-stream-3.svg'
import gettingStarted from '../../assets/getting-started-cloud.svg'
import onFire from '../../assets/youre-on-fire-bomb.svg'
import Filter from '../puzzle/Filter'
import ImageLoader from '../misc/ImageLoaderIntro'
import { useResizeObserver } from './useResizeObserver'
import { LevelColours } from '../../constants'
// @ts-ignore
import LazyLoad from 'react-lazy-load'
import Highlight from '../Highlight'

const imageStart = 'https://solvemojiimages.azureedge.net/solvemoji/latest/'
var date = new Date()
var cache = date.toISOString().substring(0, 10)

const updateLevels = ([jlt, jl, nlt, nl]: number[]): number[] => {
  const juniorLevelType = Math.random() > 0.5 ? 1 : 4
  const juniorLevel = Math.ceil(Math.random() * 5) + 5
  const nextLevel = Math.ceil(Math.random() * 2)
  const nextLevelType = Math.ceil(Math.random() * 4)

  if(juniorLevelType === jlt || nextLevelType === nlt || juniorLevel === jl || nextLevel === nl){
    return updateLevels([jlt, jl, nlt, nl])
  }

  return [juniorLevelType, juniorLevel, nextLevelType, nextLevel]
}

const Intro = (props: any) => {
  const ref = useRef(null)
  const [introLevels, setIntroLevels] = useState(JSON.parse(localStorage.getItem('introLevels') || '[4, 6, 1, 1]'))

  useEffect(() => {
    const interval = window.setInterval(() => {
      setIntroLevels((prev: any) => updateLevels(prev))
    }, 5000)

    return () => {
      window.clearInterval(interval);
    }
}, [])

  // Access the width and the height returned from the observed element.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, height] = useResizeObserver(ref)
  const [juniorLevelType, juniorLevel, nextLevelType, nextLevel] = introLevels

  return (
    <div className='home__jumbotron theme-dark intro'>
      <Container>
        <div className='row'>
          <div className='col col-2 col-md-3 smallHide'>
            <div style={{
              width: `${height}px`,
              position: 'absolute',
              right: 0
            }}>
              <div className={`text__image-left solvemoji-square solvemoji-blur`} style={{ background: LevelColours[juniorLevel] }}><div></div></div>
            </div>
            <LazyLoad debounce={false} offsetVertical={500}>
              <ImageLoader
                src={`${imageStart}${juniorLevelType}_${juniorLevel}.svg?v=${cache}`}
                level={juniorLevel}
                alt={`puzzleId_${juniorLevel.PuzzleId}`}
                className='text__image-left'
              />
            </LazyLoad>
          </div>
          <div className='col col-8 col-md-6 text__header-icon'>
            <p>
              <Highlight text="Emoji maths puzzles" /><br />Loved by students, parents & teachers<br />Great for
              home schooling
            </p>
            <p className='mobileHide'>
              <Highlight text="10 levels" /> of difficulty – Choose from Classic, Grid, Word or Emojidoku (Sudoku) types,{' '}
              <NavLink tag={Link} to={'/register'} className="inline">
                <Highlight text="Register" />
              </NavLink>{' '}with us today!
            </p>
            <p className='tinyHide'>
              Algebra has never been so much fun!
              <br />Check out our <NavLink tag={Link} to={'/worksheets'} className="inline">
                <Highlight text="Worksheets ✏️" />{' '}
              </NavLink> <NavLink tag={Link} to={'/leaderboard'} className="inline">
                <Highlight text="Leaderboards 🏆" />
              </NavLink> and <NavLink tag={Link} to={'/books'} className="inline">
                <Highlight text="Books 📚" />
              </NavLink>
            </p>
            <p className='mobileHide'>
              <Highlight text="40" /> new puzzles added daily, play up to <NavLink tag={Link} to={'/today'} className="inline">
                <Highlight text="3 FREE" />
              </NavLink>{' '}every day, <NavLink tag={Link} to={'/pricing'} className="inline">
                <Highlight text="Subscribe" />
              </NavLink>{' '}to play all puzzles without restrictions</p>
          </div>
          <div className='col col-2 col-md-3' ref={ref}>
            <div style={{
              width: `${height}px`,
              position: 'absolute',
              left: 0
            }}>
              <div className={`text__image-right solvemoji-square solvemoji-blur`} style={{ background: LevelColours[nextLevel] }}><div></div></div>
            </div>
            <ImageLoader
              src={`${imageStart}${nextLevelType}_${nextLevel}.svg?v=${cache}`}
              level={nextLevel}
              alt={`puzzleId_${nextLevelType.PuzzleId}`}
              className='text__image-right'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col col-12 col-sm-6 '>
            <Filter
              easy={true}
              logo={juniorLogo}
              labels={['1', '2', '3', '4', '5']}
              colours={[
                'rgb(133, 224, 102)',
                'rgb(255, 224, 102)',
                'rgb(255, 102, 133)',
                'rgb(126, 126, 126)',
                'rgb(102, 102, 255)',
              ]}
              size={0.2}
              margin='-8%'
              onClick={(position: number) => props.goToLevel(position, true)}
            />
            <img
              alt='gettingStarted'
              className='filter__subIcon-left smallShow'
              src={gettingStarted}
            />
          </div>
          <div className='col col-12 col-sm-6 '>
            <Filter
              logo={nextLevelLogo}
              labels={['EASY', 'MEDIUM', 'HARD', 'EXPERT', 'MASTER']}
              colours={[
                'rgb(124, 176, 66)',
                'rgb(253, 203, 46)',
                'rgb(187, 41, 20)',
                'rgb(0, 0, 0)',
                'rgb(0, 49, 177)',
              ]}
              size={0.4}
              margin='11%'
              onClick={(position: number) => props.goToLevel(position)}
            />
            <img
              alt='onFire'
              className='filter__subIcon-right smallShow'
              src={onFire}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col col-3 smallHide'>
            <div style={{
              background: `url(${gettingStarted})`,
              paddingTop: 'calc(150/208 * 100%)'
            }}
              className="gettingStarted" />
          </div>
          <div className='col col-12 col-sm-6 text__header-large'>
            <p>
              The harder the level, the more points you will earn, start
              collecting points now!
            </p>
          </div>
          <div className='col col-3 smallHide'>
            <div style={{
              background: `url(${onFire})`,
              paddingTop: 'calc(183/255 * 100%)'
            }}
              className="onFire" />
          </div>
        </div>
      </Container >
    </div >
  )
}

export default Intro
