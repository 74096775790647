import React from 'react'
import instagram from '../assets/instagram.png'
import pinterest from '../assets/pinterest.png'


export default function Social() {
  const openWindow = (page: string) => {
    window.open(`https://www.${page}.com/solvemoji`, '_blank')
  }

  return (
    <div className='social'>
      <div className='social__header'>Follow us</div>
      <div className='social__icons'>
        <div className='--group'>
          <img src={instagram} alt='instagram' onClick={() => openWindow('instagram')} className='icon-instagram ig' />
          <img src={pinterest} alt='pinterest' onClick={() => openWindow('pinterest')} className='icon-instagram ig' />
        </div>
      </div>
    </div>
  )
}
