import * as React from 'react';
// @ts-ignore
import Lottie from 'react-lottie-light';
import * as confetti from '../../assets/annimations/confeffi.json';

export default class LottieConfetti extends React.Component {
  render() {
    const defaultOptions2 = {
      loop: false,
      autoplay: true,
      // @ts-ignore
      animationData: confetti.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return (
      <div
        style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
      >
        <Lottie options={defaultOptions2} />
      </div>
    );
  }
}
