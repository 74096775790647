// Run the following command to refresh the RTKQ apis:
// npx @rtk-query/codegen-openapi openapi-config.js

import { baseApi } from './generated/baseApi'

export const enhancedSolvemojiApi = baseApi.enhanceEndpoints({
  addTagTypes: ['orders', 'students', 'classrooms', 'settings', 'puzzleLists', 'pdfWorksheetUrl'],
  endpoints: {
    checkoutGetOrders: { providesTags: ['orders'] },
    checkoutGetSubscriptions: { invalidatesTags: ['orders'] },
    studentGetStudent: { providesTags: ['students'] },
    studentGetStudents: { providesTags: ['students'] },
    studentRegisterStudent: { invalidatesTags: ['students'] },
    classroomGetClassrooms: { providesTags: ['classrooms'] },
    classroomGetClassroom: { providesTags: ['classrooms'] },
    classroomCreateClassrooms: { invalidatesTags: ['classrooms'] },
    classroomUpdateClassrooms: { invalidatesTags: ['classrooms'] },
    settingGetSettings: { providesTags: ['settings'] },
    settingUpdateSettings: { invalidatesTags: ['settings'] },
    apiPuzzleGetNewPuzzles: { providesTags: ['puzzleLists'] },
    apiPuzzleGetTodaysLatestPuzzles: { providesTags: ['puzzleLists'] },
    apiPuzzleGetAllTodaysLatestPuzzles: { providesTags: ['puzzleLists'] },
    apiPuzzleGetPopularPuzzles: { providesTags: ['puzzleLists'] },   
    apiPuzzleGetLeaderboardsRanks: { providesTags: ['puzzleLists'] },   
    pdfGetWorksheetUrl: { providesTags: ['pdfWorksheetUrl'] },   
  }
})