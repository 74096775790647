import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import * as PuzzleStore from '../../store/Puzzle';
import ColouredTitle from '../misc/ColouredTitle';
import CurrentRank from '../home/CurrentRank';
import { getImage } from '../../helpers';
import history from '../../history';

// @ts-ignore
var ReactFitText = require('react-fittext');

interface IPuzzleActions {
  puzzleActions: typeof PuzzleStore.actionCreators
}

type PuzzleProps = PuzzleStore.PuzzleState &
  IPuzzleActions &
  RouteComponentProps<{}>;

interface MyRankingsInterface {
  fullScreen?: boolean;
}

class MyRankings extends React.PureComponent<PuzzleProps> {
  redirect = (route: string) => {
    history.push(route);
    this.props.puzzleActions.doShowCompleted(false);
  };

  render() {
    const { userRanks } = this.props;
    const getLeaderboardIcon = getImage;

    return (
      <div className='m-auto myRankings__title'>
        {userRanks && userRanks.length === 3 && (
          <div className='mb-30'>
            <div className='puzzleComplete__text'>
              <ReactFitText compressor={1.8}>
                <ColouredTitle title='My Leaderboard Positions' />
              </ReactFitText>
            </div>
            <div className='puzzleComplete__leadboardRanks'>
              <div className='puzzleComplete__leadboardRanks-item'>
                <div className='puzzleComplete__leadboardRanks-title'>
                  Daily
                </div>
                <div
                  className='puzzleComplete__leadboardRanks-rank'
                  onClick={() => this.redirect('/leaderboardDaily')}
                >
                  <img
                    src={require(`../../assets/animals/${getLeaderboardIcon(
                      userRanks[2].Rank - 1,
                      userRanks[2].TotalScore
                    )}.svg`)}
                    alt='rank'
                  />
                  {userRanks[2].Rank < 4 ? (
                    <img
                      src={require(`../../assets/animals/Medal-${userRanks[2].Rank}.svg`)}
                      alt='rank'
                    />
                  ) : userRanks[2].Rank}
                </div>
              </div>
              <div className='puzzleComplete__leadboardRanks-item'>
                <div className='puzzleComplete__leadboardRanks-title'>
                  Weekly
                </div>
                <div
                  className='puzzleComplete__leadboardRanks-rank'
                  onClick={() => this.redirect('/leaderboardWeekly')}
                >
                  <img
                    src={require(`../../assets/animals/${getLeaderboardIcon(
                      userRanks[1].Rank - 1,
                      userRanks[1].TotalScore
                    )}.svg`)}
                    alt='rank'
                  />
                  {userRanks[1].Rank < 4 ? (
                    <img
                      src={require(`../../assets/animals/Medal-${userRanks[1].Rank}.svg`)}
                      alt='rank'
                    />
                  ) : userRanks[1].Rank}
                </div>
              </div>
              <div className='puzzleComplete__leadboardRanks-item'>
                <div className='puzzleComplete__leadboardRanks-title'>
                  All Time
                </div>
                <div
                  className='puzzleComplete__leadboardRanks-rank'
                  onClick={() => this.redirect('/leaderboard')}
                >
                  <img
                    src={require(`../../assets/animals/${getLeaderboardIcon(
                      userRanks[0].Rank - 1,
                      userRanks[0].TotalScore
                    )}.svg`)}
                    alt='rank'
                  />
                  {userRanks[0].Rank < 4 ? (
                    <img
                      src={require(`../../assets/animals/Medal-${userRanks[0].Rank}.svg`)}
                      alt='rank'
                    />
                  ) : userRanks[0].Rank}
                </div>
              </div>
            </div>
          </div>
        )}
        <CurrentRank />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch: any, ownProps: MyRankingsInterface) {
  return {
    puzzleActions: bindActionCreators(PuzzleStore.actionCreators, dispatch),
    ownProps,
  };
}

export default connect(
  (state: ApplicationState) => state.puzzleStore, // Selects which state properties are merged into the component's props
  mapDispatchToProps // Selects which action creators are merged into the component's props
)(MyRankings as any);
