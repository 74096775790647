import * as React from 'react';
import ColouredTitle from '../misc/ColouredTitle';
import history from '../../history';

// @ts-ignore
var ReactFitText = require('react-fittext');

interface ILoginOrRegisterProps {
    closeModal: (show: boolean) => void
}

export default function LoginOrRegister(props: ILoginOrRegisterProps) {
    const handleLogin = () => {
        history.push(`/login?redirectUrl=myAccount`);
    };

    const handleRegister = () => {
        history.push(`/register?redirectUrl=myAccount`);
    };

    return (
        <div className='puzzleComplete' >
            <div className='row'>
                <div className='col-12 col-sm-1 col-md-2 col-lg-3'></div>
                <div className='col-12 col-sm-10 col-md-8 col-lg-6'>
                    <div className='message'>
                        <div className='modal__close' onClick={() => props.closeModal(false)}>
                            <i className='icon-times'></i>
                        </div>
                        <div className='row'>
                            <div className='col col-12 text-center'>
                                <ReactFitText compressor={1.1}>
                                    <ColouredTitle title='LOGIN OR REGISTER' />
                                </ReactFitText>

                                <>
                                    <div className='puzzleComplete__text'>
                                        Please first login or register with Solvemoji to subscribe to one of our plans
                                    </div>
                                    <div className='row mt-20'>
                                        <div className='col'>
                                            <button
                                                className='btn btn-lg puzzleComplete__button-login mb-10'
                                                onClick={handleLogin}
                                            >
                                                <i className='icon-sign-in mr-10'></i>
                                                <span>Login</span>
                                            </button>
                                        </div>
                                        <div className='col'>
                                            <button
                                                className='btn btn-lg puzzleComplete__button-register  mb-10'
                                                onClick={handleRegister}
                                            >
                                                <i className='icon-user-plus mr-10'></i>
                                                <span>Register</span>
                                            </button>
                                        </div>
                                        <div className='col'>
                                            <button
                                                className='btn btn-lg puzzleComplete__button-no  mb-10'
                                                onClick={() => props.closeModal(false)}
                                            >
                                                <i className='icon-thumbs-o-down mr-10'></i>
                                                <span>Cancel</span>
                                            </button>
                                        </div>
                                    </div>
                                </>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-sm-1 col-md-2 col-lg-3'></div>
            </div>
        </div>
    );
}