import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { reducers } from './'
import { configureStore } from '@reduxjs/toolkit'
import { enhancedSolvemojiApi } from './enhancedSolvemojiApi'

export default function confStore(history: History) {
  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history)
  })

  const middleware = (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    })
      .concat(enhancedSolvemojiApi.middleware)

  return configureStore({
    reducer: rootReducer,
    middleware
  })
}
