import * as React from 'react';
// @ts-ignore
import Lottie from 'react-lottie-light';
import * as ani16 from '../../assets/annimations/trophy.json';
export default class LottieSuccess extends React.Component {
  state = {
    // @ts-ignore
    annimation: ani16.default,
  };

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      // @ts-ignore
      animationData: this.state.annimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return (
      <div className={'puzzleComplete__trophy'}>
        <Lottie options={defaultOptions} />
      </div>
    );
  }
}
