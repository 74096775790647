import * as React from "react";
import { connect } from "react-redux";

interface SectionHeaderProps {
  title: string;
  light?: boolean;
}

const SectionHeader = (props: SectionHeaderProps) => {
  return (
    <div className={`section__header${props.light ? '-light' : ''}`}>
      <div className="icon-angle-double-down section__icon"></div>
      <div>{props.title}</div>
      <div className="icon-angle-double-down section__icon"></div>
    </div>
  );
};

export default connect()(SectionHeader);
