import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga4'

class GoogleAnalytics extends React.Component {
  componentWillUpdate({ location, history }) {

    if (location.pathname === this.props.location.pathname) {
      // don't log identical link clicks (nav links likely)
      return;
    }
    
    window.setTimeout(() => {
      ReactGA.send({ hitType: "pageview", page: location.pathname, title: window.document.title });
    }, 300)
  }

  render() {
    return null;
  }
}

export default withRouter(GoogleAnalytics);
