import { Action, Reducer } from 'redux'
import { AppThunkAction } from './'
import { SecretKey, EndPoint } from '../constants'
import { ISessionToken } from '../interfaces/ISessionToken'
import { IUserDetails } from '../interfaces/IUserDetails'
import IExternalLogin from '../interfaces/IExternalLogin'
import IStat from '../interfaces/IStat'
import { ClearPuzzles, actionCreators as puzzleActionCreators } from './Puzzle'
import history from '../history'

export interface AccountState {
  session_token?: ISessionToken
  user_details?: IUserDetails
  login_error?: string
  register_error?: string
  showSetNickname: boolean
  externalLogins: IExternalLogin[]
  stats?: IStat
}

export interface SetNickname {
  type: 'SET_NICKNAME'
  data: any
}

export interface SetSessionToken {
  type: 'SET_SESSION_TOKEN'
  data: any
}

export interface SetLoginError {
  type: 'SET_LOGIN_ERROR'
  data: string
}

export interface SetRegisterError {
  type: 'SET_REGISTER_ERROR'
  data: string
}

export interface SetShowNickname {
  type: 'SHOW_NICKNAME'
  data: boolean
}

export interface SetExternalLogins {
  type: 'SET_EXTERNAL_LOGINS'
  data: IExternalLogin[]
}

export interface SetSolvemojiStats {
  type: 'SET_SOLVEMOJI_STATS'
  data: IStat
}

export type KnownAction =
  | SetSessionToken
  | SetLoginError
  | ClearPuzzles
  | SetNickname
  | SetShowNickname
  | SetRegisterError
  | SetExternalLogins
  | SetSolvemojiStats

export const actionCreators = {
  setNickname:
    (nickname: string): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        try {
          const { accountStore } = getState()
          if (accountStore) {
            const { session_token, user_details } = accountStore
            if (session_token && user_details) {
              const result1 = await fetch(`${EndPoint}/api/account/SetNickname`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                  Authorization: `Bearer ${session_token.access_token}`
                },
                body: JSON.stringify({
                  Nickname: nickname
                })
              })

              await result1.json()

              dispatch({ type: 'SET_NICKNAME', data: nickname })
            }
          }
        } catch (err) {
          console.error('Login Error', err)
          dispatch({
            // TODO - Make generic error message
            type: 'SET_LOGIN_ERROR',
            data: err as string
          })
        }
      },

  forgottenPassword:
    (emailAddress: string): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        try {
          const { accountStore } = getState()
          if (accountStore) {
            const result1 = await fetch(
              `${EndPoint}/api/account/ForgottenPassword`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json'
                },
                body: JSON.stringify({
                  Email: emailAddress
                })
              }
            )

            await result1.text()

            history.push('/forgottenpasswordconfirmation')
          }
        } catch (err) {
          console.error('Login Error', err)
          dispatch({
            // TODO - Make generic error message
            type: 'SET_LOGIN_ERROR',
            data: err as string
          })
        }
      },

  changePassword:
    (
      OldPassword: string,
      NewPassword: string,
      ConfirmPassword: string
    ): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        try {
          const { accountStore } = getState()
          if (accountStore) {
            const { session_token, user_details } = accountStore
            if (session_token && user_details) {
              const result1 = await fetch(
                `${EndPoint}/api/account/ChangePasswordApi`,
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${session_token.access_token}`
                  },
                  body: JSON.stringify({
                    OldPassword,
                    NewPassword,
                    ConfirmPassword
                  })
                }
              )

              var result = await result1.json()

              if (
                result.Message &&
                result.Message === 'The request is invalid.'
              ) {
                if (result.ModelState && result.ModelState.error) {
                  throw result.ModelState.error[0]
                } else {
                  throw result.Message
                }
              } else {
                // @ts-ignore
                await dispatch(
                  // @ts-ignore
                  actionCreators.doLogin(
                    user_details.EmailAddress,
                    NewPassword,
                    true
                  )
                )

                history.push('/')
              }
            }
          }
        } catch (err) {
          console.error('Reset Error', err)
          throw err
        }
      },

  resetPassword:
    (
      emailAddress: string,
      password: string,
      confirmPassword: string,
      code: string
    ): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        try {
          const { accountStore } = getState()
          if (accountStore) {
            const result1 = await fetch(`${EndPoint}/api/account/resetPassword`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
              },
              body: JSON.stringify({
                Email: emailAddress,
                Password: password,
                ConfirmPassword: confirmPassword,
                Code: code
              })
            })

            await result1.text()

            // @ts-ignore
            await dispatch(actionCreators.doLogin(emailAddress, password, true))

            history.push('/forgottenpasswordcomplete')
          }
        } catch (err) {
          console.error('Login Error', err)
          dispatch({
            // TODO - Make generic error message
            type: 'SET_LOGIN_ERROR',
            data: err as string
          })
        }
      },

  setStats:
    (stats: IStat): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        try {
          dispatch({
            type: 'SET_SOLVEMOJI_STATS',
            data: stats
          })
        } catch (err) {
          console.error('Stats Error', err)
        }
      },

  doLogin:
    (
      username: string,
      password: string,
      rememberMeChecked: boolean
    ): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        try {
          dispatch({
            type: 'SET_LOGIN_ERROR',
            data: ''
          })

          let uName = username
          const url = `${EndPoint}/token`

          // if the username contains an @ then check for the username incase of email
          if (username.indexOf('@') > 0) {
            const url = `${EndPoint}/api/account/GetUsernameFromEmail`
            const objectP = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                secret: SecretKey,
                email: username
              })
            }

            const result = await fetch(url, objectP)
            const json = await result.json()

            if (json.username && username !== json.username) {
              ({ username: uName } = json)
            }
          }

          const objectP = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: `grant_type=password&username=${uName}&password=${password}`
          }

          const result = await fetch(url, objectP)
          const json = await result.json()

          if (rememberMeChecked) {
            localStorage.setItem('Solvemoji_Auth', btoa(JSON.stringify(json)))
          } else {
            localStorage.removeItem('Solvemoji_Auth')
          }

          await doLoginWithAuthToken(json, dispatch, true)
        } catch (err) {
          console.error('Login Error', err)
          dispatch({
            type: 'SET_LOGIN_ERROR',
            data: err as string
          })
        }
      },

  doMagicLinkLogin:
    (
      json: any
    ): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        try {
          clearSession(dispatch, false)
          localStorage.setItem('Solvemoji_Auth', btoa(JSON.stringify(json)))
          await doLoginWithAuthToken(json, dispatch, true)
        } catch (err) {
          console.error('Login Error', err)
          dispatch({
            type: 'SET_LOGIN_ERROR',
            data: err as string
          })
        }
      },

  externalLogin:
    (json: any): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        try {
          dispatch({
            type: 'SET_LOGIN_ERROR',
            data: ''
          })

          localStorage.setItem('Solvemoji_Auth', btoa(JSON.stringify(json)))

          await doLoginWithAuthToken(json, dispatch, true)
        } catch (err) {
          console.error('Login Error', err)
          dispatch({
            type: 'SET_LOGIN_ERROR',
            data: err as string
          })
        }
      },

  getExternalLogins:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      try {
        const url = `${EndPoint}/api/Account/ExternalLogins?returnUrl=%2FHome%2FExternalLoginWeb&generateState=true`

        const objectP = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }

        const result = await fetch(url, objectP)
        const json = await result.json()

        dispatch({
          type: 'SET_EXTERNAL_LOGINS',
          data: json
        })
      } catch (err) {
        console.error('Login Error', err)
      }
    },

  refreshAuth:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      try {
        dispatch({
          type: 'SET_LOGIN_ERROR',
          data: ''
        })

        const solvemojiAuth = localStorage.getItem('Solvemoji_Auth')

        if (solvemojiAuth) {
          const json = JSON.parse(atob(solvemojiAuth))
          await doLoginWithAuthToken(json, dispatch, false)
        }
      } catch (err) {
        console.error('Login Error', err)
      }
    },

  registerStudent:
    (
      Email: string,
      Nickname: string,
      Password: string,
      ConfirmPassword: string
    ): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({
          type: 'SET_REGISTER_ERROR',
          data: ''
        })

        const { accountStore } = getState()
        if (accountStore) {
          const { session_token, user_details } = accountStore
          if (session_token && user_details) {
            const result = await fetch(
              `${EndPoint}/api/account/RegisterStudent`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                  Authorization: `Bearer ${session_token.access_token}`
                },
                body: JSON.stringify({
                  Email,
                  Nickname,
                  Password,
                  ConfirmPassword
                })
              }
            )

            const json = await result.json()
            if (json && json.ModelState && json.ModelState.error) {
              dispatch({
                type: 'SET_REGISTER_ERROR',
                data: json.ModelState.error.pop()
              })
            }
          }
        }
      },

  registerUser:
    (
      Email: string,
      Nickname: string,
      Password: string,
      ConfirmPassword: string
    ): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({
          type: 'SET_REGISTER_ERROR',
          data: ''
        })

        const url = `${EndPoint}/api/account/Register`
        const objectP = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            Email,
            Nickname,
            Password,
            ConfirmPassword
          })
        }

        const result = await fetch(url, objectP)
        const json = await result.json()
        if (json && json.ModelState && json.ModelState.error) {
          dispatch({
            type: 'SET_REGISTER_ERROR',
            data: json.ModelState.error.pop()
          })
        } else {
          // @ts-ignore
          await dispatch(actionCreators.doLogin(Email, Password, true))
        }
      },

  doShowNickname:
    (show: boolean): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({ type: 'SHOW_NICKNAME', data: show })
      },

  logOut: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    try {
      clearSession(dispatch)
      history.push('/')
    } catch (err) {
      console.error('Login Error', err)
    }
  }
}

const doLoginWithAuthToken = async (json: any, dispatch: any, redirect: boolean) => {
  const url1 = `${EndPoint}/api/Account/UserInfo`
  const objectP1 = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${json.access_token}`
    }
  }

  const result1 = await fetch(url1, objectP1)
  const json1 = await result1.json()
  if (json && json.error) {
    dispatch({
      type: 'SET_LOGIN_ERROR',
      data: json.error_description
    })
  } else {
    localStorage.setItem('SolvemojiSession', JSON.stringify(json))

    // clear any existing puzzles in cache
    dispatch({ type: 'CLEAR_PUZZLES' })

    // set account details
    dispatch({
      type: 'SET_SESSION_TOKEN',
      data: { session_token: json, user_details: json1 }
    })

    // @ts-ignore
    dispatch(puzzleActionCreators.getSolvedPuzzleIds())
    
    if (redirect) {
      const params = new URLSearchParams(window.location.search)
      const redirectUrl = params.get('redirectUrl')
      history.push(`/${redirectUrl || ''}`)
    }
  }
}

function clearSession(dispatch: (action: KnownAction) => void, reload = true) {
  dispatch({
    type: 'SET_LOGIN_ERROR',
    data: ''
  })

  localStorage.removeItem('Solvemoji_Auth')
  localStorage.removeItem('SolvemojiSession')
  localStorage.removeItem('activeProduct')
  
  // clear any existing puzzles in cache
  dispatch({ type: 'CLEAR_PUZZLES' })

  // set account details
  dispatch({
    type: 'SET_SESSION_TOKEN',
    data: { session_token: undefined, user_details: undefined }
  })

  if(reload){
    window.location.reload();
  }
}

export const reducer: Reducer<AccountState> = (
  state: AccountState | undefined,
  incomingAction: Action
): AccountState => {
  if (state === undefined) {
    return {
      login_error: '',
      showSetNickname: false,
      externalLogins: []
    }
  }

  const action = incomingAction as KnownAction
  switch (action.type) {
    case 'SET_EXTERNAL_LOGINS': {
      return {
        ...state,
        externalLogins: action.data
      }
    }

    case 'SET_SESSION_TOKEN': {
      return {
        ...state,
        session_token: action.data.session_token,
        user_details: action.data.user_details
      }
    }

    case 'SHOW_NICKNAME': {
      return {
        ...state,
        showSetNickname: action.data
      }
    }

    case 'SET_NICKNAME': {
      if (!state.user_details) {
        return state
      }

      const newUserDetails = { ...state.user_details }
      newUserDetails.Nickname = action.data

      return {
        ...state,
        user_details: newUserDetails
      }
    }

    case 'SET_LOGIN_ERROR': {
      return {
        ...state,
        login_error: action.data,
        register_error: ''
      }
    }

    case 'SET_REGISTER_ERROR': {
      return {
        ...state,
        register_error: action.data,
        login_error: ''
      }
    }

    case 'SET_SOLVEMOJI_STATS': {
      return {
        ...state,
        stats: action.data
      }
    }

    default:
      return state
  }
}
