import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import * as PuzzleStore from '../../store/Puzzle';
import * as AccountStore from '../../store/Account';

import { getImage, rangeNames } from '../../helpers';
import { titleColours } from '../../constants';

type CurrentRankProps = {
  puzzleStore: PuzzleStore.PuzzleState;
  accountStore: AccountStore.AccountState;
} & typeof PuzzleStore.actionCreators &
  RouteComponentProps<{}>;

class CurrentRank extends React.PureComponent<CurrentRankProps> {
  hideComplete = () => {
    this.props.doShowCompleted(false);
  };

  render() {
    const { userRanks } = this.props.puzzleStore;
    const getLeaderboardIcon = getImage;
    const rangeNames1 = rangeNames;

    let number =
      userRanks.length === 3
        ? getLeaderboardIcon(userRanks[0].Rank - 1, userRanks[0].TotalScore)
        : 0;

    if (!userRanks || userRanks.length !== 3) {
      return null;
    }

    return (
      <NavLink tag={Link} to={'/leaderboardRanks'} onClick={this.hideComplete}>
        <div>
          <div className='leaderboardRank'>
            <div>
              <div
                className='leaderboardRank__rankImg'
                style={{ borderColor: titleColours[number % 6] }}
              >
                <img
                  style={{ borderColor: titleColours[number % 6] }}
                  src={require(`../../assets/animals/${number}.svg`)}
                  alt='rank'
                />
              </div>
              <div
                className='leaderboardRank__rankText'
                style={{
                  backgroundColor: titleColours[number % 6],
                  lineHeight: '60px',
                }}
              >
                <div>Rank {number + 1} of 80</div>
                <div>{rangeNames1[number]}</div>
              </div>
            </div>
          </div>
        </div>
      </NavLink>
    );
  }
}

export default connect(
  (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
  PuzzleStore.actionCreators // Selects which action creators are merged into the component's props
)(CurrentRank as any);
