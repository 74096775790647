import { EndPoint } from './constants'
import { IPuzzle } from './interfaces/IPuzzle'
import { PuzzleMinified } from './store/generated/puzzlesApi'
import html2canvas from 'html2canvas'
import { useEffect } from 'react'
export function shuffle(array: any) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

export function doScrollTo(element: any, to = 0, duration = 1000) {
  const start = element.scrollTop
  const change = to - start
  const increment = 20
  let currentTime = 0

  const animateScroll = () => {
    currentTime += increment

    const val = easeInOutQuad(currentTime, start, change, duration)

    element.scrollTop = val

    if (currentTime < duration) {
      setTimeout(animateScroll, increment)
    }
  }

  animateScroll()
}

function easeInOutQuad(t: any, b: any, c: any, d: any) {
  t /= d / 2
  if (t < 1) return (c / 2) * t * t + b
  t--
  return (-c / 2) * (t * (t - 2) - 1) + b
}

export function scrollTo(
  elementId: string,
  time: number = 600,
  offset: number = 0
) {
  const scrollToElement: HTMLElement | null = document.querySelector(elementId)

  if (scrollToElement != null) {
    doScrollTo(
      document.querySelector('html, body'),
      window.scrollY + scrollToElement.getBoundingClientRect().top - offset,
      time
    )
  }
}

export function shuffleArray(array: any) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]
  }
}

export const getVideoId = (LevelId?: number | string | null) => {
  if(!LevelId){
    return 'dy3R23vqLNQ'
  }

  return Number(LevelId) > 5 ?
  '2vF7kSsyDUg' :
  Number(LevelId) < 3 ?
    'joj28GUEznU' : 'OiRtmymfGek'
}


export const GetNewPuzzles = async (
  userId?: string,
  levelId?: number,
  puzzleTypeId?: number,
  puzzleTheme?: string,
  showCompleted?: boolean
) => {
  const response = await fetch(`${EndPoint}/api/Puzzle/getNewPuzzles`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify({
      userId,
      levelId,
      puzzleTypeId,
      puzzleTheme,
      showSolved: showCompleted,
      sudoku: true
    }),
  })
  const json: any = await response.json()
  return MapMinifiedPuzzle(json)
}

export const MapMinifiedPuzzle = (minified: PuzzleMinified[]): IPuzzle[] => {
  const parsed = ((typeof minified === 'string') ? JSON.parse(minified) : minified) as PuzzleMinified[]

  // @ts-ignore
  const mapped: IPuzzle[] = parsed.map((x) => ({
    PuzzleId: x.Id,
    LevelId: x.Lv,
    QuestionImageUrl: x.Qu,
    QuestionImageSvg: x.Qs,
    QuestionImagePreview: x.Qp,
    AnswerImageUrl: x.Au,
    AnswerImageSvg: x.As,
    Answer: x.A,
    AnswerWord: x.Aw,
    PuzzleTypeId: x.Pty,
    PuzzleTheme: x.Pth,
    Attempted: x.At,
    Correct: x.Cr,
    Revealed: x.Rv,
    SudokuQuestion: x.Sq,
    SudokuAnswer: x.Sa,
    EmojiTheme: x.Et,
    EmojiIcons: x.Ei,
    PdfThumbnail: x.Pdft
  }))

  return mapped
}

export const MapMinifiedPuz = (x: PuzzleMinified): IPuzzle => {
  return {
    PuzzleId: x.Id,
    LevelId: x.Lv,
    QuestionImageUrl: x.Qu,
    QuestionImageSvg: x.Qs,
    QuestionImagePreview: x.Qp,
    AnswerImageUrl: x.Au,
    AnswerImageSvg: x.As,
    Answer: x.A,
    AnswerWord: x.Aw,
    PuzzleTypeId: x.Pty,
    PuzzleTheme: x.Pth,
    Attempted: x.At,
    Correct: x.Cr,
    Revealed: x.Rv,
    SudokuQuestion: x.Sq,
    SudokuAnswer: x.Sa,
    EmojiTheme: x.Et,
    EmojiIcons: x.Ei,
    PdfThumbnail: x.Pdft
  } as IPuzzle
}

export const sortByBoolVal = (strProp: string, asc: boolean) => (
  a: any,
  b: any
) =>
  a[strProp] === b[strProp]
    ? 0
    : a[strProp] && !b[strProp]
      ? asc
        ? 1
        : -1
      : asc
        ? -1
        : 1

export const sortByNumberVal = (strProp: string, asc: boolean) => (
  a: any,
  b: any
) =>
  a[strProp] === b[strProp]
    ? 0
    : a[strProp] > b[strProp]
      ? asc
        ? 1
        : -1
      : asc
        ? -1
        : 1

export const isInViewport = function (elem: any) {
  const bounding = elem.getBoundingClientRect()
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
    (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
    (window.innerWidth || document.documentElement.clientWidth)
  )
}

export function iOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export const isMobile = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true
    // @ts-ignore
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}

export const ranges = [
  0,
  100,
  200,
  300,
  400,
  500,
  600,
  700,
  800,
  900,
  1000,
  1200,
  1500,
  1800,
  2000,
  2500,
  3000,
  3500,
  4000,
  5000,
  6000,
  7000,
  8000,
  9000,
  10000,
  12000,
  14000,
  16000,
  18000,
  20000,
  23000,
  26000,
  29000,
  32000,
  35000,
  40000,
  45000,
  50000,
  55000,
  60000,
  65000,
  70000,
  75000,
  80000,
  85000,
  90000,
  95000,
  100000,
  105000,
  110000,
  115000,
  120000,
  140000,
  160000,
  180000,
  200000,
  300000,
  400000,
  500000,
  600000,
  700000,
  800000,
  900000,
  1000000,
  1100000,
  1300000,
  1500000,
  2000000,
  2500000,
  3000000,
  3500000,
  4000000,
  4500000,
  5000000,
  6000000,
  7000000,
  8000000,
  9000000,
  10000000,
]

export const rangeNames = [
  'Ant',
  'Spider',
  'Grasshopper',
  'Beatle',
  'Ladybird',
  'Bumble Bee',
  'Snail',
  'Caterpillar',
  'Butterfly',
  'Chick',
  'Mouse',
  'Rat',
  'Hedgehog',
  'Squirrel',
  'Crab',
  'Lobster',
  'Squid',
  'Fish',
  'Tropical Fish',
  'Lizard',
  'Snake',
  'Tortoise',
  'Bat',
  'Bird',
  'Dove',
  'Duck',
  'Owl',
  'Cockrill',
  'Turkey',
  'Otter',
  'Skunk',
  'Badger',
  'Sheep',
  'Pig',
  'Cow',
  'Rabbit',
  'Flamingo',
  'Peacock',
  'Swan',
  'Parrot',
  'Cat',
  'Dog',
  'Horse',
  'Llama',
  'Deer',
  'Bison',
  'Camel',
  'Monkey',
  'Sloth',
  'Giraffe',
  'Zebra',
  'Penguin',
  'Seal',
  'Eagle',
  'Kangaroo',
  'Orangutang',
  'Crocodile',
  'Hippo',
  'Gorilla',
  'Elephant',
  'Leopard',
  'Tiger',
  'Dolphin',
  'Shark',
  'Whale',
  'Woolly Mammoth',
  'Diplodocus',
  'T-Rex',
  'Dragon',
  'Monster',
  'Robot',
  'Alien',
  'Ghost',
  'Fairy',
  'Merperson',
  'Wizzard',
  'Ninja',
  'Genie',
  'Superhero',
  'Unicorn',
]

export const getImage = (index: number, totalScore?: number) => {
  return ranges.filter((y) => y < (totalScore || 0)).length
}

export function getUrlParameter(name: string) {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  var results = regex.exec(window.location.search)
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const shareStr = (str: string) => {
  const dt = deviceType()

  if (dt === 'desktop') {
    navigator.clipboard.writeText(str)
  } else {
    navigator.share({
      title: 'Solvemoji Daily',
      text: str
    })
  }

  return dt
}


export function deviceType() {
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet"
  }
  else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return "mobile"
  }
  return "desktop"
};


export const pad = (num: number) => (
  num.toString().padStart(2, '0')
)

export const getUTCDate = () => {
  const now = new Date()
  const utcDate = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1, 0, 0, 0)
  return { now, utcDate }
}

export const obfs = (str: string) => {
  let obfsStr = JSON.stringify(str)

  try {
    var bytes = []
    for (var i = 0; i < obfsStr.length; i++) {
      bytes.push(obfsStr.charCodeAt(i).toString(16))
    }
    obfsStr = btoa(bytes.join('$'))
  } catch (err) {
    console.error(err)
  }

  return obfsStr
}

export const deob = (str: string) => {
  let deobStr = str
  try {
    var arr = atob(str).split('$')
    deobStr = arr.map(function (c) {
      return String.fromCharCode(parseInt(c, 16))
    }).reduce(function (a, b) { return a + b })
  } catch (err) {
    console.error(err)
  }

  return JSON.parse(deobStr)
}

// @ts-ignore
// eslint-disable-next-line no-extend-native
Date.prototype.toShortFormat = function () {
  let monthNames = ["Jan", "Feb", "Mar", "Apr",
    "May", "Jun", "Jul", "Aug",
    "Sep", "Oct", "Nov", "Dec"]

  let day = this.getDate()

  let monthIndex = this.getMonth()
  let monthName = monthNames[monthIndex]

  let year = this.getFullYear()

  return `${pad(day)}-${monthName}-${year}`
}

export const getPercentageStr = (value: number, total: number) => {
  return `${value} - ${percentageStr(value, total)}%`
}

export const getPercentageStrLong = (value: number, total: number) => {
  return `${value}/${total} - ${percentageStr(value, total)}%`
}

export const percentageStr = (value: number, total: number) => {
  return total ? ((value / total) * 100).toFixed(0) : 0
}

export async function sharePuzzle(puzzle: IPuzzle, isSolution?: boolean) {
  const { QuestionImageUrl, AnswerImageUrl, PuzzleId } = puzzle
  const response = await fetch(isSolution ? AnswerImageUrl : QuestionImageUrl)
  const blob = await response.blob()
  const dt = deviceType()

  if (dt === 'desktop') {
    var reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = function () {
      var base64data = reader.result?.toString().replace('data:application/octet-stream;base64,', '')
      if (base64data) {
        const blobInput = convertBase64ToBlob(base64data, 'image/png')
        // @ts-ignore
        const clipboardItemInput = new ClipboardItem({ 'image/png': blobInput })
        // @ts-ignore
        navigator.clipboard.write([clipboardItemInput])
      }
    }
  } else {
    const filesArray = [
      new File(
        [blob],
        'meme.jpg',
        {
          type: "image/jpeg",
          lastModified: new Date().getTime()
        }
      )
    ]

    const shareData = {
      title: `Solvemoji - Puzzle ID: ${PuzzleId}`,
      url: `https://www.solvemoji.com/puzzle/${PuzzleId}`,
      files: filesArray,
    }
    // @ts-ignore
    navigator.share(shareData)
  }


}

function convertBase64ToBlob(base64: string, type: string) {
  var bytes = window.atob(base64)
  var ab = new ArrayBuffer(bytes.length)
  var ia = new Uint8Array(ab)
  for (var i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i)
  }
  return new Blob([ab], { type: type })
}

export const getMagicLink = (magic: string) => (
  `${window.location.origin}/magic?magic=${encodeURIComponent(magic)}`
)

export async function getScreenShot(element: HTMLElement) {
  const canvas = await html2canvas(element)
  canvas.toBlob(function (blob) {
    if (blob) {
      navigator.clipboard
        // @ts-ignore
        .write([
          // @ts-ignore
          new ClipboardItem(
            Object.defineProperty({}, blob.type, {
              value: blob,
              enumerable: true
            })
          )
        ])
    }
  })
}

export const useUnhandledExceptions = () => {
  useEffect(() => {
    const errorFunc1 = function (e: ErrorEvent) {
      postError(e.error.message, Error()?.stack?.toString() || '')
      console.error("Error occurred: " + e.error.message)
      return false
    }

    const errorFunc2 = function (e: PromiseRejectionEvent) {
      postError(e.reason.message, Error()?.stack?.toString() || '')
      console.error("Error occurred: " + e.reason.message)
    }

    window.addEventListener("error", errorFunc1)
    window.addEventListener('unhandledrejection', errorFunc2)

    return () => {
      window.removeEventListener("error", errorFunc1)
      window.removeEventListener('unhandledrejection', errorFunc2)
    }
  }, [])
}

export const postError = (message: string, stack: string) => {
  try {
    const session = localStorage.getItem('SolvemojiSession')
    let user = ''

    if (session !== null) {
      const parsed = JSON.parse(session)
      user = parsed.userName
    }

    const headers = new Headers()
    headers.append("Content-Type", "application/json")

    const body = {
      user,
      "log": message,
      "stack": stack
    }

    const options = {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    }

    fetch("https://solvemoji-log.azurewebsites.net/api/HttpTrigger1?code=Oe21JkyvZnE5suRv2JO6YBAnw2dGOhraY-CDLzZ0TXAGAzFuMiDwAA==", options)
  } catch (err) {
    console.error(err)
  }

}

export function generateId() {     
  return Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2) +
    (new Date()).getTime().toString(36);
}